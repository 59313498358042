//Mask clip image shapes
.mask-squircle {
    mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E")
}
.mask-blob{
    mask-image: url('data:image/svg+xml;charset=utf-8,%3Csvg width="960" height="1140" viewBox="0 0 960 1140" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M408.453 0.35942C262.614 -4.60354 93.7303 41.1372 20.1168 180.826C-48.758 311.523 80.8716 460.184 86.4278 611.433C91.5013 749.544 -20.5442 894.766 50.5155 1008.33C124.267 1126.2 279.385 1116.17 408.453 1126.09C551.791 1137.11 709.886 1170.19 819.707 1067.45C934.679 959.904 967.526 777.933 958.621 611.433C950.224 454.41 873.887 317.789 773.663 206.47C673.336 95.0366 550.388 5.18954 408.453 0.35942Z" fill="%23000"/%3E%3C/svg%3E');
}
.mask-blob-2{
    mask-image:url('data:image/svg+xml;charset=utf-8,%3Csvg width="960" height="1140" viewBox="0 0 960 1140" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M537.08 1103.67C392.701 1112.16 220.82 1193.69 120.365 1080.49C20.0292 967.422 127.095 783.434 113.977 626.879C98.0414 436.693 -73.5079 230.789 36.7192 82.2148C145.776 -64.7824 366.175 23.5494 537.08 56.5805C670.343 82.3365 804.269 129.485 886.552 246.463C960.973 352.266 934.079 493.7 938.227 626.879C942.631 768.245 998.846 929.688 911.085 1034C822.28 1139.55 668.373 1095.95 537.08 1103.67Z" fill="%23000"/%3E%3C/svg%3E');
}
.mask-blob-3{
    mask-image: url('data:image/svg+xml;charset=utf-8,%3Csvg width="960" height="900" viewBox="0 0 960 900" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M956.368 429.683C943.276 311.394 895.043 194.621 814.425 111.211C733.807 27.8021 620.804 -23.0016 533.296 10.3622C481.227 30.2137 438.429 79.8636 391.837 133.915C360.124 170.704 326.654 209.533 287.307 242.391C251.158 272.512 209.977 297.414 170.363 321.369C104.267 361.337 42.5359 398.666 15.8243 453.19C4.92206 475.808 0 501.334 0 528.233C0 605.039 40.1287 693.037 95.7533 756.497C170.859 841.423 272.837 882.369 365.858 895.26C389.407 898.523 412.337 899.988 434.94 900C435.122 900 435.303 900 435.485 900C501.97 899.966 565.639 887.373 633.896 870.995C725.539 848.247 824.761 817.916 886.086 742.848C947.411 667.021 969.46 547.974 956.368 429.683Z" fill="%23000"/%3E%3C/svg%3E');
}
.mask-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
}