
.dark-mode-checkbox{
    cursor: pointer;
    input[type=checkbox]{
        appearance: none;
        -webkit-appearance: none;
    }
    .light_mode{
        display: none;
    }
    .slide{
        width:2.5rem;
        height:2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $dropdown-border-radius;
        color:currentColor;
        box-shadow: $box-shadow;
        &::after{
            content: "\e51c";
            font-family: "Material Icons";
        }
    }
}

body.dark-layout{
    .dark-mode-checkbox{
        .light_mode{
            display: block;
        }
        .dark_mode{
            display: none;
        }
        .slide::after{
            content: "\e0f0";
        }
    }
}