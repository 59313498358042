//
//Dropzone plugin scss
//
.dz-preview{
    display: flex;
    flex-wrap: wrap;
    .badge {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.dropzone-image-preview{
    img{
        max-width: 100%;
    }
}
.listing-img-preview{
margin-right: .35rem;
}