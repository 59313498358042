//Accordions
.accordion-list {
    .accordion-item {
        border-radius: $border-radius;
        border: 1px solid $border-color;
        &:not(:last-child) {
           margin-bottom: .5rem;
        }
    }

    .accordion-body {
        padding:1rem;
        padding-top: .5rem;
    }

    .accordion-header {
        .accordion-button {
            font-weight: $headings-font-weight;
            display: flex;
            align-items: center;
            padding: .75rem 1rem;

            &::after {
                content: "\e5cf";
                font-family: "Material Icons";
                margin-left: auto;
                font-size: 1.25rem;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                transition: all .25s;
            }

            &[aria-expanded=true] {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

//Accordion style
.accordion-button {
    font-weight: $headings-font-weight-sm;
}

//custo underline-accordion
.accordion{
    --#{$prefix}accordion-color: #{$body-color};
}
.accordion.accordion-custom {
    .accordion-item {
        border: 0px;
        border-radius: $accordion-border-radius;
    }
    .accordion-body {
        padding-top: 0;
    }
    .accordion-button {
        transition: all .25s;
        position: relative;
        z-index: 1;
        overflow: hidden;
        background-color: $white;
        border-radius: $border-radius;
        box-shadow: $btn-focus-box-shadow;
        &::after {
            background-image: none;
            font-weight: 400;
            font-family: boxicons !important;
            transform: rotate(90deg);
            content: "\ed91";
            font-size: $font-size-sm;
            color:currentColor;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 1rem;
            height: 1rem;
            text-align:center;
            line-height: 1;
            box-sizing: content-box;
        }
    }
    .accordion-collapse,.accordion-button:not(.collapsed){
        background-color: tint-color($primary, 90%);
    }
    .accordion-collapse{
        border-bottom-left-radius: $accordion-border-radius;
        border-bottom-right-radius: $accordion-border-radius;
    }
    .accordion-button:not(.collapsed) {
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        &::after {
            background-image: none;
            transform: rotate(0);
            color: $primary;
        }
    }
}