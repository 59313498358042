.modal-header{
    .btn-close{
        padding:0;
        right:0;
        top:0;
        margin:0;
    }
}

.btn-close{
    width:32px;
    height:32px;
    background-image:none;
    opacity:1;
}

  