.tippy-box[data-animation=fade][data-state=hidden] {
    opacity: 0
}

[data-tippy-root] {
    max-width:$tooltip-max-width;
}

.tippy-box {
    opacity:$tooltip-opacity;
    position: relative;
    background-color: $tooltip-bg;
    color: $tooltip-color;
    border-radius: $tooltip-border-radius;
    font-size: $font-size-sm;
    line-height:$line-height-base;
    white-space: normal;
    box-shadow: $box-shadow;
    outline: 0;
    transition-property: transform, visibility, opacity;
    a:not(.btn){
        color: $tooltip-color;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
}

.tippy-box[data-placement^=top]>.tippy-arrow {
    bottom: 0
}

.tippy-box[data-placement^=top]>.tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 8px 8px 0;
    border-top-color: initial;
    transform-origin: center top
}

.tippy-box[data-placement^=bottom]>.tippy-arrow {
    top: 0
}

.tippy-box[data-placement^=bottom]>.tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 8px;
    border-bottom-color: initial;
    transform-origin: center bottom
}

.tippy-box[data-placement^=left]>.tippy-arrow {
    right: 0
}

.tippy-box[data-placement^=left]>.tippy-arrow:before {
    border-width: 8px 0 8px 8px;
    border-left-color: initial;
    right: -7px;
    transform-origin: center left
}

.tippy-box[data-placement^=right]>.tippy-arrow {
    left: 0
}

.tippy-box[data-placement^=right]>.tippy-arrow:before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center right
}

.tippy-box[data-inertia][data-state=visible] {
    transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
}

.tippy-arrow {
    width: 16px;
    height: 16px;
    color: $tooltip-bg;
}

.tippy-arrow:before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid
}

.tippy-content {
    position: relative;
    padding: .75rem 1rem;
    z-index: 1
}

.tippy-box[data-animation=shift-toward][data-state=hidden] {
    opacity: 0
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=top] {
    transform: translateY(-10px)
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=bottom] {
    transform: translateY(10px)
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=left] {
    transform: translateX(-10px)
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=right] {
    transform: translateX(10px)
}