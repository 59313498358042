@charset "UTF-8";
.flex-center {
  display: inline-flex !important;
}

.border-dashed {
  border-style: dashed !important;
  border-width: inherit !important;
}

[class~=hover-shadow-],
[class*=hover-shadow-],
.hover-shadow {
  transition: all 0.25s ease-in-out;
}

.hover-shadow-sm:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(17, 15, 26, 0.075) !important;
}

.hover-shadow:hover {
  box-shadow: 0 0.5rem 1rem rgba(17, 15, 26, 0.0875) !important;
}

.hover-shadow-lg:hover {
  box-shadow: 0 1rem 3rem rgba(17, 15, 26, 0.075) !important;
}

.hover-shadow-xl:hover {
  box-shadow: 0 2rem 4rem rgba(17, 15, 26, 0.0875) !important;
}

.hover-shadow-3d:hover {
  box-shadow: 5px 5px 0px 0px #110f1a !important;
}

.grid-separator {
  overflow: hidden;
}
.grid-separator > * {
  box-shadow: -1px -1px 0px 0px #eae8f0;
}
.grid-separator > [class*=col-],
.grid-separator [class^=col-] {
  padding: 2rem 1rem;
}

.text-white .grid-separator > * {
  box-shadow: -1px -1px 0 0 rgba(255, 255, 255, 0.075);
}

/*!
 * Bootstrap  v5.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0061ff;
  --bs-indigo: #7360f2;
  --bs-purple: #6f42c1;
  --bs-pink: #f94770;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffd751;
  --bs-green: #35a552;
  --bs-teal: #20c997;
  --bs-cyan: #00c2ff;
  --bs-black: #110f1a;
  --bs-white: #fff;
  --bs-gray: #524b6a;
  --bs-gray-dark: #262136;
  --bs-gray-100: #f9f8fc;
  --bs-gray-200: #eae8f0;
  --bs-gray-300: #e6e5ec;
  --bs-gray-400: #c7c3d3;
  --bs-gray-500: #8a8793;
  --bs-gray-600: #524b6a;
  --bs-gray-700: #352f4a;
  --bs-gray-800: #262136;
  --bs-gray-900: #1a1726;
  --bs-primary: #110f1a;
  --bs-secondary: #352f4a;
  --bs-success: #35a552;
  --bs-info: #00c2ff;
  --bs-warning: #ffd751;
  --bs-danger: #f94770;
  --bs-light: #f9f8fc;
  --bs-white: #fff;
  --bs-dark: #110f1a;
  --bs-gray-200: #eae8f0;
  --bs-gray-800: #262136;
  --bs-primary-rgb: 17, 15, 26;
  --bs-secondary-rgb: 53, 47, 74;
  --bs-success-rgb: 53, 165, 82;
  --bs-info-rgb: 0, 194, 255;
  --bs-warning-rgb: 255, 215, 81;
  --bs-danger-rgb: 249, 71, 112;
  --bs-light-rgb: 249, 248, 252;
  --bs-white-rgb: 255, 255, 255;
  --bs-dark-rgb: 17, 15, 26;
  --bs-gray-200-rgb: 234, 232, 240;
  --bs-gray-800-rgb: 38, 33, 54;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 17, 15, 26;
  --bs-body-color-rgb: 26, 23, 38;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #1a1726;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #eae8f0;
  --bs-border-color-translucent: rgba(17, 15, 26, 0.125);
  --bs-border-radius: 0.475rem;
  --bs-border-radius-sm: 0.325rem;
  --bs-border-radius-lg: 0.725rem;
  --bs-border-radius-xl: 1.25rem;
  --bs-border-radius-2xl: 2.5rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #110f1a;
  --bs-link-hover-color: #0e0c15;
  --bs-code-color: #f94770;
  --bs-highlight-bg: #fff7dc;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(17, 15, 26, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.125;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.325rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #8a8793;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.6rem + 4.2vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 4.75rem;
  }
}

.display-2 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4rem;
  }
}

.display-3 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 3.5rem;
  }
}

.display-4 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3rem;
  }
}

.display-5 {
  font-size: calc(1.4rem + 1.8vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.75rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #8a8793;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.475rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #524b6a;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 3.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 3.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 6rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 6rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 7rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 7rem;
}

.g-13,
.gx-13 {
  --bs-gutter-x: 8rem;
}

.g-13,
.gy-13 {
  --bs-gutter-y: 8rem;
}

.g-14,
.gx-14 {
  --bs-gutter-x: 9rem;
}

.g-14,
.gy-14 {
  --bs-gutter-y: 9rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 10rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 10rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-11,
.gx-sm-11 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-11,
.gy-sm-11 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-12,
.gx-sm-12 {
    --bs-gutter-x: 7rem;
  }
  .g-sm-12,
.gy-sm-12 {
    --bs-gutter-y: 7rem;
  }
  .g-sm-13,
.gx-sm-13 {
    --bs-gutter-x: 8rem;
  }
  .g-sm-13,
.gy-sm-13 {
    --bs-gutter-y: 8rem;
  }
  .g-sm-14,
.gx-sm-14 {
    --bs-gutter-x: 9rem;
  }
  .g-sm-14,
.gy-sm-14 {
    --bs-gutter-y: 9rem;
  }
  .g-sm-15,
.gx-sm-15 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-15,
.gy-sm-15 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2rem;
  }
  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 3rem;
  }
  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 3rem;
  }
  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 4rem;
  }
  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 4rem;
  }
  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 5rem;
  }
  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 5rem;
  }
  .g-md-11,
.gx-md-11 {
    --bs-gutter-x: 6rem;
  }
  .g-md-11,
.gy-md-11 {
    --bs-gutter-y: 6rem;
  }
  .g-md-12,
.gx-md-12 {
    --bs-gutter-x: 7rem;
  }
  .g-md-12,
.gy-md-12 {
    --bs-gutter-y: 7rem;
  }
  .g-md-13,
.gx-md-13 {
    --bs-gutter-x: 8rem;
  }
  .g-md-13,
.gy-md-13 {
    --bs-gutter-y: 8rem;
  }
  .g-md-14,
.gx-md-14 {
    --bs-gutter-x: 9rem;
  }
  .g-md-14,
.gy-md-14 {
    --bs-gutter-y: 9rem;
  }
  .g-md-15,
.gx-md-15 {
    --bs-gutter-x: 10rem;
  }
  .g-md-15,
.gy-md-15 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-11,
.gx-lg-11 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-11,
.gy-lg-11 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-12,
.gx-lg-12 {
    --bs-gutter-x: 7rem;
  }
  .g-lg-12,
.gy-lg-12 {
    --bs-gutter-y: 7rem;
  }
  .g-lg-13,
.gx-lg-13 {
    --bs-gutter-x: 8rem;
  }
  .g-lg-13,
.gy-lg-13 {
    --bs-gutter-y: 8rem;
  }
  .g-lg-14,
.gx-lg-14 {
    --bs-gutter-x: 9rem;
  }
  .g-lg-14,
.gy-lg-14 {
    --bs-gutter-y: 9rem;
  }
  .g-lg-15,
.gx-lg-15 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-15,
.gy-lg-15 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-11,
.gx-xl-11 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-11,
.gy-xl-11 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-12,
.gx-xl-12 {
    --bs-gutter-x: 7rem;
  }
  .g-xl-12,
.gy-xl-12 {
    --bs-gutter-y: 7rem;
  }
  .g-xl-13,
.gx-xl-13 {
    --bs-gutter-x: 8rem;
  }
  .g-xl-13,
.gy-xl-13 {
    --bs-gutter-y: 8rem;
  }
  .g-xl-14,
.gx-xl-14 {
    --bs-gutter-x: 9rem;
  }
  .g-xl-14,
.gy-xl-14 {
    --bs-gutter-y: 9rem;
  }
  .g-xl-15,
.gx-xl-15 {
    --bs-gutter-x: 10rem;
  }
  .g-xl-15,
.gy-xl-15 {
    --bs-gutter-y: 10rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-11,
.gx-xxl-11 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-11,
.gy-xxl-11 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-12,
.gx-xxl-12 {
    --bs-gutter-x: 7rem;
  }
  .g-xxl-12,
.gy-xxl-12 {
    --bs-gutter-y: 7rem;
  }
  .g-xxl-13,
.gx-xxl-13 {
    --bs-gutter-x: 8rem;
  }
  .g-xxl-13,
.gy-xxl-13 {
    --bs-gutter-y: 8rem;
  }
  .g-xxl-14,
.gx-xxl-14 {
    --bs-gutter-x: 9rem;
  }
  .g-xxl-14,
.gy-xxl-14 {
    --bs-gutter-y: 9rem;
  }
  .g-xxl-15,
.gx-xxl-15 {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-15,
.gy-xxl-15 {
    --bs-gutter-y: 10rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(17, 15, 26, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(17, 15, 26, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(17, 15, 26, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #110f1a;
  --bs-table-bg: #cfcfd1;
  --bs-table-border-color: #bcbcbf;
  --bs-table-striped-bg: #c6c5c8;
  --bs-table-striped-color: #110f1a;
  --bs-table-active-bg: #bcbcbf;
  --bs-table-active-color: #110f1a;
  --bs-table-hover-bg: #c1c1c3;
  --bs-table-hover-color: #110f1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #110f1a;
  --bs-table-bg: #d7d5db;
  --bs-table-border-color: #c3c1c8;
  --bs-table-striped-bg: #cdcbd1;
  --bs-table-striped-color: #110f1a;
  --bs-table-active-bg: #c3c1c8;
  --bs-table-active-color: #110f1a;
  --bs-table-hover-bg: #c8c6cd;
  --bs-table-hover-color: #110f1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #110f1a;
  --bs-table-bg: #d7eddc;
  --bs-table-border-color: #c3d7c9;
  --bs-table-striped-bg: #cde2d2;
  --bs-table-striped-color: #110f1a;
  --bs-table-active-bg: #c3d7c9;
  --bs-table-active-color: #110f1a;
  --bs-table-hover-bg: #c8dccd;
  --bs-table-hover-color: #110f1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #110f1a;
  --bs-table-bg: #ccf3ff;
  --bs-table-border-color: #b9dce8;
  --bs-table-striped-bg: #c3e8f4;
  --bs-table-striped-color: #110f1a;
  --bs-table-active-bg: #b9dce8;
  --bs-table-active-color: #110f1a;
  --bs-table-hover-bg: #bee2ee;
  --bs-table-hover-color: #110f1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #110f1a;
  --bs-table-bg: #fff7dc;
  --bs-table-border-color: #e7e0c9;
  --bs-table-striped-bg: #f3ebd2;
  --bs-table-striped-color: #110f1a;
  --bs-table-active-bg: #e7e0c9;
  --bs-table-active-color: #110f1a;
  --bs-table-hover-bg: #ede6cd;
  --bs-table-hover-color: #110f1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #110f1a;
  --bs-table-bg: #fedae2;
  --bs-table-border-color: #e6c6ce;
  --bs-table-striped-bg: #f2d0d8;
  --bs-table-striped-color: #110f1a;
  --bs-table-active-bg: #e6c6ce;
  --bs-table-active-color: #110f1a;
  --bs-table-hover-bg: #eccbd3;
  --bs-table-hover-color: #110f1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #110f1a;
  --bs-table-bg: #f9f8fc;
  --bs-table-border-color: #e2e1e5;
  --bs-table-striped-bg: #edecf1;
  --bs-table-striped-color: #110f1a;
  --bs-table-active-bg: #e2e1e5;
  --bs-table-active-color: #110f1a;
  --bs-table-hover-bg: #e8e7eb;
  --bs-table-hover-color: #110f1a;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #110f1a;
  --bs-table-border-color: #292731;
  --bs-table-striped-bg: #1d1b25;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #292731;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #23212b;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.535rem + 1px);
  padding-bottom: calc(0.535rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1.125rem;
}

.col-form-label-sm {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #8a8793;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.535rem 1.125rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1a1726;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e6e5ec;
  appearance: none;
  border-radius: 0.475rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #1a1726;
  background-color: #fff;
  border-color: #292731;
  outline: 0;
  box-shadow: 0 0 0.75rem 0 rgba(17, 15, 26, 0.0875);
}
.form-control::-webkit-date-and-time-value {
  height: 1.6em;
}
.form-control::placeholder {
  color: #8a8793;
  opacity: 1;
}
.form-control:disabled {
  background-color: #f9f8fc;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.535rem 1.125rem;
  margin: -0.535rem -1.125rem;
  margin-inline-end: 1.125rem;
  color: #1a1726;
  background-color: #f9f8fc;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #edecef;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.535rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: #1a1726;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.325rem;
}
.form-control-sm::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1.5rem + 2px);
  padding: 0.75rem 1.675rem;
  font-size: 1.125rem;
  border-radius: 0.725rem;
}
.form-control-lg::file-selector-button {
  padding: 0.75rem 1.675rem;
  margin: -0.75rem -1.675rem;
  margin-inline-end: 1.675rem;
}

textarea.form-control {
  min-height: calc(1.6em + 1.07rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.6em + 0.75rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.6em + 1.5rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.6em + 1.07rem + 2px);
  padding: 0.535rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.475rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.475rem;
}
.form-control-color.form-control-sm {
  height: calc(1.6em + 0.75rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.6em + 1.5rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.535rem 3.375rem 0.535rem 1.125rem;
  -moz-padding-start: calc(1.125rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1a1726;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23262136' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.125rem center;
  background-size: 16px 12px;
  border: 1px solid #e6e5ec;
  border-radius: 0.475rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #292731;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(17, 15, 26, 0.0875);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.125rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #eae8f0;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1a1726;
}

.form-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.325rem;
}

.form-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.675rem;
  font-size: 1.125rem;
  border-radius: 0.725rem;
}

.form-check {
  display: block;
  min-height: 1.6rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.75em;
}

.form-check-reverse {
  padding-right: 1.75em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.75em;
  margin-left: 0;
}

.form-check-input {
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.175em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #e6e5ec;
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #292731;
  outline: 0;
  box-shadow: 0 0 0.75rem 0 rgba(17, 15, 26, 0.0875);
}
.form-check-input:checked {
  background-color: #110f1a;
  border-color: #110f1a;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #110f1a;
  border-color: #110f1a;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 3em;
}
.form-switch .form-check-input {
  width: 2.5em;
  margin-left: -3em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e6e5ec'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2.5em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23292731'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 3em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -3em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0.75rem 0 rgba(17, 15, 26, 0.0875);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0.75rem 0 rgba(17, 15, 26, 0.0875);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #110f1a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b8b7ba;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e6e5ec;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #110f1a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b8b7ba;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e6e5ec;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #8a8793;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #8a8793;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 1.125rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1.125rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.535rem 1.125rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #1a1726;
  text-align: center;
  white-space: nowrap;
  background-color: #f9f8fc;
  border: 1px solid #e6e5ec;
  border-radius: 0.475rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.75rem 1.675rem;
  font-size: 1.125rem;
  border-radius: 0.725rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.325rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4.5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #35a552;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(53, 165, 82, 0.9);
  border-radius: 0.475rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #35a552;
  padding-right: calc(1.6em + 1.07rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2335a552' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.2675rem) center;
  background-size: calc(0.8em + 0.535rem) calc(0.8em + 0.535rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #35a552;
  box-shadow: 0 0 0.75rem 0 rgba(53, 165, 82, 0.0875);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 1.07rem);
  background-position: top calc(0.4em + 0.2675rem) right calc(0.4em + 0.2675rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #35a552;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 6.1875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23262136' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2335a552' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.125rem center, center right 3.375rem;
  background-size: 16px 12px, calc(0.8em + 0.535rem) calc(0.8em + 0.535rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #35a552;
  box-shadow: 0 0 0.75rem 0 rgba(53, 165, 82, 0.0875);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.6em + 1.07rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #35a552;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #35a552;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0.75rem 0 rgba(53, 165, 82, 0.0875);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #35a552;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f94770;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(249, 71, 112, 0.9);
  border-radius: 0.475rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f94770;
  padding-right: calc(1.6em + 1.07rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f94770'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f94770' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.2675rem) center;
  background-size: calc(0.8em + 0.535rem) calc(0.8em + 0.535rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f94770;
  box-shadow: 0 0 0.75rem 0 rgba(249, 71, 112, 0.0875);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 1.07rem);
  background-position: top calc(0.4em + 0.2675rem) right calc(0.4em + 0.2675rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #f94770;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 6.1875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23262136' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f94770'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f94770' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.125rem center, center right 3.375rem;
  background-size: 16px 12px, calc(0.8em + 0.535rem) calc(0.8em + 0.535rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #f94770;
  box-shadow: 0 0 0.75rem 0 rgba(249, 71, 112, 0.0875);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.6em + 1.07rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #f94770;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #f94770;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0.75rem 0 rgba(249, 71, 112, 0.0875);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f94770;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.125rem;
  --bs-btn-padding-y: 0.535rem;
  --bs-btn-font-family: var(--bs-font-sans-serif);
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.6;
  --bs-btn-color: #1a1726;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.475rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(17, 15, 26, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
:not(.btn-check) + .btn:hover, .btn:first-child:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #110f1a;
  --bs-btn-border-color: #110f1a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0e0d16;
  --bs-btn-hover-border-color: #0e0c15;
  --bs-btn-focus-shadow-rgb: 53, 51, 60;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0e0c15;
  --bs-btn-active-border-color: #0d0b14;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #110f1a;
  --bs-btn-disabled-border-color: #110f1a;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #352f4a;
  --bs-btn-border-color: #352f4a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2d283f;
  --bs-btn-hover-border-color: #2a263b;
  --bs-btn-focus-shadow-rgb: 83, 78, 101;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2a263b;
  --bs-btn-active-border-color: #282338;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #352f4a;
  --bs-btn-disabled-border-color: #352f4a;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #35a552;
  --bs-btn-border-color: #35a552;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2d8c46;
  --bs-btn-hover-border-color: #2a8442;
  --bs-btn-focus-shadow-rgb: 83, 179, 108;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2a8442;
  --bs-btn-active-border-color: #287c3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #35a552;
  --bs-btn-disabled-border-color: #35a552;
}

.btn-info {
  --bs-btn-color: #110f1a;
  --bs-btn-bg: #00c2ff;
  --bs-btn-border-color: #00c2ff;
  --bs-btn-hover-color: #110f1a;
  --bs-btn-hover-bg: #26cbff;
  --bs-btn-hover-border-color: #1ac8ff;
  --bs-btn-focus-shadow-rgb: 3, 167, 221;
  --bs-btn-active-color: #110f1a;
  --bs-btn-active-bg: #33ceff;
  --bs-btn-active-border-color: #1ac8ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #110f1a;
  --bs-btn-disabled-bg: #00c2ff;
  --bs-btn-disabled-border-color: #00c2ff;
}

.btn-warning {
  --bs-btn-color: #110f1a;
  --bs-btn-bg: #ffd751;
  --bs-btn-border-color: #ffd751;
  --bs-btn-hover-color: #110f1a;
  --bs-btn-hover-bg: #ffdd6b;
  --bs-btn-hover-border-color: #ffdb62;
  --bs-btn-focus-shadow-rgb: 219, 185, 73;
  --bs-btn-active-color: #110f1a;
  --bs-btn-active-bg: #ffdf74;
  --bs-btn-active-border-color: #ffdb62;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #110f1a;
  --bs-btn-disabled-bg: #ffd751;
  --bs-btn-disabled-border-color: #ffd751;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f94770;
  --bs-btn-border-color: #f94770;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d43c5f;
  --bs-btn-hover-border-color: #c7395a;
  --bs-btn-focus-shadow-rgb: 250, 99, 133;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c7395a;
  --bs-btn-active-border-color: #bb3554;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f94770;
  --bs-btn-disabled-border-color: #f94770;
}

.btn-light {
  --bs-btn-color: #110f1a;
  --bs-btn-bg: #f9f8fc;
  --bs-btn-border-color: #f9f8fc;
  --bs-btn-hover-color: #110f1a;
  --bs-btn-hover-bg: #d4d3d6;
  --bs-btn-hover-border-color: #c7c6ca;
  --bs-btn-focus-shadow-rgb: 214, 213, 218;
  --bs-btn-active-color: #110f1a;
  --bs-btn-active-bg: #c7c6ca;
  --bs-btn-active-border-color: #bbbabd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #110f1a;
  --bs-btn-disabled-bg: #f9f8fc;
  --bs-btn-disabled-border-color: #f9f8fc;
}

.btn-white {
  --bs-btn-color: #110f1a;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #110f1a;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 219, 219, 221;
  --bs-btn-active-color: #110f1a;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #110f1a;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #110f1a;
  --bs-btn-border-color: #110f1a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #35333c;
  --bs-btn-hover-border-color: #292731;
  --bs-btn-focus-shadow-rgb: 53, 51, 60;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #413f48;
  --bs-btn-active-border-color: #292731;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #110f1a;
  --bs-btn-disabled-border-color: #110f1a;
}

.btn-gray-200 {
  --bs-btn-color: #110f1a;
  --bs-btn-bg: #eae8f0;
  --bs-btn-border-color: #eae8f0;
  --bs-btn-hover-color: #110f1a;
  --bs-btn-hover-bg: #edebf2;
  --bs-btn-hover-border-color: #eceaf2;
  --bs-btn-focus-shadow-rgb: 201, 199, 208;
  --bs-btn-active-color: #110f1a;
  --bs-btn-active-bg: #eeedf3;
  --bs-btn-active-border-color: #eceaf2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #110f1a;
  --bs-btn-disabled-bg: #eae8f0;
  --bs-btn-disabled-border-color: #eae8f0;
}

.btn-gray-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #262136;
  --bs-btn-border-color: #262136;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #201c2e;
  --bs-btn-hover-border-color: #1e1a2b;
  --bs-btn-focus-shadow-rgb: 71, 66, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1e1a2b;
  --bs-btn-active-border-color: #1d1929;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #262136;
  --bs-btn-disabled-border-color: #262136;
}

.btn-outline-primary {
  --bs-btn-color: #110f1a;
  --bs-btn-border-color: #110f1a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #110f1a;
  --bs-btn-hover-border-color: #110f1a;
  --bs-btn-focus-shadow-rgb: 17, 15, 26;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #110f1a;
  --bs-btn-active-border-color: #110f1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #110f1a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #110f1a;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #352f4a;
  --bs-btn-border-color: #352f4a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #352f4a;
  --bs-btn-hover-border-color: #352f4a;
  --bs-btn-focus-shadow-rgb: 53, 47, 74;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #352f4a;
  --bs-btn-active-border-color: #352f4a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #352f4a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #352f4a;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #35a552;
  --bs-btn-border-color: #35a552;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #35a552;
  --bs-btn-hover-border-color: #35a552;
  --bs-btn-focus-shadow-rgb: 53, 165, 82;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #35a552;
  --bs-btn-active-border-color: #35a552;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #35a552;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #35a552;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #00c2ff;
  --bs-btn-border-color: #00c2ff;
  --bs-btn-hover-color: #110f1a;
  --bs-btn-hover-bg: #00c2ff;
  --bs-btn-hover-border-color: #00c2ff;
  --bs-btn-focus-shadow-rgb: 0, 194, 255;
  --bs-btn-active-color: #110f1a;
  --bs-btn-active-bg: #00c2ff;
  --bs-btn-active-border-color: #00c2ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #00c2ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00c2ff;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffd751;
  --bs-btn-border-color: #ffd751;
  --bs-btn-hover-color: #110f1a;
  --bs-btn-hover-bg: #ffd751;
  --bs-btn-hover-border-color: #ffd751;
  --bs-btn-focus-shadow-rgb: 255, 215, 81;
  --bs-btn-active-color: #110f1a;
  --bs-btn-active-bg: #ffd751;
  --bs-btn-active-border-color: #ffd751;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #ffd751;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffd751;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #f94770;
  --bs-btn-border-color: #f94770;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f94770;
  --bs-btn-hover-border-color: #f94770;
  --bs-btn-focus-shadow-rgb: 249, 71, 112;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f94770;
  --bs-btn-active-border-color: #f94770;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #f94770;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f94770;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f9f8fc;
  --bs-btn-border-color: #f9f8fc;
  --bs-btn-hover-color: #110f1a;
  --bs-btn-hover-bg: #f9f8fc;
  --bs-btn-hover-border-color: #f9f8fc;
  --bs-btn-focus-shadow-rgb: 249, 248, 252;
  --bs-btn-active-color: #110f1a;
  --bs-btn-active-bg: #f9f8fc;
  --bs-btn-active-border-color: #f9f8fc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #f9f8fc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f9f8fc;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #110f1a;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #110f1a;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #110f1a;
  --bs-btn-border-color: #110f1a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #110f1a;
  --bs-btn-hover-border-color: #110f1a;
  --bs-btn-focus-shadow-rgb: 17, 15, 26;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #110f1a;
  --bs-btn-active-border-color: #110f1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #110f1a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #110f1a;
  --bs-gradient: none;
}

.btn-outline-gray-200 {
  --bs-btn-color: #eae8f0;
  --bs-btn-border-color: #eae8f0;
  --bs-btn-hover-color: #110f1a;
  --bs-btn-hover-bg: #eae8f0;
  --bs-btn-hover-border-color: #eae8f0;
  --bs-btn-focus-shadow-rgb: 234, 232, 240;
  --bs-btn-active-color: #110f1a;
  --bs-btn-active-bg: #eae8f0;
  --bs-btn-active-border-color: #eae8f0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #eae8f0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eae8f0;
  --bs-gradient: none;
}

.btn-outline-gray-800 {
  --bs-btn-color: #262136;
  --bs-btn-border-color: #262136;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #262136;
  --bs-btn-hover-border-color: #262136;
  --bs-btn-focus-shadow-rgb: 38, 33, 54;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #262136;
  --bs-btn-active-border-color: #262136;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(17, 15, 26, 0.125);
  --bs-btn-disabled-color: #262136;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #262136;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #524b6a;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 53, 51, 60;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-padding-x: 1.675rem;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-border-radius: 0.725rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.325rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 13rem;
  --bs-dropdown-padding-x: 0.5rem;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #1a1726;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: transparent;
  --bs-dropdown-border-radius: 0.475rem;
  --bs-dropdown-border-width: 0px;
  --bs-dropdown-inner-border-radius: calc(0.475rem - 0px);
  --bs-dropdown-divider-bg: #eae8f0;
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 100px 80px rgba(17, 15, 26, 0.05), 0 42px 34px rgba(17, 15, 26, 0.04), 0 22px 18px rgba(17, 15, 26, 0.03), 0 13px 11px rgba(17, 15, 26, 0.03), 0 6.65px 5.325px rgba(17, 15, 26, 0.02), 0 3px 2px rgba(17, 15, 26, 0.01);
  --bs-dropdown-link-color: #524b6a;
  --bs-dropdown-link-hover-color: #110f1a;
  --bs-dropdown-link-hover-bg: #f9f8fc;
  --bs-dropdown-link-active-color: #110f1a;
  --bs-dropdown-link-active-bg: #eae8f0;
  --bs-dropdown-link-disabled-color: #8a8793;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.4rem;
  --bs-dropdown-header-color: #8a8793;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #e6e5ec;
  --bs-dropdown-bg: #262136;
  --bs-dropdown-border-color: transparent;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #e6e5ec;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #eae8f0;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #110f1a;
  --bs-dropdown-link-active-bg: #eae8f0;
  --bs-dropdown-link-disabled-color: #8a8793;
  --bs-dropdown-header-color: #8a8793;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.475rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.25625rem;
  padding-left: 1.25625rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #524b6a;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #e6e5ec;
  --bs-nav-tabs-border-radius: 0.475rem;
  --bs-nav-tabs-link-hover-border-color: #eae8f0 #eae8f0 #e6e5ec;
  --bs-nav-tabs-link-active-color: #352f4a;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #e6e5ec #e6e5ec #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.475rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #110f1a;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(17, 15, 26, 0.55);
  --bs-navbar-hover-color: #110f1a;
  --bs-navbar-disabled-color: rgba(17, 15, 26, 0.3);
  --bs-navbar-active-color: #110f1a;
  --bs-navbar-brand-padding-y: 0.3rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #110f1a;
  --bs-navbar-brand-hover-color: #110f1a;
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2817, 15, 26, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(17, 15, 26, 0.1);
  --bs-navbar-toggler-border-radius: 0.475rem;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-hover-color: white;
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: #eae8f0;
  --bs-card-border-radius: 0.475rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.475rem - 1px);
  --bs-card-cap-padding-y: 0.9375rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.475rem;
  --bs-accordion-inner-border-radius: calc(0.475rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230f0e17'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #292731;
  --bs-accordion-btn-focus-box-shadow: 0 0 0.75rem 0 rgba(17, 15, 26, 0.0875);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0f0e17;
  --bs-accordion-active-bg: #e7e7e8;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #524b6a;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #524b6a;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #e6e5ec;
  --bs-pagination-border-radius: 0.475rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #eae8f0;
  --bs-pagination-hover-border-color: #e6e5ec;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #eae8f0;
  --bs-pagination-focus-box-shadow: 0 0 0.75rem 0 rgba(17, 15, 26, 0.0875);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #110f1a;
  --bs-pagination-active-border-color: #110f1a;
  --bs-pagination-disabled-color: #524b6a;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #e6e5ec;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.725rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.325rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 600;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.475rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.475rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #0a0910;
  --bs-alert-bg: #cfcfd1;
  --bs-alert-border-color: #b8b7ba;
}
.alert-primary .alert-link {
  color: #08070d;
}

.alert-secondary {
  --bs-alert-color: #201c2c;
  --bs-alert-bg: #d7d5db;
  --bs-alert-border-color: #c2c1c9;
}
.alert-secondary .alert-link {
  color: #1a1623;
}

.alert-success {
  --bs-alert-color: #206331;
  --bs-alert-bg: #d7eddc;
  --bs-alert-border-color: #c2e4cb;
}
.alert-success .alert-link {
  color: #1a4f27;
}

.alert-info {
  --bs-alert-color: #007499;
  --bs-alert-bg: #ccf3ff;
  --bs-alert-border-color: #b3edff;
}
.alert-info .alert-link {
  color: #005d7a;
}

.alert-warning {
  --bs-alert-color: #998131;
  --bs-alert-bg: #fff7dc;
  --bs-alert-border-color: #fff3cb;
}
.alert-warning .alert-link {
  color: #7a6727;
}

.alert-danger {
  --bs-alert-color: #952b43;
  --bs-alert-bg: #fedae2;
  --bs-alert-border-color: #fdc8d4;
}
.alert-danger .alert-link {
  color: #772236;
}

.alert-light {
  --bs-alert-color: #6e6c74;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #58565d;
}

.alert-white {
  --bs-alert-color: #706f76;
  --bs-alert-bg: white;
  --bs-alert-border-color: white;
}
.alert-white .alert-link {
  color: #5a595e;
}

.alert-dark {
  --bs-alert-color: #0a0910;
  --bs-alert-bg: #cfcfd1;
  --bs-alert-border-color: #b8b7ba;
}
.alert-dark .alert-link {
  color: #08070d;
}

.alert-gray-200 {
  --bs-alert-color: #8c8b90;
  --bs-alert-bg: #fbfafc;
  --bs-alert-border-color: #f9f8fb;
}
.alert-gray-200 .alert-link {
  color: #706f73;
}

.alert-gray-800 {
  --bs-alert-color: #171420;
  --bs-alert-bg: #d4d3d7;
  --bs-alert-border-color: #bebcc3;
}
.alert-gray-800 .alert-link {
  color: #12101a;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #eae8f0;
  --bs-progress-border-radius: 0.475rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(17, 15, 26, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #110f1a;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #1a1726;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(17, 15, 26, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.475rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #352f4a;
  --bs-list-group-action-hover-color: #352f4a;
  --bs-list-group-action-hover-bg: #f9f8fc;
  --bs-list-group-action-active-color: #1a1726;
  --bs-list-group-action-active-bg: #eae8f0;
  --bs-list-group-disabled-color: #524b6a;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #110f1a;
  --bs-list-group-active-border-color: #110f1a;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0a0910;
  background-color: #cfcfd1;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0a0910;
  background-color: #bababc;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0a0910;
  border-color: #0a0910;
}

.list-group-item-secondary {
  color: #201c2c;
  background-color: #d7d5db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #201c2c;
  background-color: #c2c0c5;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #201c2c;
  border-color: #201c2c;
}

.list-group-item-success {
  color: #206331;
  background-color: #d7eddc;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #206331;
  background-color: #c2d5c6;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #206331;
  border-color: #206331;
}

.list-group-item-info {
  color: #007499;
  background-color: #ccf3ff;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #007499;
  background-color: #b8dbe6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #007499;
  border-color: #007499;
}

.list-group-item-warning {
  color: #998131;
  background-color: #fff7dc;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #998131;
  background-color: #e6dec6;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #998131;
  border-color: #998131;
}

.list-group-item-danger {
  color: #952b43;
  background-color: #fedae2;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #952b43;
  background-color: #e5c4cb;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #952b43;
  border-color: #952b43;
}

.list-group-item-light {
  color: #6e6c74;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #6e6c74;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #6e6c74;
  border-color: #6e6c74;
}

.list-group-item-white {
  color: #706f76;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #706f76;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #706f76;
  border-color: #706f76;
}

.list-group-item-dark {
  color: #0a0910;
  background-color: #cfcfd1;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0a0910;
  background-color: #bababc;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0a0910;
  border-color: #0a0910;
}

.list-group-item-gray-200 {
  color: #8c8b90;
  background-color: #fbfafc;
}
.list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
  color: #8c8b90;
  background-color: #e2e1e3;
}
.list-group-item-gray-200.list-group-item-action.active {
  color: #fff;
  background-color: #8c8b90;
  border-color: #8c8b90;
}

.list-group-item-gray-800 {
  color: #171420;
  background-color: #d4d3d7;
}
.list-group-item-gray-800.list-group-item-action:hover, .list-group-item-gray-800.list-group-item-action:focus {
  color: #171420;
  background-color: #bfbec2;
}
.list-group-item-gray-800.list-group-item-action.active {
  color: #fff;
  background-color: #171420;
  border-color: #171420;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #110f1a;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23110f1a'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.475rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #110f1a;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0.75rem 0 rgba(17, 15, 26, 0.0875);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.475rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(17, 15, 26, 0.0875);
  --bs-toast-header-color: #524b6a;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(17, 15, 26, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.725rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(17, 15, 26, 0.075);
  --bs-modal-inner-border-radius: calc(0.725rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.6;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #110f1a;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(17, 15, 26, 0.0875);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #110f1a;
  --bs-tooltip-border-radius: 0.475rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.725rem;
  --bs-popover-inner-border-radius: calc(0.725rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(17, 15, 26, 0.0875);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: var(--bs-heading-color);
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #1a1726;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #110f1a;
}
.carousel-dark .carousel-caption {
  color: #110f1a;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(17, 15, 26, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #110f1a;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #110f1a 55%, rgba(0, 0, 0, 0.8) 75%, #110f1a 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(17, 15, 26, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(53, 47, 74, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(53, 165, 82, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #110f1a !important;
  background-color: RGBA(0, 194, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #110f1a !important;
  background-color: RGBA(255, 215, 81, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(249, 71, 112, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #110f1a !important;
  background-color: RGBA(249, 248, 252, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #110f1a !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(17, 15, 26, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-200 {
  color: #110f1a !important;
  background-color: RGBA(234, 232, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-800 {
  color: #fff !important;
  background-color: RGBA(38, 33, 54, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #110f1a !important;
}
.link-primary:hover, .link-primary:focus {
  color: #0e0c15 !important;
}

.link-secondary {
  color: #352f4a !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #2a263b !important;
}

.link-success {
  color: #35a552 !important;
}
.link-success:hover, .link-success:focus {
  color: #2a8442 !important;
}

.link-info {
  color: #00c2ff !important;
}
.link-info:hover, .link-info:focus {
  color: #33ceff !important;
}

.link-warning {
  color: #ffd751 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffdf74 !important;
}

.link-danger {
  color: #f94770 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #c7395a !important;
}

.link-light {
  color: #f9f8fc !important;
}
.link-light:hover, .link-light:focus {
  color: #faf9fd !important;
}

.link-white {
  color: #fff !important;
}
.link-white:hover, .link-white:focus {
  color: white !important;
}

.link-dark {
  color: #110f1a !important;
}
.link-dark:hover, .link-dark:focus {
  color: #0e0c15 !important;
}

.link-gray-200 {
  color: #eae8f0 !important;
}
.link-gray-200:hover, .link-gray-200:focus {
  color: #eeedf3 !important;
}

.link-gray-800 {
  color: #262136 !important;
}
.link-gray-800:hover, .link-gray-800:focus {
  color: #1e1a2b !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.125;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(17, 15, 26, 0.0875) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(17, 15, 26, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(17, 15, 26, 0.075) !important;
}

.shadow-3d {
  box-shadow: 5px 5px 0px 0px #110f1a !important;
}

.shadow-xl {
  box-shadow: 0 2rem 4rem rgba(17, 15, 26, 0.0875) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-fixed {
  position: fixed !important;
}

.position-absolute {
  position: absolute !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-200-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-800-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-0 {
  width: 0 !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-0 {
  height: 0 !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.m-11 {
  margin: 6rem !important;
}

.m-12 {
  margin: 7rem !important;
}

.m-13 {
  margin: 8rem !important;
}

.m-14 {
  margin: 9rem !important;
}

.m-15 {
  margin: 10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-11 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-12 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-13 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-14 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}

.mx-15 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-11 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-12 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-13 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-14 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-15 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 5rem !important;
}

.mt-11 {
  margin-top: 6rem !important;
}

.mt-12 {
  margin-top: 7rem !important;
}

.mt-13 {
  margin-top: 8rem !important;
}

.mt-14 {
  margin-top: 9rem !important;
}

.mt-15 {
  margin-top: 10rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 5rem !important;
}

.me-11 {
  margin-right: 6rem !important;
}

.me-12 {
  margin-right: 7rem !important;
}

.me-13 {
  margin-right: 8rem !important;
}

.me-14 {
  margin-right: 9rem !important;
}

.me-15 {
  margin-right: 10rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 5rem !important;
}

.mb-11 {
  margin-bottom: 6rem !important;
}

.mb-12 {
  margin-bottom: 7rem !important;
}

.mb-13 {
  margin-bottom: 8rem !important;
}

.mb-14 {
  margin-bottom: 9rem !important;
}

.mb-15 {
  margin-bottom: 10rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 5rem !important;
}

.ms-11 {
  margin-left: 6rem !important;
}

.ms-12 {
  margin-left: 7rem !important;
}

.ms-13 {
  margin-left: 8rem !important;
}

.ms-14 {
  margin-left: 9rem !important;
}

.ms-15 {
  margin-left: 10rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -5rem !important;
}

.m-n11 {
  margin: -6rem !important;
}

.m-n12 {
  margin: -7rem !important;
}

.m-n13 {
  margin: -8rem !important;
}

.m-n14 {
  margin: -9rem !important;
}

.m-n15 {
  margin: -10rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n8 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n11 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n12 {
  margin-right: -7rem !important;
  margin-left: -7rem !important;
}

.mx-n13 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n14 {
  margin-right: -9rem !important;
  margin-left: -9rem !important;
}

.mx-n15 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n11 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n12 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.my-n13 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n14 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.my-n15 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.mt-n7 {
  margin-top: -3rem !important;
}

.mt-n8 {
  margin-top: -3.5rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -5rem !important;
}

.mt-n11 {
  margin-top: -6rem !important;
}

.mt-n12 {
  margin-top: -7rem !important;
}

.mt-n13 {
  margin-top: -8rem !important;
}

.mt-n14 {
  margin-top: -9rem !important;
}

.mt-n15 {
  margin-top: -10rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.me-n7 {
  margin-right: -3rem !important;
}

.me-n8 {
  margin-right: -3.5rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -5rem !important;
}

.me-n11 {
  margin-right: -6rem !important;
}

.me-n12 {
  margin-right: -7rem !important;
}

.me-n13 {
  margin-right: -8rem !important;
}

.me-n14 {
  margin-right: -9rem !important;
}

.me-n15 {
  margin-right: -10rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.mb-n7 {
  margin-bottom: -3rem !important;
}

.mb-n8 {
  margin-bottom: -3.5rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -5rem !important;
}

.mb-n11 {
  margin-bottom: -6rem !important;
}

.mb-n12 {
  margin-bottom: -7rem !important;
}

.mb-n13 {
  margin-bottom: -8rem !important;
}

.mb-n14 {
  margin-bottom: -9rem !important;
}

.mb-n15 {
  margin-bottom: -10rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.ms-n7 {
  margin-left: -3rem !important;
}

.ms-n8 {
  margin-left: -3.5rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -5rem !important;
}

.ms-n11 {
  margin-left: -6rem !important;
}

.ms-n12 {
  margin-left: -7rem !important;
}

.ms-n13 {
  margin-left: -8rem !important;
}

.ms-n14 {
  margin-left: -9rem !important;
}

.ms-n15 {
  margin-left: -10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.p-11 {
  padding: 6rem !important;
}

.p-12 {
  padding: 7rem !important;
}

.p-13 {
  padding: 8rem !important;
}

.p-14 {
  padding: 9rem !important;
}

.p-15 {
  padding: 10rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-11 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-12 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.px-13 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-14 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}

.px-15 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-11 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-12 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-13 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-14 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-15 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 5rem !important;
}

.pt-11 {
  padding-top: 6rem !important;
}

.pt-12 {
  padding-top: 7rem !important;
}

.pt-13 {
  padding-top: 8rem !important;
}

.pt-14 {
  padding-top: 9rem !important;
}

.pt-15 {
  padding-top: 10rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 5rem !important;
}

.pe-11 {
  padding-right: 6rem !important;
}

.pe-12 {
  padding-right: 7rem !important;
}

.pe-13 {
  padding-right: 8rem !important;
}

.pe-14 {
  padding-right: 9rem !important;
}

.pe-15 {
  padding-right: 10rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 5rem !important;
}

.pb-11 {
  padding-bottom: 6rem !important;
}

.pb-12 {
  padding-bottom: 7rem !important;
}

.pb-13 {
  padding-bottom: 8rem !important;
}

.pb-14 {
  padding-bottom: 9rem !important;
}

.pb-15 {
  padding-bottom: 10rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 5rem !important;
}

.ps-11 {
  padding-left: 6rem !important;
}

.ps-12 {
  padding-left: 7rem !important;
}

.ps-13 {
  padding-left: 8rem !important;
}

.ps-14 {
  padding-left: 9rem !important;
}

.ps-15 {
  padding-left: 10rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 2.5rem !important;
}

.gap-7 {
  gap: 3rem !important;
}

.gap-8 {
  gap: 3.5rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 5rem !important;
}

.gap-11 {
  gap: 6rem !important;
}

.gap-12 {
  gap: 7rem !important;
}

.gap-13 {
  gap: 8rem !important;
}

.gap-14 {
  gap: 9rem !important;
}

.gap-15 {
  gap: 10rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: 200 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-semibold {
  font-weight: 500 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-200-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-800-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #8a8793 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(17, 15, 26, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-blob {
  border-radius: var(--bs-border-radius-blob) !important;
}

.rounded-block {
  border-radius: var(--bs-border-radius-block) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-top-block {
  border-top-left-radius: var(--bs-border-radius-block) !important;
  border-top-right-radius: var(--bs-border-radius-block) !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-end-block {
  border-top-right-radius: var(--bs-border-radius-block) !important;
  border-bottom-right-radius: var(--bs-border-radius-block) !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-bottom-block {
  border-bottom-right-radius: var(--bs-border-radius-block) !important;
  border-bottom-left-radius: var(--bs-border-radius-block) !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-start-block {
  border-bottom-left-radius: var(--bs-border-radius-block) !important;
  border-top-left-radius: var(--bs-border-radius-block) !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.rounded-top-start {
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-top-start-0 {
  border-top-left-radius: 0 !important;
}

.rounded-top-start-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-start-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-start-5 {
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-top-start-block {
  border-top-left-radius: var(--bs-border-radius-block) !important;
}

.rounded-top-start-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top-end {
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-end-0 {
  border-top-right-radius: 0 !important;
}

.rounded-top-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-end-5 {
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-top-end-block {
  border-top-right-radius: var(--bs-border-radius-block) !important;
}

.rounded-top-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-start-0 {
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-bottom-start-block {
  border-bottom-left-radius: var(--bs-border-radius-block) !important;
}

.rounded-bottom-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom-end {
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-end-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-end-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-end-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-end-5 {
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-bottom-end-block {
  border-bottom-right-radius: var(--bs-border-radius-block) !important;
}

.rounded-bottom-end-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.flex-center {
  align-items: center !important;
}

.flex-center {
  justify-content: center !important;
}

.bg-center {
  background-position: center center !important;
}

.bg-top-left {
  background-position: top left !important;
}

.bg-top-right {
  background-position: top right !important;
}

.bg-bottom-left {
  background-position: bottom left !important;
}

.bg-bottom-right {
  background-position: bottom right !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

.bg-100 {
  background-size: 100% !important;
}

.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.width-1x {
  width: 1rem !important;
}

.width-2x {
  width: 1.5rem !important;
}

.width-3x {
  width: 2rem !important;
}

.width-4x {
  width: 2.5rem !important;
}

.width-5x {
  width: 3rem !important;
}

.width-6x {
  width: 3.5rem !important;
}

.width-7x {
  width: 4rem !important;
}

.width-8x {
  width: 5rem !important;
}

.width-9x {
  width: 5.5rem !important;
}

.width-10x {
  width: 6rem !important;
}

.width-11x {
  width: 7rem !important;
}

.width-12x {
  width: 8rem !important;
}

.width-13x {
  width: 9rem !important;
}

.width-14x {
  width: 9.5rem !important;
}

.width-15x {
  width: 10rem !important;
}

.width-16x {
  width: 11rem !important;
}

.width-17x {
  width: 12rem !important;
}

.width-18x {
  width: 13rem !important;
}

.width-19x {
  width: 14rem !important;
}

.width-20x {
  width: 15rem !important;
}

.width-auto {
  width: auto !important;
}

.height-1x {
  height: 1rem !important;
}

.height-2x {
  height: 1.5rem !important;
}

.height-3x {
  height: 2rem !important;
}

.height-4x {
  height: 2.5rem !important;
}

.height-5x {
  height: 3rem !important;
}

.height-6x {
  height: 3.5rem !important;
}

.height-7x {
  height: 4rem !important;
}

.height-8x {
  height: 5rem !important;
}

.height-9x {
  height: 5.5rem !important;
}

.height-10x {
  height: 6rem !important;
}

.height-11x {
  height: 7rem !important;
}

.height-12x {
  height: 8rem !important;
}

.height-13x {
  height: 9rem !important;
}

.height-14x {
  height: 9.5rem !important;
}

.height-15x {
  height: 10rem !important;
}

.height-16x {
  height: 11rem !important;
}

.height-17x {
  height: 12rem !important;
}

.height-18x {
  height: 13rem !important;
}

.height-19x {
  height: 14rem !important;
}

.height-20x {
  height: 15rem !important;
}

.height-auto {
  height: auto !important;
}

.flip-y {
  transform: rotateZ(180deg) !important;
}

.flip-x {
  transform: rotateY(180deg) !important;
}

.rotate-1 {
  transform: rotate(1deg) !important;
}

.rotate-2 {
  transform: rotate(2deg) !important;
}

.rotate-3 {
  transform: rotate(3deg) !important;
}

.rotate-45 {
  transform: rotate(45deg) !important;
}

.rotate-n45 {
  transform: rotate(-45deg) !important;
}

.rotate-n3 {
  transform: rotate(-3deg) !important;
}

.rotate-none {
  transform: none !important;
}

.z-index-n1 {
  z-index: -1 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-fixed {
  z-index: 1030 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
  .border-top-sm {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-end-sm {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-end-sm-0 {
    border-right: 0 !important;
  }
  .border-bottom-sm {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-start-sm {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-start-sm-0 {
    border-left: 0 !important;
  }
  .w-sm-0 {
    width: 0 !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-0 {
    height: 0 !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-60 {
    height: 60% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .m-sm-10 {
    margin: 5rem !important;
  }
  .m-sm-11 {
    margin: 6rem !important;
  }
  .m-sm-12 {
    margin: 7rem !important;
  }
  .m-sm-13 {
    margin: 8rem !important;
  }
  .m-sm-14 {
    margin: 9rem !important;
  }
  .m-sm-15 {
    margin: 10rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-13 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-14 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-sm-15 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-13 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-14 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-sm-15 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 3rem !important;
  }
  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 4rem !important;
  }
  .mt-sm-10 {
    margin-top: 5rem !important;
  }
  .mt-sm-11 {
    margin-top: 6rem !important;
  }
  .mt-sm-12 {
    margin-top: 7rem !important;
  }
  .mt-sm-13 {
    margin-top: 8rem !important;
  }
  .mt-sm-14 {
    margin-top: 9rem !important;
  }
  .mt-sm-15 {
    margin-top: 10rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2rem !important;
  }
  .me-sm-6 {
    margin-right: 2.5rem !important;
  }
  .me-sm-7 {
    margin-right: 3rem !important;
  }
  .me-sm-8 {
    margin-right: 3.5rem !important;
  }
  .me-sm-9 {
    margin-right: 4rem !important;
  }
  .me-sm-10 {
    margin-right: 5rem !important;
  }
  .me-sm-11 {
    margin-right: 6rem !important;
  }
  .me-sm-12 {
    margin-right: 7rem !important;
  }
  .me-sm-13 {
    margin-right: 8rem !important;
  }
  .me-sm-14 {
    margin-right: 9rem !important;
  }
  .me-sm-15 {
    margin-right: 10rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 7rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 9rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2rem !important;
  }
  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 3rem !important;
  }
  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 4rem !important;
  }
  .ms-sm-10 {
    margin-left: 5rem !important;
  }
  .ms-sm-11 {
    margin-left: 6rem !important;
  }
  .ms-sm-12 {
    margin-left: 7rem !important;
  }
  .ms-sm-13 {
    margin-left: 8rem !important;
  }
  .ms-sm-14 {
    margin-left: 9rem !important;
  }
  .ms-sm-15 {
    margin-left: 10rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .m-sm-n10 {
    margin: -5rem !important;
  }
  .m-sm-n11 {
    margin: -6rem !important;
  }
  .m-sm-n12 {
    margin: -7rem !important;
  }
  .m-sm-n13 {
    margin: -8rem !important;
  }
  .m-sm-n14 {
    margin: -9rem !important;
  }
  .m-sm-n15 {
    margin: -10rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-sm-n13 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-sm-n14 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-sm-n15 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-sm-n13 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-sm-n14 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-sm-n15 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -2rem !important;
  }
  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -3rem !important;
  }
  .mt-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -4rem !important;
  }
  .mt-sm-n10 {
    margin-top: -5rem !important;
  }
  .mt-sm-n11 {
    margin-top: -6rem !important;
  }
  .mt-sm-n12 {
    margin-top: -7rem !important;
  }
  .mt-sm-n13 {
    margin-top: -8rem !important;
  }
  .mt-sm-n14 {
    margin-top: -9rem !important;
  }
  .mt-sm-n15 {
    margin-top: -10rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -2rem !important;
  }
  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -3rem !important;
  }
  .me-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n9 {
    margin-right: -4rem !important;
  }
  .me-sm-n10 {
    margin-right: -5rem !important;
  }
  .me-sm-n11 {
    margin-right: -6rem !important;
  }
  .me-sm-n12 {
    margin-right: -7rem !important;
  }
  .me-sm-n13 {
    margin-right: -8rem !important;
  }
  .me-sm-n14 {
    margin-right: -9rem !important;
  }
  .me-sm-n15 {
    margin-right: -10rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -7rem !important;
  }
  .mb-sm-n13 {
    margin-bottom: -8rem !important;
  }
  .mb-sm-n14 {
    margin-bottom: -9rem !important;
  }
  .mb-sm-n15 {
    margin-bottom: -10rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -2rem !important;
  }
  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -3rem !important;
  }
  .ms-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -4rem !important;
  }
  .ms-sm-n10 {
    margin-left: -5rem !important;
  }
  .ms-sm-n11 {
    margin-left: -6rem !important;
  }
  .ms-sm-n12 {
    margin-left: -7rem !important;
  }
  .ms-sm-n13 {
    margin-left: -8rem !important;
  }
  .ms-sm-n14 {
    margin-left: -9rem !important;
  }
  .ms-sm-n15 {
    margin-left: -10rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .p-sm-10 {
    padding: 5rem !important;
  }
  .p-sm-11 {
    padding: 6rem !important;
  }
  .p-sm-12 {
    padding: 7rem !important;
  }
  .p-sm-13 {
    padding: 8rem !important;
  }
  .p-sm-14 {
    padding: 9rem !important;
  }
  .p-sm-15 {
    padding: 10rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-sm-13 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-sm-14 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-sm-15 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-sm-13 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-sm-14 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-sm-15 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 3rem !important;
  }
  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 4rem !important;
  }
  .pt-sm-10 {
    padding-top: 5rem !important;
  }
  .pt-sm-11 {
    padding-top: 6rem !important;
  }
  .pt-sm-12 {
    padding-top: 7rem !important;
  }
  .pt-sm-13 {
    padding-top: 8rem !important;
  }
  .pt-sm-14 {
    padding-top: 9rem !important;
  }
  .pt-sm-15 {
    padding-top: 10rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2rem !important;
  }
  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 3rem !important;
  }
  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 4rem !important;
  }
  .pe-sm-10 {
    padding-right: 5rem !important;
  }
  .pe-sm-11 {
    padding-right: 6rem !important;
  }
  .pe-sm-12 {
    padding-right: 7rem !important;
  }
  .pe-sm-13 {
    padding-right: 8rem !important;
  }
  .pe-sm-14 {
    padding-right: 9rem !important;
  }
  .pe-sm-15 {
    padding-right: 10rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 7rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 9rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 10rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2rem !important;
  }
  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 3rem !important;
  }
  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 4rem !important;
  }
  .ps-sm-10 {
    padding-left: 5rem !important;
  }
  .ps-sm-11 {
    padding-left: 6rem !important;
  }
  .ps-sm-12 {
    padding-left: 7rem !important;
  }
  .ps-sm-13 {
    padding-left: 8rem !important;
  }
  .ps-sm-14 {
    padding-left: 9rem !important;
  }
  .ps-sm-15 {
    padding-left: 10rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2rem !important;
  }
  .gap-sm-6 {
    gap: 2.5rem !important;
  }
  .gap-sm-7 {
    gap: 3rem !important;
  }
  .gap-sm-8 {
    gap: 3.5rem !important;
  }
  .gap-sm-9 {
    gap: 4rem !important;
  }
  .gap-sm-10 {
    gap: 5rem !important;
  }
  .gap-sm-11 {
    gap: 6rem !important;
  }
  .gap-sm-12 {
    gap: 7rem !important;
  }
  .gap-sm-13 {
    gap: 8rem !important;
  }
  .gap-sm-14 {
    gap: 9rem !important;
  }
  .gap-sm-15 {
    gap: 10rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
  .border-top-md {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-end-md {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-end-md-0 {
    border-right: 0 !important;
  }
  .border-bottom-md {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-start-md {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-start-md-0 {
    border-left: 0 !important;
  }
  .w-md-0 {
    width: 0 !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-0 {
    height: 0 !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-60 {
    height: 60% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .m-md-10 {
    margin: 5rem !important;
  }
  .m-md-11 {
    margin: 6rem !important;
  }
  .m-md-12 {
    margin: 7rem !important;
  }
  .m-md-13 {
    margin: 8rem !important;
  }
  .m-md-14 {
    margin: 9rem !important;
  }
  .m-md-15 {
    margin: 10rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-13 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-14 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-md-15 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-13 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-14 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-md-15 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2rem !important;
  }
  .mt-md-6 {
    margin-top: 2.5rem !important;
  }
  .mt-md-7 {
    margin-top: 3rem !important;
  }
  .mt-md-8 {
    margin-top: 3.5rem !important;
  }
  .mt-md-9 {
    margin-top: 4rem !important;
  }
  .mt-md-10 {
    margin-top: 5rem !important;
  }
  .mt-md-11 {
    margin-top: 6rem !important;
  }
  .mt-md-12 {
    margin-top: 7rem !important;
  }
  .mt-md-13 {
    margin-top: 8rem !important;
  }
  .mt-md-14 {
    margin-top: 9rem !important;
  }
  .mt-md-15 {
    margin-top: 10rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2rem !important;
  }
  .me-md-6 {
    margin-right: 2.5rem !important;
  }
  .me-md-7 {
    margin-right: 3rem !important;
  }
  .me-md-8 {
    margin-right: 3.5rem !important;
  }
  .me-md-9 {
    margin-right: 4rem !important;
  }
  .me-md-10 {
    margin-right: 5rem !important;
  }
  .me-md-11 {
    margin-right: 6rem !important;
  }
  .me-md-12 {
    margin-right: 7rem !important;
  }
  .me-md-13 {
    margin-right: 8rem !important;
  }
  .me-md-14 {
    margin-right: 9rem !important;
  }
  .me-md-15 {
    margin-right: 10rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 3rem !important;
  }
  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 4rem !important;
  }
  .mb-md-10 {
    margin-bottom: 5rem !important;
  }
  .mb-md-11 {
    margin-bottom: 6rem !important;
  }
  .mb-md-12 {
    margin-bottom: 7rem !important;
  }
  .mb-md-13 {
    margin-bottom: 8rem !important;
  }
  .mb-md-14 {
    margin-bottom: 9rem !important;
  }
  .mb-md-15 {
    margin-bottom: 10rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2rem !important;
  }
  .ms-md-6 {
    margin-left: 2.5rem !important;
  }
  .ms-md-7 {
    margin-left: 3rem !important;
  }
  .ms-md-8 {
    margin-left: 3.5rem !important;
  }
  .ms-md-9 {
    margin-left: 4rem !important;
  }
  .ms-md-10 {
    margin-left: 5rem !important;
  }
  .ms-md-11 {
    margin-left: 6rem !important;
  }
  .ms-md-12 {
    margin-left: 7rem !important;
  }
  .ms-md-13 {
    margin-left: 8rem !important;
  }
  .ms-md-14 {
    margin-left: 9rem !important;
  }
  .ms-md-15 {
    margin-left: 10rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .m-md-n10 {
    margin: -5rem !important;
  }
  .m-md-n11 {
    margin: -6rem !important;
  }
  .m-md-n12 {
    margin: -7rem !important;
  }
  .m-md-n13 {
    margin: -8rem !important;
  }
  .m-md-n14 {
    margin: -9rem !important;
  }
  .m-md-n15 {
    margin: -10rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-md-n13 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-md-n14 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-md-n15 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-md-n13 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-md-n14 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-md-n15 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -2rem !important;
  }
  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -3rem !important;
  }
  .mt-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -4rem !important;
  }
  .mt-md-n10 {
    margin-top: -5rem !important;
  }
  .mt-md-n11 {
    margin-top: -6rem !important;
  }
  .mt-md-n12 {
    margin-top: -7rem !important;
  }
  .mt-md-n13 {
    margin-top: -8rem !important;
  }
  .mt-md-n14 {
    margin-top: -9rem !important;
  }
  .mt-md-n15 {
    margin-top: -10rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -2rem !important;
  }
  .me-md-n6 {
    margin-right: -2.5rem !important;
  }
  .me-md-n7 {
    margin-right: -3rem !important;
  }
  .me-md-n8 {
    margin-right: -3.5rem !important;
  }
  .me-md-n9 {
    margin-right: -4rem !important;
  }
  .me-md-n10 {
    margin-right: -5rem !important;
  }
  .me-md-n11 {
    margin-right: -6rem !important;
  }
  .me-md-n12 {
    margin-right: -7rem !important;
  }
  .me-md-n13 {
    margin-right: -8rem !important;
  }
  .me-md-n14 {
    margin-right: -9rem !important;
  }
  .me-md-n15 {
    margin-right: -10rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -7rem !important;
  }
  .mb-md-n13 {
    margin-bottom: -8rem !important;
  }
  .mb-md-n14 {
    margin-bottom: -9rem !important;
  }
  .mb-md-n15 {
    margin-bottom: -10rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -2rem !important;
  }
  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -3rem !important;
  }
  .ms-md-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n9 {
    margin-left: -4rem !important;
  }
  .ms-md-n10 {
    margin-left: -5rem !important;
  }
  .ms-md-n11 {
    margin-left: -6rem !important;
  }
  .ms-md-n12 {
    margin-left: -7rem !important;
  }
  .ms-md-n13 {
    margin-left: -8rem !important;
  }
  .ms-md-n14 {
    margin-left: -9rem !important;
  }
  .ms-md-n15 {
    margin-left: -10rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .p-md-10 {
    padding: 5rem !important;
  }
  .p-md-11 {
    padding: 6rem !important;
  }
  .p-md-12 {
    padding: 7rem !important;
  }
  .p-md-13 {
    padding: 8rem !important;
  }
  .p-md-14 {
    padding: 9rem !important;
  }
  .p-md-15 {
    padding: 10rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-md-13 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-md-14 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-md-15 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-md-13 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-md-14 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-md-15 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2rem !important;
  }
  .pt-md-6 {
    padding-top: 2.5rem !important;
  }
  .pt-md-7 {
    padding-top: 3rem !important;
  }
  .pt-md-8 {
    padding-top: 3.5rem !important;
  }
  .pt-md-9 {
    padding-top: 4rem !important;
  }
  .pt-md-10 {
    padding-top: 5rem !important;
  }
  .pt-md-11 {
    padding-top: 6rem !important;
  }
  .pt-md-12 {
    padding-top: 7rem !important;
  }
  .pt-md-13 {
    padding-top: 8rem !important;
  }
  .pt-md-14 {
    padding-top: 9rem !important;
  }
  .pt-md-15 {
    padding-top: 10rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2rem !important;
  }
  .pe-md-6 {
    padding-right: 2.5rem !important;
  }
  .pe-md-7 {
    padding-right: 3rem !important;
  }
  .pe-md-8 {
    padding-right: 3.5rem !important;
  }
  .pe-md-9 {
    padding-right: 4rem !important;
  }
  .pe-md-10 {
    padding-right: 5rem !important;
  }
  .pe-md-11 {
    padding-right: 6rem !important;
  }
  .pe-md-12 {
    padding-right: 7rem !important;
  }
  .pe-md-13 {
    padding-right: 8rem !important;
  }
  .pe-md-14 {
    padding-right: 9rem !important;
  }
  .pe-md-15 {
    padding-right: 10rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 3rem !important;
  }
  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 4rem !important;
  }
  .pb-md-10 {
    padding-bottom: 5rem !important;
  }
  .pb-md-11 {
    padding-bottom: 6rem !important;
  }
  .pb-md-12 {
    padding-bottom: 7rem !important;
  }
  .pb-md-13 {
    padding-bottom: 8rem !important;
  }
  .pb-md-14 {
    padding-bottom: 9rem !important;
  }
  .pb-md-15 {
    padding-bottom: 10rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2rem !important;
  }
  .ps-md-6 {
    padding-left: 2.5rem !important;
  }
  .ps-md-7 {
    padding-left: 3rem !important;
  }
  .ps-md-8 {
    padding-left: 3.5rem !important;
  }
  .ps-md-9 {
    padding-left: 4rem !important;
  }
  .ps-md-10 {
    padding-left: 5rem !important;
  }
  .ps-md-11 {
    padding-left: 6rem !important;
  }
  .ps-md-12 {
    padding-left: 7rem !important;
  }
  .ps-md-13 {
    padding-left: 8rem !important;
  }
  .ps-md-14 {
    padding-left: 9rem !important;
  }
  .ps-md-15 {
    padding-left: 10rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2rem !important;
  }
  .gap-md-6 {
    gap: 2.5rem !important;
  }
  .gap-md-7 {
    gap: 3rem !important;
  }
  .gap-md-8 {
    gap: 3.5rem !important;
  }
  .gap-md-9 {
    gap: 4rem !important;
  }
  .gap-md-10 {
    gap: 5rem !important;
  }
  .gap-md-11 {
    gap: 6rem !important;
  }
  .gap-md-12 {
    gap: 7rem !important;
  }
  .gap-md-13 {
    gap: 8rem !important;
  }
  .gap-md-14 {
    gap: 9rem !important;
  }
  .gap-md-15 {
    gap: 10rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .border-top-lg {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-end-lg {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-end-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-start-lg {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-start-lg-0 {
    border-left: 0 !important;
  }
  .w-lg-0 {
    width: 0 !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-0 {
    height: 0 !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-60 {
    height: 60% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .m-lg-10 {
    margin: 5rem !important;
  }
  .m-lg-11 {
    margin: 6rem !important;
  }
  .m-lg-12 {
    margin: 7rem !important;
  }
  .m-lg-13 {
    margin: 8rem !important;
  }
  .m-lg-14 {
    margin: 9rem !important;
  }
  .m-lg-15 {
    margin: 10rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-13 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-14 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-lg-15 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-13 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-14 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-lg-15 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2rem !important;
  }
  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 3rem !important;
  }
  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 4rem !important;
  }
  .mt-lg-10 {
    margin-top: 5rem !important;
  }
  .mt-lg-11 {
    margin-top: 6rem !important;
  }
  .mt-lg-12 {
    margin-top: 7rem !important;
  }
  .mt-lg-13 {
    margin-top: 8rem !important;
  }
  .mt-lg-14 {
    margin-top: 9rem !important;
  }
  .mt-lg-15 {
    margin-top: 10rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2rem !important;
  }
  .me-lg-6 {
    margin-right: 2.5rem !important;
  }
  .me-lg-7 {
    margin-right: 3rem !important;
  }
  .me-lg-8 {
    margin-right: 3.5rem !important;
  }
  .me-lg-9 {
    margin-right: 4rem !important;
  }
  .me-lg-10 {
    margin-right: 5rem !important;
  }
  .me-lg-11 {
    margin-right: 6rem !important;
  }
  .me-lg-12 {
    margin-right: 7rem !important;
  }
  .me-lg-13 {
    margin-right: 8rem !important;
  }
  .me-lg-14 {
    margin-right: 9rem !important;
  }
  .me-lg-15 {
    margin-right: 10rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2rem !important;
  }
  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 3rem !important;
  }
  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 4rem !important;
  }
  .ms-lg-10 {
    margin-left: 5rem !important;
  }
  .ms-lg-11 {
    margin-left: 6rem !important;
  }
  .ms-lg-12 {
    margin-left: 7rem !important;
  }
  .ms-lg-13 {
    margin-left: 8rem !important;
  }
  .ms-lg-14 {
    margin-left: 9rem !important;
  }
  .ms-lg-15 {
    margin-left: 10rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .m-lg-n10 {
    margin: -5rem !important;
  }
  .m-lg-n11 {
    margin: -6rem !important;
  }
  .m-lg-n12 {
    margin: -7rem !important;
  }
  .m-lg-n13 {
    margin: -8rem !important;
  }
  .m-lg-n14 {
    margin: -9rem !important;
  }
  .m-lg-n15 {
    margin: -10rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-lg-n13 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-lg-n14 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-lg-n15 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-lg-n13 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-lg-n14 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-lg-n15 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -2rem !important;
  }
  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -3rem !important;
  }
  .mt-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -4rem !important;
  }
  .mt-lg-n10 {
    margin-top: -5rem !important;
  }
  .mt-lg-n11 {
    margin-top: -6rem !important;
  }
  .mt-lg-n12 {
    margin-top: -7rem !important;
  }
  .mt-lg-n13 {
    margin-top: -8rem !important;
  }
  .mt-lg-n14 {
    margin-top: -9rem !important;
  }
  .mt-lg-n15 {
    margin-top: -10rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -2rem !important;
  }
  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -3rem !important;
  }
  .me-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n9 {
    margin-right: -4rem !important;
  }
  .me-lg-n10 {
    margin-right: -5rem !important;
  }
  .me-lg-n11 {
    margin-right: -6rem !important;
  }
  .me-lg-n12 {
    margin-right: -7rem !important;
  }
  .me-lg-n13 {
    margin-right: -8rem !important;
  }
  .me-lg-n14 {
    margin-right: -9rem !important;
  }
  .me-lg-n15 {
    margin-right: -10rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -7rem !important;
  }
  .mb-lg-n13 {
    margin-bottom: -8rem !important;
  }
  .mb-lg-n14 {
    margin-bottom: -9rem !important;
  }
  .mb-lg-n15 {
    margin-bottom: -10rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -2rem !important;
  }
  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -3rem !important;
  }
  .ms-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -4rem !important;
  }
  .ms-lg-n10 {
    margin-left: -5rem !important;
  }
  .ms-lg-n11 {
    margin-left: -6rem !important;
  }
  .ms-lg-n12 {
    margin-left: -7rem !important;
  }
  .ms-lg-n13 {
    margin-left: -8rem !important;
  }
  .ms-lg-n14 {
    margin-left: -9rem !important;
  }
  .ms-lg-n15 {
    margin-left: -10rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .p-lg-10 {
    padding: 5rem !important;
  }
  .p-lg-11 {
    padding: 6rem !important;
  }
  .p-lg-12 {
    padding: 7rem !important;
  }
  .p-lg-13 {
    padding: 8rem !important;
  }
  .p-lg-14 {
    padding: 9rem !important;
  }
  .p-lg-15 {
    padding: 10rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-lg-13 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-lg-14 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-lg-15 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-lg-13 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-lg-14 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-lg-15 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2rem !important;
  }
  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 3rem !important;
  }
  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 4rem !important;
  }
  .pt-lg-10 {
    padding-top: 5rem !important;
  }
  .pt-lg-11 {
    padding-top: 6rem !important;
  }
  .pt-lg-12 {
    padding-top: 7rem !important;
  }
  .pt-lg-13 {
    padding-top: 8rem !important;
  }
  .pt-lg-14 {
    padding-top: 9rem !important;
  }
  .pt-lg-15 {
    padding-top: 10rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2rem !important;
  }
  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 3rem !important;
  }
  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 4rem !important;
  }
  .pe-lg-10 {
    padding-right: 5rem !important;
  }
  .pe-lg-11 {
    padding-right: 6rem !important;
  }
  .pe-lg-12 {
    padding-right: 7rem !important;
  }
  .pe-lg-13 {
    padding-right: 8rem !important;
  }
  .pe-lg-14 {
    padding-right: 9rem !important;
  }
  .pe-lg-15 {
    padding-right: 10rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 7rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 9rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 10rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2rem !important;
  }
  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 3rem !important;
  }
  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 4rem !important;
  }
  .ps-lg-10 {
    padding-left: 5rem !important;
  }
  .ps-lg-11 {
    padding-left: 6rem !important;
  }
  .ps-lg-12 {
    padding-left: 7rem !important;
  }
  .ps-lg-13 {
    padding-left: 8rem !important;
  }
  .ps-lg-14 {
    padding-left: 9rem !important;
  }
  .ps-lg-15 {
    padding-left: 10rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2rem !important;
  }
  .gap-lg-6 {
    gap: 2.5rem !important;
  }
  .gap-lg-7 {
    gap: 3rem !important;
  }
  .gap-lg-8 {
    gap: 3.5rem !important;
  }
  .gap-lg-9 {
    gap: 4rem !important;
  }
  .gap-lg-10 {
    gap: 5rem !important;
  }
  .gap-lg-11 {
    gap: 6rem !important;
  }
  .gap-lg-12 {
    gap: 7rem !important;
  }
  .gap-lg-13 {
    gap: 8rem !important;
  }
  .gap-lg-14 {
    gap: 9rem !important;
  }
  .gap-lg-15 {
    gap: 10rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .border-top-xl {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-end-xl {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-end-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xl {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-start-xl-0 {
    border-left: 0 !important;
  }
  .w-xl-0 {
    width: 0 !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-0 {
    height: 0 !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-60 {
    height: 60% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .m-xl-10 {
    margin: 5rem !important;
  }
  .m-xl-11 {
    margin: 6rem !important;
  }
  .m-xl-12 {
    margin: 7rem !important;
  }
  .m-xl-13 {
    margin: 8rem !important;
  }
  .m-xl-14 {
    margin: 9rem !important;
  }
  .m-xl-15 {
    margin: 10rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-13 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-14 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xl-15 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-13 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-14 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xl-15 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2rem !important;
  }
  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 3rem !important;
  }
  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 4rem !important;
  }
  .mt-xl-10 {
    margin-top: 5rem !important;
  }
  .mt-xl-11 {
    margin-top: 6rem !important;
  }
  .mt-xl-12 {
    margin-top: 7rem !important;
  }
  .mt-xl-13 {
    margin-top: 8rem !important;
  }
  .mt-xl-14 {
    margin-top: 9rem !important;
  }
  .mt-xl-15 {
    margin-top: 10rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2rem !important;
  }
  .me-xl-6 {
    margin-right: 2.5rem !important;
  }
  .me-xl-7 {
    margin-right: 3rem !important;
  }
  .me-xl-8 {
    margin-right: 3.5rem !important;
  }
  .me-xl-9 {
    margin-right: 4rem !important;
  }
  .me-xl-10 {
    margin-right: 5rem !important;
  }
  .me-xl-11 {
    margin-right: 6rem !important;
  }
  .me-xl-12 {
    margin-right: 7rem !important;
  }
  .me-xl-13 {
    margin-right: 8rem !important;
  }
  .me-xl-14 {
    margin-right: 9rem !important;
  }
  .me-xl-15 {
    margin-right: 10rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2rem !important;
  }
  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 3rem !important;
  }
  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 4rem !important;
  }
  .ms-xl-10 {
    margin-left: 5rem !important;
  }
  .ms-xl-11 {
    margin-left: 6rem !important;
  }
  .ms-xl-12 {
    margin-left: 7rem !important;
  }
  .ms-xl-13 {
    margin-left: 8rem !important;
  }
  .ms-xl-14 {
    margin-left: 9rem !important;
  }
  .ms-xl-15 {
    margin-left: 10rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .m-xl-n10 {
    margin: -5rem !important;
  }
  .m-xl-n11 {
    margin: -6rem !important;
  }
  .m-xl-n12 {
    margin: -7rem !important;
  }
  .m-xl-n13 {
    margin: -8rem !important;
  }
  .m-xl-n14 {
    margin: -9rem !important;
  }
  .m-xl-n15 {
    margin: -10rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-xl-n13 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-xl-n14 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-xl-n15 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-xl-n13 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-xl-n14 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-xl-n15 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -3rem !important;
  }
  .mt-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xl-n10 {
    margin-top: -5rem !important;
  }
  .mt-xl-n11 {
    margin-top: -6rem !important;
  }
  .mt-xl-n12 {
    margin-top: -7rem !important;
  }
  .mt-xl-n13 {
    margin-top: -8rem !important;
  }
  .mt-xl-n14 {
    margin-top: -9rem !important;
  }
  .mt-xl-n15 {
    margin-top: -10rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -2rem !important;
  }
  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -3rem !important;
  }
  .me-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n9 {
    margin-right: -4rem !important;
  }
  .me-xl-n10 {
    margin-right: -5rem !important;
  }
  .me-xl-n11 {
    margin-right: -6rem !important;
  }
  .me-xl-n12 {
    margin-right: -7rem !important;
  }
  .me-xl-n13 {
    margin-right: -8rem !important;
  }
  .me-xl-n14 {
    margin-right: -9rem !important;
  }
  .me-xl-n15 {
    margin-right: -10rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -7rem !important;
  }
  .mb-xl-n13 {
    margin-bottom: -8rem !important;
  }
  .mb-xl-n14 {
    margin-bottom: -9rem !important;
  }
  .mb-xl-n15 {
    margin-bottom: -10rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -3rem !important;
  }
  .ms-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xl-n10 {
    margin-left: -5rem !important;
  }
  .ms-xl-n11 {
    margin-left: -6rem !important;
  }
  .ms-xl-n12 {
    margin-left: -7rem !important;
  }
  .ms-xl-n13 {
    margin-left: -8rem !important;
  }
  .ms-xl-n14 {
    margin-left: -9rem !important;
  }
  .ms-xl-n15 {
    margin-left: -10rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .p-xl-10 {
    padding: 5rem !important;
  }
  .p-xl-11 {
    padding: 6rem !important;
  }
  .p-xl-12 {
    padding: 7rem !important;
  }
  .p-xl-13 {
    padding: 8rem !important;
  }
  .p-xl-14 {
    padding: 9rem !important;
  }
  .p-xl-15 {
    padding: 10rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xl-13 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xl-14 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xl-15 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xl-13 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xl-14 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xl-15 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2rem !important;
  }
  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 3rem !important;
  }
  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 4rem !important;
  }
  .pt-xl-10 {
    padding-top: 5rem !important;
  }
  .pt-xl-11 {
    padding-top: 6rem !important;
  }
  .pt-xl-12 {
    padding-top: 7rem !important;
  }
  .pt-xl-13 {
    padding-top: 8rem !important;
  }
  .pt-xl-14 {
    padding-top: 9rem !important;
  }
  .pt-xl-15 {
    padding-top: 10rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2rem !important;
  }
  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 3rem !important;
  }
  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 4rem !important;
  }
  .pe-xl-10 {
    padding-right: 5rem !important;
  }
  .pe-xl-11 {
    padding-right: 6rem !important;
  }
  .pe-xl-12 {
    padding-right: 7rem !important;
  }
  .pe-xl-13 {
    padding-right: 8rem !important;
  }
  .pe-xl-14 {
    padding-right: 9rem !important;
  }
  .pe-xl-15 {
    padding-right: 10rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 7rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 9rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 10rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2rem !important;
  }
  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 3rem !important;
  }
  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 4rem !important;
  }
  .ps-xl-10 {
    padding-left: 5rem !important;
  }
  .ps-xl-11 {
    padding-left: 6rem !important;
  }
  .ps-xl-12 {
    padding-left: 7rem !important;
  }
  .ps-xl-13 {
    padding-left: 8rem !important;
  }
  .ps-xl-14 {
    padding-left: 9rem !important;
  }
  .ps-xl-15 {
    padding-left: 10rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2rem !important;
  }
  .gap-xl-6 {
    gap: 2.5rem !important;
  }
  .gap-xl-7 {
    gap: 3rem !important;
  }
  .gap-xl-8 {
    gap: 3.5rem !important;
  }
  .gap-xl-9 {
    gap: 4rem !important;
  }
  .gap-xl-10 {
    gap: 5rem !important;
  }
  .gap-xl-11 {
    gap: 6rem !important;
  }
  .gap-xl-12 {
    gap: 7rem !important;
  }
  .gap-xl-13 {
    gap: 8rem !important;
  }
  .gap-xl-14 {
    gap: 9rem !important;
  }
  .gap-xl-15 {
    gap: 10rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-sticky {
    position: sticky !important;
  }
  .border-top-xxl {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-end-xxl {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-end-xxl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xxl {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xxl {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-start-xxl-0 {
    border-left: 0 !important;
  }
  .w-xxl-0 {
    width: 0 !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .h-xxl-0 {
    height: 0 !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-60 {
    height: 60% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2rem !important;
  }
  .m-xxl-6 {
    margin: 2.5rem !important;
  }
  .m-xxl-7 {
    margin: 3rem !important;
  }
  .m-xxl-8 {
    margin: 3.5rem !important;
  }
  .m-xxl-9 {
    margin: 4rem !important;
  }
  .m-xxl-10 {
    margin: 5rem !important;
  }
  .m-xxl-11 {
    margin: 6rem !important;
  }
  .m-xxl-12 {
    margin: 7rem !important;
  }
  .m-xxl-13 {
    margin: 8rem !important;
  }
  .m-xxl-14 {
    margin: 9rem !important;
  }
  .m-xxl-15 {
    margin: 10rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-13 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-14 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .mx-xxl-15 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-13 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-14 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .my-xxl-15 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2rem !important;
  }
  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 3rem !important;
  }
  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 4rem !important;
  }
  .mt-xxl-10 {
    margin-top: 5rem !important;
  }
  .mt-xxl-11 {
    margin-top: 6rem !important;
  }
  .mt-xxl-12 {
    margin-top: 7rem !important;
  }
  .mt-xxl-13 {
    margin-top: 8rem !important;
  }
  .mt-xxl-14 {
    margin-top: 9rem !important;
  }
  .mt-xxl-15 {
    margin-top: 10rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 2rem !important;
  }
  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 3rem !important;
  }
  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-9 {
    margin-right: 4rem !important;
  }
  .me-xxl-10 {
    margin-right: 5rem !important;
  }
  .me-xxl-11 {
    margin-right: 6rem !important;
  }
  .me-xxl-12 {
    margin-right: 7rem !important;
  }
  .me-xxl-13 {
    margin-right: 8rem !important;
  }
  .me-xxl-14 {
    margin-right: 9rem !important;
  }
  .me-xxl-15 {
    margin-right: 10rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2rem !important;
  }
  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 3rem !important;
  }
  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 4rem !important;
  }
  .ms-xxl-10 {
    margin-left: 5rem !important;
  }
  .ms-xxl-11 {
    margin-left: 6rem !important;
  }
  .ms-xxl-12 {
    margin-left: 7rem !important;
  }
  .ms-xxl-13 {
    margin-left: 8rem !important;
  }
  .ms-xxl-14 {
    margin-left: 9rem !important;
  }
  .ms-xxl-15 {
    margin-left: 10rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2rem !important;
  }
  .m-xxl-n6 {
    margin: -2.5rem !important;
  }
  .m-xxl-n7 {
    margin: -3rem !important;
  }
  .m-xxl-n8 {
    margin: -3.5rem !important;
  }
  .m-xxl-n9 {
    margin: -4rem !important;
  }
  .m-xxl-n10 {
    margin: -5rem !important;
  }
  .m-xxl-n11 {
    margin: -6rem !important;
  }
  .m-xxl-n12 {
    margin: -7rem !important;
  }
  .m-xxl-n13 {
    margin: -8rem !important;
  }
  .m-xxl-n14 {
    margin: -9rem !important;
  }
  .m-xxl-n15 {
    margin: -10rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-xxl-n13 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-xxl-n14 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .mx-xxl-n15 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-xxl-n13 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-xxl-n14 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .my-xxl-n15 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n12 {
    margin-top: -7rem !important;
  }
  .mt-xxl-n13 {
    margin-top: -8rem !important;
  }
  .mt-xxl-n14 {
    margin-top: -9rem !important;
  }
  .mt-xxl-n15 {
    margin-top: -10rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -2rem !important;
  }
  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -3rem !important;
  }
  .me-xxl-n8 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -4rem !important;
  }
  .me-xxl-n10 {
    margin-right: -5rem !important;
  }
  .me-xxl-n11 {
    margin-right: -6rem !important;
  }
  .me-xxl-n12 {
    margin-right: -7rem !important;
  }
  .me-xxl-n13 {
    margin-right: -8rem !important;
  }
  .me-xxl-n14 {
    margin-right: -9rem !important;
  }
  .me-xxl-n15 {
    margin-right: -10rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n12 {
    margin-bottom: -7rem !important;
  }
  .mb-xxl-n13 {
    margin-bottom: -8rem !important;
  }
  .mb-xxl-n14 {
    margin-bottom: -9rem !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -10rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n12 {
    margin-left: -7rem !important;
  }
  .ms-xxl-n13 {
    margin-left: -8rem !important;
  }
  .ms-xxl-n14 {
    margin-left: -9rem !important;
  }
  .ms-xxl-n15 {
    margin-left: -10rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2rem !important;
  }
  .p-xxl-6 {
    padding: 2.5rem !important;
  }
  .p-xxl-7 {
    padding: 3rem !important;
  }
  .p-xxl-8 {
    padding: 3.5rem !important;
  }
  .p-xxl-9 {
    padding: 4rem !important;
  }
  .p-xxl-10 {
    padding: 5rem !important;
  }
  .p-xxl-11 {
    padding: 6rem !important;
  }
  .p-xxl-12 {
    padding: 7rem !important;
  }
  .p-xxl-13 {
    padding: 8rem !important;
  }
  .p-xxl-14 {
    padding: 9rem !important;
  }
  .p-xxl-15 {
    padding: 10rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xxl-13 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxl-14 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .px-xxl-15 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xxl-13 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxl-14 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-xxl-15 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2rem !important;
  }
  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 3rem !important;
  }
  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 4rem !important;
  }
  .pt-xxl-10 {
    padding-top: 5rem !important;
  }
  .pt-xxl-11 {
    padding-top: 6rem !important;
  }
  .pt-xxl-12 {
    padding-top: 7rem !important;
  }
  .pt-xxl-13 {
    padding-top: 8rem !important;
  }
  .pt-xxl-14 {
    padding-top: 9rem !important;
  }
  .pt-xxl-15 {
    padding-top: 10rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2rem !important;
  }
  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 3rem !important;
  }
  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 4rem !important;
  }
  .pe-xxl-10 {
    padding-right: 5rem !important;
  }
  .pe-xxl-11 {
    padding-right: 6rem !important;
  }
  .pe-xxl-12 {
    padding-right: 7rem !important;
  }
  .pe-xxl-13 {
    padding-right: 8rem !important;
  }
  .pe-xxl-14 {
    padding-right: 9rem !important;
  }
  .pe-xxl-15 {
    padding-right: 10rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 7rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 9rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 10rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2rem !important;
  }
  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 3rem !important;
  }
  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 4rem !important;
  }
  .ps-xxl-10 {
    padding-left: 5rem !important;
  }
  .ps-xxl-11 {
    padding-left: 6rem !important;
  }
  .ps-xxl-12 {
    padding-left: 7rem !important;
  }
  .ps-xxl-13 {
    padding-left: 8rem !important;
  }
  .ps-xxl-14 {
    padding-left: 9rem !important;
  }
  .ps-xxl-15 {
    padding-left: 10rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 2rem !important;
  }
  .gap-xxl-6 {
    gap: 2.5rem !important;
  }
  .gap-xxl-7 {
    gap: 3rem !important;
  }
  .gap-xxl-8 {
    gap: 3.5rem !important;
  }
  .gap-xxl-9 {
    gap: 4rem !important;
  }
  .gap-xxl-10 {
    gap: 5rem !important;
  }
  .gap-xxl-11 {
    gap: 6rem !important;
  }
  .gap-xxl-12 {
    gap: 7rem !important;
  }
  .gap-xxl-13 {
    gap: 8rem !important;
  }
  .gap-xxl-14 {
    gap: 9rem !important;
  }
  .gap-xxl-15 {
    gap: 10rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root {
  --bs-font-serif: "Source Serif Pro", serif;
  --bs-border-radius-block: 3.25rem;
  --bs-border-radius-blob: 30% 70% 70% 30%/30% 30% 70% 70%;
}

/**
 * transition mixin
 * @type {map}
 */
body {
  letter-spacing: 0rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}
body:not(.loaded) {
  overflow-x: hidden;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
html, body {
  min-height: 100%;
}

* {
  outline: 0 !important;
}
*:focus {
  outline: 0 !important;
}

a {
  transition: all 0.2s ease-in-out;
}

h1,
.h1,
h2,
.h2 {
  letter-spacing: -0.044rem;
}

h3,
.h3, h4, .h4,
h5,
h6,
.h5,
.h6 {
  letter-spacing: -0.022rem;
  font-weight: 500;
}

.ls-0 {
  letter-spacing: 0px;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 3px;
}

.ls-4 {
  letter-spacing: 4px;
}

.ls-5 {
  letter-spacing: 5px;
}

.text-strikethrough {
  text-decoration: line-through;
}

.font-serif {
  font-family: var(--bs-font-serif) !important;
  letter-spacing: 0;
  font-weight: 400;
}
.font-serif.display-6, .font-serif.display-5, .font-serif.display-4, .font-serif.display-3, .font-serif.display-2, .font-serif.display-1 {
  font-weight: 400;
}

.text-mono {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-weight: 400;
}

.text-italic {
  font-style: italic;
}

sup, sub {
  letter-spacing: 0rem;
}

.text-white,
.text-light {
  --bs-body-color:#fff;
  --bs-body-color-rgb: 255,255,255;
  --bs-text-opacity: 1;
}
.text-white .text-muted,
.text-light .text-muted {
  --bs-text-opacity: .6;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.text-white a:not(.btn):not(.dropdown-item):not([class*=text-]):not(.nav-link),
.text-light a:not(.btn):not(.dropdown-item):not([class*=text-]):not(.nav-link) {
  --bs-text-opacity: .75;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.text-white a:not(.btn):not(.dropdown-item):not([class*=text-]):not(.nav-link):hover,
.text-light a:not(.btn):not(.dropdown-item):not([class*=text-]):not(.nav-link):hover {
  --bs-text-opacity: 1;
}
.text-white a.text-muted, .text-white a.text-body,
.text-light a.text-muted,
.text-light a.text-body {
  --bs-text-opacity: .6;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-white a.text-muted:hover, .text-white a.text-body:hover,
.text-light a.text-muted:hover,
.text-light a.text-body:hover {
  --bs-text-opacity: 1;
}

.footer:not(.text-white) {
  --bs-link-color: var(--bs-body-color);
}

.text-gradient {
  background: radial-gradient(circle at 70%, #f94770 10%, #110f1a 30%);
  background-size: 400% 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  animation: textGradientAnimation 5s ease-in-out infinite;
  -webkit-animation: textGradientAnimation 5s ease-in-out infinite;
}

.text-gradient-light {
  background: radial-gradient(circle at 70%, #fff 10%, #88878d 30%);
  background-size: 400% 400%;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  animation: textGradientAnimation 5s ease-in-out infinite;
  -webkit-animation: textGradientAnimation 5s ease-in-out infinite;
}

.dropcap::first-letter {
  float: left;
  line-height: 0.5;
  font-weight: 600;
  font-size: 2.5rem;
  margin: 0 6px 0 0;
  color: #110f1a;
}

.text-outline {
  -webkit-text-stroke-width: 2px;
  text-decoration: none !important;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  background: transparent;
  -webkit-text-fill-color: transparent;
}

.text-outline.text-primary {
  -webkit-text-stroke: 2px #110f1a;
  color: transparent !important;
}

.text-outline.text-secondary {
  -webkit-text-stroke: 2px #352f4a;
  color: transparent !important;
}

.text-outline.text-success {
  -webkit-text-stroke: 2px #35a552;
  color: transparent !important;
}

.text-outline.text-info {
  -webkit-text-stroke: 2px #00c2ff;
  color: transparent !important;
}

.text-outline.text-warning {
  -webkit-text-stroke: 2px #ffd751;
  color: transparent !important;
}

.text-outline.text-danger {
  -webkit-text-stroke: 2px #f94770;
  color: transparent !important;
}

.text-outline.text-light {
  -webkit-text-stroke: 2px #f9f8fc;
  color: transparent !important;
}

.text-outline.text-white {
  -webkit-text-stroke: 2px #fff;
  color: transparent !important;
}

.text-outline.text-dark {
  -webkit-text-stroke: 2px #110f1a;
  color: transparent !important;
}

.text-outline.text-gray-200 {
  -webkit-text-stroke: 2px #eae8f0;
  color: transparent !important;
}

.text-outline.text-gray-800 {
  -webkit-text-stroke: 2px #262136;
  color: transparent !important;
}

b, strong {
  font-weight: 500;
}

.bx {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

@keyframes textGradientAnimation {
  0% {
    background-position: 50% 30%;
  }
  25% {
    background-position: 100% 10%;
  }
  50% {
    background-position: 100% 90%;
  }
  75% {
    background-position: 60% 90%;
  }
  100% {
    background-position: 50% 30%;
  }
}
@keyframes transform-x {
  0% {
    opacity: 0;
    transform: translateX(100%);
    animation-timing-function: ease;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes scaleIn {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  to {
    transform: scaleX(1);
    transform-origin: left;
  }
}
@keyframes scaleOut {
  0% {
    transform: scaleX(1);
    transform-origin: right;
  }
  to {
    transform: scaleX(0);
    transform-origin: right;
  }
}
@keyframes fadeDown {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
@keyframes underline-both {
  to {
    transform: translate3d(101%, 0, 0);
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes connectLine {
  0% {
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  50% {
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  51% {
    transform-origin: 0 100%;
    transform: scaleY(1);
  }
  100% {
    transform-origin: 0 100%;
    transform: scaleY(0);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes slide-bg-2 {
  33.3% {
    transform-origin: left top;
    transform: scale(0.05, 1);
  }
  66.6% {
    transform-origin: left center;
    transform: scale(1, 1);
  }
  66.7% {
    transform-origin: right center;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: right center;
    transform: scale(0, 1);
  }
}
@keyframes show-img-1 {
  0%, 66.6% {
    visibility: hidden;
  }
  100%, 66.7% {
    visibility: visible;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
.card-hover .card-img, .card-hover .hover-arrow {
  transition: transform 0.4s;
}
.card-hover .hover-visible {
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.card-hover .hover-visible .btn {
  border: 0px;
}
.card-hover:hover .card-img {
  transform: scale(1.15);
}
.card-hover:hover .hover-arrow {
  transform: translateX(5px);
}
.card-hover:hover .hover-visible {
  transform: none;
  opacity: 1;
}

.btn-favorite {
  position: relative;
}
.btn-favorite::before {
  content: "\e87e";
  font-family: "Material Icons";
  font-size: 1.1rem;
  vertical-align: middle;
  margin-right: 0.25rem;
  color: currentColor;
}
.btn-favorite.active {
  color: #f94770;
}
.btn-favorite.active::before {
  content: "\e87d";
}

.countdown {
  min-width: 200px;
}

.card-divider {
  color: var(--bs-card-bg);
}

.swiper-pagination .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background-color: #cfcfd1;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #292731;
}

.swiper-prev, .swiper-next {
  transition: all 0.25s;
}

.swiper-button-disabled {
  opacity: 0.25;
  pointer-events: none;
}

.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: 200px;
}

.tippy-box {
  opacity: 0.9;
  position: relative;
  background-color: #110f1a;
  color: #fff;
  border-radius: 0.475rem;
  font-size: 0.875rem;
  line-height: 1.6;
  white-space: normal;
  box-shadow: 0 0.5rem 1rem rgba(17, 15, 26, 0.0875);
  outline: 0;
  transition-property: transform, visibility, opacity;
}
.tippy-box a:not(.btn) {
  color: #fff;
  text-decoration: underline;
}
.tippy-box a:not(.btn):hover {
  text-decoration: none;
}

.tippy-box[data-placement^=top] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^=top] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}

.tippy-box[data-placement^=bottom] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^=bottom] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}

.tippy-box[data-placement^=left] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^=left] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}

.tippy-box[data-placement^=right] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^=right] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-box[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #110f1a;
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  position: relative;
  padding: 0.75rem 1rem;
  z-index: 1;
}

.tippy-box[data-animation=shift-toward][data-state=hidden] {
  opacity: 0;
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=top] {
  transform: translateY(-10px);
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=bottom] {
  transform: translateY(10px);
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=left] {
  transform: translateX(-10px);
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=right] {
  transform: translateX(10px);
}

.accordion-list .accordion-item {
  border-radius: 0.475rem;
  border: 1px solid #eae8f0;
}
.accordion-list .accordion-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.accordion-list .accordion-body {
  padding: 1rem;
  padding-top: 0.5rem;
}
.accordion-list .accordion-header .accordion-button {
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
}
.accordion-list .accordion-header .accordion-button::after {
  content: "\e5cf";
  font-family: "Material Icons";
  margin-left: auto;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transition: all 0.25s;
}
.accordion-list .accordion-header .accordion-button[aria-expanded=true]::after {
  transform: rotate(180deg);
}

.accordion-button {
  font-weight: 500;
}

.accordion {
  --bs-accordion-color: #1a1726;
}

.accordion.accordion-custom .accordion-item {
  border: 0px;
  border-radius: 0.475rem;
}
.accordion.accordion-custom .accordion-body {
  padding-top: 0;
}
.accordion.accordion-custom .accordion-button {
  transition: all 0.25s;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.475rem;
  box-shadow: 0 0 0.75rem 0 rgba(17, 15, 26, 0.0875);
}
.accordion.accordion-custom .accordion-button::after {
  background-image: none;
  font-weight: 400;
  font-family: boxicons !important;
  transform: rotate(90deg);
  content: "\ed91";
  font-size: 0.875rem;
  color: currentColor;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
}
.accordion.accordion-custom .accordion-collapse, .accordion.accordion-custom .accordion-button:not(.collapsed) {
  background-color: #e7e7e8;
}
.accordion.accordion-custom .accordion-collapse {
  border-bottom-left-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}
.accordion.accordion-custom .accordion-button:not(.collapsed) {
  box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion.accordion-custom .accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(0);
  color: #110f1a;
}

.avatar {
  width: 3rem;
  height: 3rem;
}
.avatar.xs {
  width: 1.75rem;
  height: 1.75rem;
}
.avatar.sm {
  width: 2rem;
  height: 2rem;
}
.avatar.lg {
  width: 4rem;
  height: 4rem;
}
.avatar.xl {
  width: 5rem;
  height: 5rem;
}

.avatar-status {
  position: relative;
  display: inline-block;
}
.avatar-status::after {
  content: "";
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 20%;
  height: 20%;
  border: 2px solid #fff;
  border-radius: 50%;
}
.avatar-status.off::after {
  background-color: #e6e5ec;
}
.avatar-status.on::after {
  background-color: #30954a;
}
.avatar-status.away::after {
  background-color: #e6c249;
}
.avatar-status.dnd::after {
  background-color: #dc3545;
}
.avatar-status:not(.rounded-pill):not(.rounded-circle)::after {
  margin-right: -0.4rem;
  margin-bottom: -0.4rem;
}
.avatar-status.rounded-pill > img {
  border-radius: 50rem;
}
.avatar-status.rounded-circle > img {
  border-radius: 100%;
}
.avatar-status.rounded-3 > img {
  border-radius: 0.725rem;
}
.avatar-status.rounded-2 > img {
  border-radius: 0.475rem;
}
.avatar-status.rounded-1 > img {
  border-radius: 0.325rem;
}

.avatar-group {
  display: flex;
  align-items: stretch;
}
.avatar-group .avatar-group-item > img {
  border-radius: 100%;
}
.avatar-group .avatar-group-item:not(:first-child) {
  position: relative;
  box-shadow: 0px 2px 4px rgba(17, 15, 26, 0.125);
}
.avatar-group .avatar-group-item.avatar.xs:not(:first-child) {
  margin-left: -0.5rem;
}
.avatar-group .avatar-group-item.avatar.sm:not(:first-child) {
  margin-left: -0.75rem;
}
.avatar-group .avatar-group-item.avatar:not(:first-child):not(.xs):not(.sm):not(.lg):not(.xl) {
  margin-left: -1rem;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  font-family: boxicons !important;
  font-size: 10px;
  font-weight: normal;
  color: currentColor;
  opacity: 0.35;
  display: inline-block;
  vertical-align: middle;
  float: none;
}
.breadcrumb .breadcrumb-item.active {
  color: rgba(26, 23, 38, 0.6);
}

.text-white .breadcrumb-item, .text-light .breadcrumb-item {
  color: #fff;
}
.text-white .breadcrumb-item.active, .text-light .breadcrumb-item.active {
  color: rgba(255, 255, 255, 0.5);
}
.text-white .breadcrumb-item + .breadcrumb-item::before, .text-light .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.7);
}

.showMore-link {
  display: inline-block;
}
.showMore-link[aria-expanded=false]::after {
  content: "show more...";
}
.showMore-link[aria-expanded=true]::after {
  content: "show less...";
}

.collapse .search-collapse {
  background-color: white;
}

.collapse-link {
  position: relative;
  display: inline-block;
}
.collapse-link::before {
  content: "+";
  width: 0.5rem;
  font-weight: 600;
  text-align: center;
  display: inline-block;
}
.collapse-link[aria-expanded=true]::before {
  content: "-";
}

.collapse-group .nav-link[data-bs-toggle=collapse],
.collapse-group [data-bs-toggle=collapse] {
  position: relative;
  display: block;
  color: #1a1726;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.collapse-group .nav-link[data-bs-toggle=collapse]::after,
.collapse-group [data-bs-toggle=collapse]::after {
  border: 0px;
  margin-top: 0px;
  content: "\ed35";
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 0.75rem;
  height: 0.75rem;
  transform: translateY(-50%) rotate(180deg);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: boxicons !important;
  font-style: normal;
  font-weight: 400 !important;
  box-sizing: content-box;
  font-size: 0.875rem;
  transition: all 0.2s;
  will-change: transform;
}
.collapse-group .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.collapse-group [data-bs-toggle=collapse][aria-expanded=true] {
  color: #110f1a;
}
.collapse-group .nav-link[data-bs-toggle=collapse][aria-expanded=true]::after,
.collapse-group [data-bs-toggle=collapse][aria-expanded=true]::after {
  transform: translateY(-50%) rotate(0deg);
}
.collapse-group .nav.nav-level-2.flex-column {
  padding-left: 0.5rem;
}
.collapse-group .nav.nav-level-3.flex-column {
  padding-left: 1rem;
}
.collapse-group .nav.nav-level-2.flex-column > .nav-link,
.collapse-group .nav.nav-level-3.flex-column > .nav-link {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.text-white .collapse-group .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.text-white .collapse-group [data-bs-toggle=collapse][aria-expanded=true],
.bg-dark .collapse-group .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.bg-dark .collapse-group [data-bs-toggle=collapse][aria-expanded=true],
.text-light .collapse-group .nav-link[data-bs-toggle=collapse][aria-expanded=true],
.text-light .collapse-group [data-bs-toggle=collapse][aria-expanded=true] {
  color: rgba(255, 255, 255, 0.65);
}

.hover-lift, .hover-lift-lg {
  will-change: transform;
  transition: all 0.3s ease-in-out;
}

.hover-lift:hover {
  transform: translate(0, -5px);
}

.hover-lift-lg:hover {
  transform: translate(0, -20px);
}

.img-zoom {
  transform: scale(1);
  transform-origin: center;
  transition: transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
}

.card-over, .card-hover {
  position: relative;
  z-index: 1;
  will-change: transform;
}
.card-over:not(.row):not([class~=col-]):not(.d-block):not(.card), .card-hover:not(.row):not([class~=col-]):not(.d-block):not(.card) {
  display: block;
  color: currentColor;
}

.card-overlay {
  will-change: transform;
  transition: opacity 1.1s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.1s cubic-bezier(0.5, 0, 0, 1) 0.3s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.card-overlay::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  z-index: -1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  background: linear-gradient(0deg, rgba(17, 15, 26, 0.8) 0, rgba(17, 15, 26, 0.35) 25%, rgba(17, 15, 26, 0) 50%);
  transition: background-color, opacity 1.2s ease;
}
.card-overlay.overlay-center {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-overlay.overlay-center::before {
  background: linear-gradient(-180deg, rgba(249, 71, 112, 0.75) 0, rgba(249, 71, 112, 0.8) 0, rgba(17, 15, 26, 0.875) 110%);
  transition: background-color, opacity 1.2s ease;
}
.card-overlay .overlay-items {
  position: relative;
  margin: 0;
  overflow: hidden;
  font-weight: 400;
  color: currentColor;
}
.card-overlay .overlay-items li {
  transition: opacity 0.25s ease 0.2s, visibility 0.25s ease 0.2s, transform 0.25s ease 0.2s;
  display: block;
  position: relative;
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.card-hover .card-overlay::before,
.card-split-hover .card-overlay::before {
  opacity: 0;
}
.card-hover .card-overlay .overlay-items,
.card-split-hover .card-overlay .overlay-items {
  position: relative;
}
.card-hover .card-overlay .overlay-items li,
.card-split-hover .card-overlay .overlay-items li {
  opacity: 0;
  transform: translate3d(0, 30px, 0);
}
.card-hover:hover .card-overlay::before,
.card-split-hover:hover .card-overlay::before {
  opacity: 1;
}
.card-hover:hover .overlay-items li,
.card-split-hover:hover .overlay-items li {
  opacity: 1;
  transform: translateZ(0);
  transition-duration: 0.6s;
}
.card-hover:hover .overlay-items li:nth-child(1),
.card-split-hover:hover .overlay-items li:nth-child(1) {
  transition-delay: 100ms;
}
.card-hover:hover .overlay-items li:nth-child(2),
.card-split-hover:hover .overlay-items li:nth-child(2) {
  transition-delay: 200ms;
}
.card-hover:hover .overlay-items li:nth-child(3),
.card-split-hover:hover .overlay-items li:nth-child(3) {
  transition-delay: 300ms;
}
.card-hover:hover .overlay-items li:nth-child(4),
.card-split-hover:hover .overlay-items li:nth-child(4) {
  transition-delay: 400ms;
}
.card-hover:hover .overlay-items li:nth-child(5),
.card-split-hover:hover .overlay-items li:nth-child(5) {
  transition-delay: 500ms;
}
.card-hover:hover .overlay-items li:nth-child(6),
.card-split-hover:hover .overlay-items li:nth-child(6) {
  transition-delay: 600ms;
}
.card-hover:hover .overlay-items li:nth-child(7),
.card-split-hover:hover .overlay-items li:nth-child(7) {
  transition-delay: 700ms;
}
.card-hover:hover .overlay-items li:nth-child(8),
.card-split-hover:hover .overlay-items li:nth-child(8) {
  transition-delay: 800ms;
}
.card-hover:hover .overlay-items li:nth-child(9),
.card-split-hover:hover .overlay-items li:nth-child(9) {
  transition-delay: 900ms;
}
.card-hover:hover .overlay-items li:nth-child(10),
.card-split-hover:hover .overlay-items li:nth-child(10) {
  transition-delay: 1000ms;
}
.card-hover:hover .overlay-items li:nth-child(11),
.card-split-hover:hover .overlay-items li:nth-child(11) {
  transition-delay: 1100ms;
}
.card-hover:hover .overlay-items li:nth-child(12),
.card-split-hover:hover .overlay-items li:nth-child(12) {
  transition-delay: 1200ms;
}
.card-hover:hover .overlay-items li:nth-child(13),
.card-split-hover:hover .overlay-items li:nth-child(13) {
  transition-delay: 1300ms;
}
.card-hover:hover .overlay-items li:nth-child(14),
.card-split-hover:hover .overlay-items li:nth-child(14) {
  transition-delay: 1400ms;
}
.card-hover:hover .overlay-items li:nth-child(15),
.card-split-hover:hover .overlay-items li:nth-child(15) {
  transition-delay: 1500ms;
}
.card-hover:hover .overlay-items li:nth-child(16),
.card-split-hover:hover .overlay-items li:nth-child(16) {
  transition-delay: 1600ms;
}
.card-hover:hover .overlay-items li:nth-child(17),
.card-split-hover:hover .overlay-items li:nth-child(17) {
  transition-delay: 1700ms;
}
.card-hover:hover .overlay-items li:nth-child(18),
.card-split-hover:hover .overlay-items li:nth-child(18) {
  transition-delay: 1800ms;
}
.card-hover:hover .overlay-items li:nth-child(19),
.card-split-hover:hover .overlay-items li:nth-child(19) {
  transition-delay: 1900ms;
}
.card-hover:hover .overlay-items li:nth-child(20),
.card-split-hover:hover .overlay-items li:nth-child(20) {
  transition-delay: 2000ms;
}
.card-hover:hover .overlay-items li:nth-child(21),
.card-split-hover:hover .overlay-items li:nth-child(21) {
  transition-delay: 2100ms;
}

.card-hover,
.card-over,
.card-hover-2 {
  will-change: transform;
}
.card-hover:hover .img-zoom,
.card-over:hover .img-zoom,
.card-hover-2:hover .img-zoom {
  transform: scale(1.1);
}

.card-split-hover {
  will-change: transform;
}
.card-split-hover .card-overlay {
  opacity: 0;
  transition: all 0.15s;
}
.card-split-hover:hover .card-overlay {
  opacity: 1;
}

.hover-pulse {
  animation: pulse 1.5s infinite forwards;
}

.infinite {
  animation-iteration-count: infinite;
}

.card-hover:hover .link-multiline,
.card-hover-2:hover .link-multiline {
  background-size: 100% 1px;
}

.card-hover-2 {
  background-color: #110f1a;
}

.card-hover-2-overlay {
  transition: all 0.25s ease-out;
}

.card-hover-2-header p {
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
}

.card-hover-2-footer {
  position: relative;
}
.card-hover-2-footer .card-hover-2-footer-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.card-hover-2-footer .tags {
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
}

.card-hover-2-footer-link {
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}
.card-hover-2-footer-link span {
  display: block;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
}
.card-hover-2-footer-link::after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background-size: 24px 24px;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 24 24'%3E%3Cpath d='M22 12l-4-4v3H3v2h15v3z'/%3E%3C/svg%3E");
}

.card-hover-2 img {
  transition: opacity 0.25s, transform 0.4s;
}
.card-hover-2:hover img {
  opacity: 0.5;
}
.card-hover-2:hover .card-hover-2-overlay {
  transition: all 0.6s ease-in;
}
.card-hover-2:hover .card-hover-2-header p {
  opacity: 1;
  transform: translateY(0%);
}
.card-hover-2:hover .card-hover-2-footer .tags {
  opacity: 0;
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.card-hover-2:hover .card-hover-2-footer .card-hover-2-footer-link span {
  opacity: 1;
  transform: translateY(0);
}
.card-hover-2:hover .card-hover-2-footer .card-hover-2-footer-link::after {
  opacity: 1;
  transform: translateX(0);
}

.card-reveal-effect {
  visibility: hidden;
  overflow: hidden;
  position: relative;
  transition-property: transform;
  will-change: transform;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.card-reveal-effect a {
  display: block;
}
.card-reveal-effect > * {
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;
  animation-fill-mode: forwards;
}
.card-reveal-effect::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left top;
  transform: scale(0.05, 0);
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  background-color: currentColor;
}

.card-reveal-effect.aos-animate, .is-inview .card-reveal-effect, .aos-animate .card-reveal-effect {
  transform: translate(0, 0);
  visibility: visible;
}
.card-reveal-effect.aos-animate > *, .is-inview .card-reveal-effect > *, .aos-animate .card-reveal-effect > * {
  animation-name: show-img-1;
}
.card-reveal-effect.aos-animate:after, .is-inview .card-reveal-effect:after, .aos-animate .card-reveal-effect:after {
  animation-name: slide-bg-2;
}

.link-hover-underline > i, .link-underline > i, .link-both-underline > i, .link-hover-no-underline > i {
  display: inline-block;
  transition: all 0.25s;
}
.link-hover-underline:hover > i, .link-underline:hover > i, .link-both-underline:hover > i, .link-hover-no-underline:hover > i {
  transform: rotate(-45deg);
}

.bg-pattern {
  background-image: radial-gradient(circle, currentColor 1px, rgba(0, 0, 0, 0) 1px);
  background-size: 1rem 1rem;
}

img.bg-image {
  object-fit: cover;
}
img.bg-image.bg-top-center {
  object-position: top center;
}
img.bg-image.bg-center {
  object-position: center;
}

.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.bg-image:not([class*=position-absolute]):not([class*=position-relative]) {
  position: absolute;
}
.bg-image + * {
  position: relative;
  z-index: 1;
}

.bg-gradient-primary {
  background-image: linear-gradient(155deg, #110f1a 20%, #6f42c1 40%, #f94770 95%, #f94770);
}

.bg-gradient-secondary {
  background-image: radial-gradient(200% 160% at 75% 130%, #110f1a 0%, #352f4a 100%);
}

.bg-gradient-light {
  background-image: radial-gradient(200% 160% at 75% 130%, #fff 0%, #f9f8fc 100%);
}

.bg-gradient-white {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#fff));
  mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#fff));
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#fff));
}

.bg-gradient-dark {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 15, 26, 0)), to(#110f1a));
  mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 15, 26, 0)), to(#110f1a));
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 15, 26, 0)), to(#110f1a));
}

.bg-gradient-tint {
  background-image: radial-gradient(200% 160% at 75% 130%, #fff4f6, #f5f5f6);
}

.bg-gradient-blur {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.01), white 85%), radial-gradient(ellipse at top left, rgba(17, 15, 26, 0.3), transparent 50%), radial-gradient(ellipse at top right, rgba(249, 71, 112, 0.25), transparent 50%), radial-gradient(ellipse at center right, rgba(111, 66, 193, 0.25), transparent 50%), radial-gradient(ellipse at center left, rgba(249, 71, 112, 0.25), transparent 50%);
}

.bg-current {
  background-color: currentColor;
}

.bg-shade-primary {
  background-color: #09080d !important;
}

.bg-shade-secondary {
  background-color: #1b1825 !important;
}

.bg-shade-success {
  background-color: #1b5329 !important;
}

.bg-shade-info {
  background-color: #006180 !important;
}

.bg-shade-warning {
  background-color: #806c29 !important;
}

.bg-shade-danger {
  background-color: #7d2438 !important;
}

.bg-shade-light {
  background-color: #7d7c7e !important;
}

.bg-shade-white {
  background-color: gray !important;
}

.bg-shade-dark {
  background-color: #09080d !important;
}

.bg-shade-gray-200 {
  background-color: #757478 !important;
}

.bg-shade-gray-800 {
  background-color: #13111b !important;
}

.bg-overlay-gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.bg-overlay-gradient::before, .bg-overlay-gradient::after {
  content: "";
  position: absolute;
  z-index: -1;
}
.bg-overlay-gradient::after {
  content: "";
  top: 0;
  background: radial-gradient(50% 50% at 50% 50%, #e7e7e8 0, rgba(204, 244, 100, 0) 78.74%);
  display: block;
  position: absolute;
  width: 118vh;
  height: 118vh;
  right: 20vh;
  transform: translate(50%, -50%);
}
.bg-overlay-gradient::before {
  background: radial-gradient(50% 50% at 50% 50%, #feedf1 0, rgba(248, 205, 209, 0) 78.74%);
  position: absolute;
  display: block;
  content: "";
  top: -60%;
  left: -90%;
  width: 200%;
  padding-bottom: 200%;
}

.badge {
  line-height: 1.3;
}
.badge:not(.bg-light):not(.bg-white):not(.bg-gradient-light) {
  color: #fff;
}
.badge.badge-pill {
  border-radius: 50rem;
}

.btn {
  text-transform: capitalize;
  letter-spacing: -0.011rem;
  font-weight: 500;
  --bs-btn-focus-box-shadow: 0 6px 30px -3px rgba(var(--bs-btn-focus-shadow-rgb), .325);
}
.btn:hover {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-rise {
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
  box-shadow: none;
  z-index: 0;
}
.btn-rise.p-0 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.btn-rise .btn-rise-bg {
  z-index: -1;
  display: block;
  width: 125%;
  height: 100%;
  position: absolute;
  background-color: currentColor;
  top: 0;
  left: 50%;
  pointer-events: none;
  transform: translate(-50%, 105%);
  border-radius: 50%;
  transition: transform 0.65s cubic-bezier(0.77, 0, 0.175, 1), border-radius 0.65s cubic-bezier(0.77, 0, 0.175, 1);
}
.btn-rise .btn-rise-text {
  position: relative;
  transition: color 0.4s;
}
.btn-rise[class^=btn-outline-]:hover, .btn-rise[class^=btn-outline-]:focus, .btn-rise[class^=btn-outline-]:focus, .btn-rise[class^=btn-outline-]:active, .btn-rise[class^=btn-outline-].active, .btn-rise[class*=btn-outline-]:hover, .btn-rise[class*=btn-outline-]:focus, .btn-rise[class*=btn-outline-]:focus, .btn-rise[class*=btn-outline-]:active, .btn-rise[class*=btn-outline-].active {
  background-color: transparent;
}
.btn-rise:hover:not(:disabled):not(.disabled) .btn-rise-bg, .btn-rise:focus:not(:disabled):not(.disabled) .btn-rise-bg, .btn-rise.active:not(:disabled):not(.disabled) .btn-rise-bg, .btn-rise.focus:not(:disabled):not(.disabled) .btn-rise-bg {
  transform: translate(-50%, 0);
  border-radius: 0;
}

.btn-hover-arrow {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-hover-arrow span {
  display: inline-block;
  transform: translate3d(12px, 0, 0);
  transition: transform 0.3s cubic-bezier(0.42, 0.01, 0.23, 1);
}
.btn-hover-arrow::after {
  vertical-align: middle;
  content: "\ebe6";
  font-family: boxicons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  line-height: 24px;
  font-size: 1.25rem;
  width: 24px;
  height: 24px;
  display: inline-block;
  opacity: 0;
  transform: translate3d(12px, 0, 0);
  transition: opacity 0.3s, transform 0.4s cubic-bezier(0.42, 0.01, 0.23, 1);
}
.btn-hover-arrow:hover span {
  transform: translate3d(0, 0, 0);
}
.btn-hover-arrow:hover::after {
  transform: translate3d(6px, 0, 0);
  opacity: 1;
}
.btn-hover-arrow.btn-lg::after, .btn-group-lg > .btn-hover-arrow.btn::after {
  font-size: 1.375rem;
}
.btn-hover-arrow.btn-lg:hover span, .btn-group-lg > .btn-hover-arrow.btn:hover span {
  transform: translate3d(-6px, 0, 0);
}
.btn-hover-arrow.btn-sm::after, .btn-group-sm > .btn-hover-arrow.btn::after {
  font-size: 1.125rem;
}
.btn-hover-arrow.btn-sm:hover span, .btn-group-sm > .btn-hover-arrow.btn:hover span {
  transform: translateZ(0px);
}

.btn-circle-ripple {
  position: relative;
}
.btn-circle-ripple::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 1px currentColor;
  animation: pulse 2s infinite ease-in-out;
}

.btn-circle-ripple.btn-primary::before,
.btn-circle-ripple.btn-outline-primary::before {
  box-shadow: 0 0 0 1px #110f1a !important;
}

.btn-circle-ripple.btn-secondary::before,
.btn-circle-ripple.btn-outline-secondary::before {
  box-shadow: 0 0 0 1px #352f4a !important;
}

.btn-circle-ripple.btn-success::before,
.btn-circle-ripple.btn-outline-success::before {
  box-shadow: 0 0 0 1px #35a552 !important;
}

.btn-circle-ripple.btn-info::before,
.btn-circle-ripple.btn-outline-info::before {
  box-shadow: 0 0 0 1px #00c2ff !important;
}

.btn-circle-ripple.btn-warning::before,
.btn-circle-ripple.btn-outline-warning::before {
  box-shadow: 0 0 0 1px #ffd751 !important;
}

.btn-circle-ripple.btn-danger::before,
.btn-circle-ripple.btn-outline-danger::before {
  box-shadow: 0 0 0 1px #f94770 !important;
}

.btn-circle-ripple.btn-light::before,
.btn-circle-ripple.btn-outline-light::before {
  box-shadow: 0 0 0 1px #f9f8fc !important;
}

.btn-circle-ripple.btn-white::before,
.btn-circle-ripple.btn-outline-white::before {
  box-shadow: 0 0 0 1px #fff !important;
}

.btn-circle-ripple.btn-dark::before,
.btn-circle-ripple.btn-outline-dark::before {
  box-shadow: 0 0 0 1px #110f1a !important;
}

.btn-circle-ripple.btn-gray-200::before,
.btn-circle-ripple.btn-outline-gray-200::before {
  box-shadow: 0 0 0 1px #eae8f0 !important;
}

.btn-circle-ripple.btn-gray-800::before,
.btn-circle-ripple.btn-outline-gray-800::before {
  box-shadow: 0 0 0 1px #262136 !important;
}

.btn-hover-text {
  position: relative;
}
.btn-hover-text .btn-hover-label.label-default {
  display: block;
  transition: transform 0.5s cubic-bezier(0.5, 0, 0, 1), opacity 0.5s cubic-bezier(0.5, 0, 0, 1);
  transition-delay: 0.1s;
}
.btn-hover-text .btn-hover-label.label-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(1vw);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.5, 0, 0, 1), opacity 0.5s cubic-bezier(0.5, 0, 0, 1);
  transition-delay: 0s;
}
.btn-hover-text:hover .btn-hover-label.label-default {
  transform: translateY(-1vw);
  opacity: 0;
  transition-delay: 0s;
}
.btn-hover-text:hover .btn-hover-label.label-hover {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.1s;
}

.modal-header .btn-close {
  padding: 0;
  right: 0;
  top: 0;
  margin: 0;
}

.btn-close {
  width: 32px;
  height: 32px;
  background-image: none;
  opacity: 1;
}

.nav {
  --bs-nav-link-color: #1a1726;
  --bs-nav-link-hover-color: #110f1a;
  --bs-nav-link-disabled-color: #8a8793;
}
.nav.flex-column .nav-link {
  padding-left: 0;
}

.text-white, .text-light {
  --bs-body-color:#fff;
  --bs-body-color-rgb: 255,255,255;
}
.text-white .nav, .text-light .nav {
  --bs-text-opacity: .75;
  --bs-nav-link-color: rgba(var(--bs-body-color-rgb),var(--bs-text-opacity));
  --bs-nav-link-hover-color: #fff;
  --bs-nav-link-active-color: #fff;
  --bs-nav-link-disabled-color: #8a8793;
}
.text-white .nav .nav-link.active, .text-light .nav .nav-link.active {
  color: var(--bs-nav-link-active-color);
}

.nav.nav-filter .nav-link {
  position: relative;
  padding: 0;
}
.nav.nav-filter .nav-link.active, .nav.nav-filter .nav-link:hover {
  color: #8a8793;
}
.nav.nav-filter .nav-link.active {
  text-decoration: line-through;
}
.nav.nav-filter:not(.flex-column) .nav-item:not(:last-child) {
  margin-right: 1rem;
}

.nav-overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px;
}
.nav-overflow::-webkit-scrollbar {
  display: none;
}

.nav-tabs-vertical {
  flex-direction: column;
  flex-shrink: 0;
  flex: 1;
}

.nav.nav-pills.nav-pills-white .nav-link.active {
  background-color: #fff;
  color: #110f1a;
}

.nav-tabs {
  border-bottom: 2px solid #eae8f0;
}
.nav-tabs .nav-link {
  padding-left: 0px;
  padding-right: 0px;
}
.nav-tabs .nav-link:not(:first-child) {
  margin-left: 1.75rem;
}
.nav-tabs .nav-link {
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  background-color: transparent;
}
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-bottom-color: #110f1a;
}

.navbar .navbar-nav .nav-link {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1rem;
  font-family: var(--bs-font-sans-serif);
  letter-spacing: -0.011rem;
}
.navbar:not(.position-fixed):not(.position-absolute):not(.fixed-top) {
  position: relative;
}
.navbar .dropdown-menu {
  z-index: 1022;
}

.navbar-no-collapse-items .btn {
  white-space: nowrap;
}
.navbar-no-collapse-items .nav-link {
  padding: 0.5rem 0;
  color: rgba(17, 15, 26, 0.55);
}
.navbar-no-collapse-items .nav-link:hover {
  color: #110f1a;
}

.navbar-link-white .navbar-no-collapse-items .nav-link,
.navbar-dark .navbar-no-collapse-items .nav-link {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-link-white .navbar-no-collapse-items .nav-link:hover, .navbar-link-white .navbar-no-collapse-items .nav-link:focus,
.navbar-dark .navbar-no-collapse-items .nav-link:hover,
.navbar-dark .navbar-no-collapse-items .nav-link:focus {
  color: white;
}
.navbar-link-white .navbar-no-collapse-items .nav-link.active, .navbar-link-white .navbar-no-collapse-items .nav-link.show,
.navbar-dark .navbar-no-collapse-items .nav-link.active,
.navbar-dark .navbar-no-collapse-items .nav-link.show {
  color: #fff;
}

.scroll-up .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link,
.is-sticky .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link {
  color: rgba(17, 15, 26, 0.55);
}
.scroll-up .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link:hover, .scroll-up .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link:focus,
.is-sticky .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link:hover,
.is-sticky .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link:focus {
  color: #110f1a;
}
.scroll-up .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link.active, .scroll-up .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link.show,
.is-sticky .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link.active,
.is-sticky .navbar-light.navbar-link-white .navbar-no-collapse-items .nav-link.show {
  color: #110f1a;
}

.header-transparent .navbar {
  background-color: transparent;
  border-bottom: 0px;
}

.header-absolute-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1030;
}

.header-fixed-top {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1030;
}

.navbar-brand {
  width: 5rem;
  display: block;
  padding: 0;
  position: relative;
}
.navbar-brand .navbar-brand-transparent,
.navbar-brand .navbar-brand-sticky {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

header.header-transparent.sticky-fixed .navbar {
  padding: 0.75rem 0;
  transition: transform 0.5s cubic-bezier(0.55, 0.04, 0.43, 1);
}
header.header-transparent.sticky-fixed .navbar .navbar-fixed-bg {
  transform: translateY(-100%);
  transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  box-shadow: 0 1rem 3rem rgba(17, 15, 26, 0.075);
  backdrop-filter: blur(10px);
}
header.header-transparent.sticky-fixed.is-fixed .navbar {
  transform: none;
}
header.header-transparent.sticky-fixed.is-fixed .navbar .navbar-fixed-bg {
  transform: translateY(0);
  transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
header.header-transparent.sticky-fixed.is-fixed .navbar-light .navbar-fixed-bg {
  background-color: rgba(255, 255, 255, 0.985);
}
header.header-transparent.sticky-fixed.is-fixed .navbar-light.navbar-link-white .navbar-brand > .navbar-brand-transparent {
  opacity: 0;
  visibility: hidden;
}
header.header-transparent.sticky-fixed.is-fixed .navbar-light.navbar-link-white .navbar-brand > .navbar-brand-sticky {
  opacity: 1;
  visibility: visible;
}
header.header-transparent.sticky-fixed.is-fixed .navbar-light .navbar-toggler .navbar-toggler-icon > i {
  color: rgba(17, 15, 26, 0.55);
}
header.header-transparent.sticky-fixed.is-fixed .navbar-dark .navbar-fixed-bg {
  background-color: rgba(17, 15, 26, 0.985);
}

@media (min-width: 992px) {
  .header-absolute-top .navbar {
    max-width: calc(100vw - 17px);
  }
  .header-center-logo .navbar-brand {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .navbar.navbar-brand-center .navbar-brand {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  header.header-sticky.is-sticky .navbar.navbar-expand-lg {
    border-radius: 0px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1030;
    width: 100%;
    transition: all 0.3s;
    animation: fadeDown 0.4s both;
    border-width: 1px;
  }
  header.header-sticky.is-sticky .navbar.navbar-expand-lg.navbar-light {
    animation: fadeDown 0.4s both;
    border-bottom-color: #eae8f0;
    box-shadow: 0 16px 32px 0 rgba(17, 15, 26, 0.04);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.85);
  }
  header.header-sticky.is-sticky .navbar.navbar-expand-lg.navbar-dark {
    backdrop-filter: blur(20px);
    background-color: rgba(17, 15, 26, 0.75);
    animation: fadeDown 0.4s both;
    box-shadow: 0 16px 32px 0 rgba(17, 15, 26, 0.0975);
  }
  header.header-boxed.is-sticky .navbar-boxed, header.header-boxed.scroll-up .navbar-boxed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1030;
    padding: 0.5rem 0;
  }
  header.header-boxed.is-sticky .navbar-boxed .navbar.navbar-expand-lg, header.header-boxed.scroll-up .navbar-boxed .navbar.navbar-expand-lg {
    position: relative;
    padding: 0 1rem;
    border-radius: 0.725rem;
  }
  header:not(.scroll-up):not(.is-sticky):not(.is-fixed) .navbar-light.navbar-link-white .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.75);
  }
  header:not(.scroll-up):not(.is-sticky):not(.is-fixed) .navbar-light.navbar-link-white .navbar-nav .nav-link:hover, header:not(.scroll-up):not(.is-sticky):not(.is-fixed) .navbar-light.navbar-link-white .navbar-nav .nav-link:focus {
    color: white;
  }
  header:not(.scroll-up):not(.is-sticky):not(.is-fixed) .navbar-light.navbar-link-white .navbar-nav .nav-link.active {
    color: #fff;
  }
  .navbar-expand-lg .navbar-brand {
    width: 6.5rem;
  }
  .navbar-expand-lg.navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .nav-item > .nav-link,
.navbar-expand-lg .navbar-no-collapse-items .nav-item {
    height: 4.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-expand-lg .navbar-no-collapse-items .nav-item .nav-link {
    height: auto;
  }
  .navbar-expand-lg .nav-item:not(:first-child) {
    margin-left: 0.65rem;
  }
  header .navbar-link-white .navbar-brand .navbar-brand-sticky {
    opacity: 0;
    visibility: hidden;
  }
  header.scroll-up .navbar-link-white .navbar-brand .navbar-brand-sticky, header.is-sticky .navbar-link-white .navbar-brand .navbar-brand-sticky {
    opacity: 1;
    visibility: visible;
  }
  header.scroll-up .navbar-link-white .navbar-brand .navbar-brand-transparent, header.is-sticky .navbar-link-white .navbar-brand .navbar-brand-transparent {
    opacity: 0;
    visibility: hidden;
  }
  header.header-transparent.sticky-fixed .navbar {
    padding: 20px 0;
  }
  header.header-transparent.sticky-fixed .navbar .navbar-fixed-bg {
    height: calc(100% - 20px);
  }
  header.header-transparent.sticky-fixed.is-fixed .navbar {
    transform: translateY(-20px);
  }
  .sticky-reverse .navbar-expand-lg {
    transition: all 0.4s;
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1030;
    padding: 1rem 0;
  }
  .sticky-reverse.scroll-down .navbar-expand-lg {
    transform: translateY(-100%);
  }
  .sticky-reverse.scroll-up .navbar-expand-lg, .sticky-reverse.scroll-down .navbar-expand-lg {
    padding: 0 0;
  }
  .sticky-reverse.scroll-up .navbar-light, .sticky-reverse.scroll-down .navbar-light {
    background-color: rgba(255, 255, 255, 0.985);
  }
  .sticky-reverse.scroll-up .navbar-dark, .sticky-reverse.scroll-down .navbar-dark {
    background-color: rgba(17, 15, 26, 0.985);
  }
  .sticky-reverse.scroll-up .navbar-light {
    box-shadow: 0px 11px 24px rgba(17, 15, 26, 0.04);
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 0.25rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu::before {
    content: "";
    left: -0.275rem;
    top: -0.275rem;
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    display: block;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropend .dropdown-menu {
    margin-left: 0.725rem;
    margin-top: 0;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropend .dropdown-menu::before {
    top: 0;
    left: -0.785rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropstart .dropdown-menu {
    margin-right: 0.725rem;
    margin-top: 0;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropstart .dropdown-menu::before {
    top: 0;
    left: auto;
    right: -0.785rem;
  }
}
.navbar-dark .navbar-toggler,
.navbar-link-white .navbar-toggler {
  color: #fff;
}

.navbar-toggler {
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
}
.navbar-toggler:focus {
  outline: 0;
  box-shadow: none;
}
.navbar-toggler .navbar-toggler-icon {
  background-image: none;
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
  transition: all 0.5s ease;
  vertical-align: middle;
  font-size: 0;
  cursor: pointer;
}
.navbar-toggler .navbar-toggler-icon > i {
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  background-color: currentColor;
  transition: background-color 0s 0.3s;
}
.navbar-toggler .navbar-toggler-icon > i::before, .navbar-toggler .navbar-toggler-icon > i::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  display: block;
  left: 0;
  background-color: currentColor;
}
.navbar-toggler .navbar-toggler-icon > i::before {
  top: 8px;
  transition: top 0.3s 0.3s, transform 0.3s 0s, background-color 0.3s 0s;
}
.navbar-toggler .navbar-toggler-icon > i::after {
  bottom: 8px;
  transition: bottom 0.3s 0.3s, transform 0.3s 0s, background-color 0.3s 0s;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon > i {
  background-color: transparent;
  transition: background-color 0s 0.2s;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon > i:before {
  top: 0;
  transform: rotate(45deg);
  transition: top 0.2s 0s, transform 0.3s 0.2s, background-color 0.2s 0s;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon > i::after {
  bottom: 0;
  transition: bottom 0.2s 0s, transform 0.2s 0.2s, background-color 0.2s 0s;
  transform: rotate(-45deg);
}

@media (max-width: 991.98px) {
  header.sticky-reverse .navbar-link-white .navbar-brand .navbar-brand-sticky {
    opacity: 0;
    visibility: hidden;
  }
  .navbar-expand-lg.navbar .navbar-collapse {
    max-height: 80vh;
    overflow-y: auto;
    margin-top: 0.5rem;
    border-bottom-left-radius: 0.725rem;
    border-bottom-right-radius: 0.725rem;
  }
  .navbar-expand-lg.navbar .navbar-collapse > .navbar-nav {
    padding: 1rem;
  }
  .navbar-expand-lg.navbar.navbar-light .navbar-collapse {
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(17, 15, 26, 0.0875);
  }
  .navbar-expand-lg.navbar.navbar-dark .navbar-collapse {
    background-color: #110f1a;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm.navbar-search-w-icons .navbar-collapse {
    flex-grow: 0;
    flex-basis: 0;
  }
  .navbar-expand-sm.navbar-search-w-icons .collapse-search {
    --navbar-search-width:440px;
    min-width: var(--navbar-search-width);
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm .collapse-search {
    width: 100%;
    flex-basis: 100%;
  }
  .navbar-expand-sm.navbar-search-w-icons .search-link[aria-expanded=true] .bx::before {
    content: "\ec8d";
  }
}
@media (min-width: 768px) {
  .navbar-expand-md.navbar-search-w-icons .navbar-collapse {
    flex-grow: 0;
    flex-basis: 0;
  }
  .navbar-expand-md.navbar-search-w-icons .collapse-search {
    --navbar-search-width:440px;
    min-width: var(--navbar-search-width);
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md .collapse-search {
    width: 100%;
    flex-basis: 100%;
  }
  .navbar-expand-md.navbar-search-w-icons .search-link[aria-expanded=true] .bx::before {
    content: "\ec8d";
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg.navbar-search-w-icons .navbar-collapse {
    flex-grow: 0;
    flex-basis: 0;
  }
  .navbar-expand-lg.navbar-search-w-icons .collapse-search {
    --navbar-search-width:440px;
    min-width: var(--navbar-search-width);
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .collapse-search {
    width: 100%;
    flex-basis: 100%;
  }
  .navbar-expand-lg.navbar-search-w-icons .search-link[aria-expanded=true] .bx::before {
    content: "\ec8d";
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl.navbar-search-w-icons .navbar-collapse {
    flex-grow: 0;
    flex-basis: 0;
  }
  .navbar-expand-xl.navbar-search-w-icons .collapse-search {
    --navbar-search-width:440px;
    min-width: var(--navbar-search-width);
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .collapse-search {
    width: 100%;
    flex-basis: 100%;
  }
  .navbar-expand-xl.navbar-search-w-icons .search-link[aria-expanded=true] .bx::before {
    content: "\ec8d";
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl.navbar-search-w-icons .navbar-collapse {
    flex-grow: 0;
    flex-basis: 0;
  }
  .navbar-expand-xxl.navbar-search-w-icons .collapse-search {
    --navbar-search-width:440px;
    min-width: var(--navbar-search-width);
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl .collapse-search {
    width: 100%;
    flex-basis: 100%;
  }
  .navbar-expand-xxl.navbar-search-w-icons .search-link[aria-expanded=true] .bx::before {
    content: "\ec8d";
  }
}
.navbar-expand.navbar-search-w-icons .navbar-collapse {
  flex-grow: 0;
  flex-basis: 0;
}
.navbar-expand.navbar-search-w-icons .collapse-search {
  --navbar-search-width:440px;
  min-width: var(--navbar-search-width);
}
.navbar-expand .collapse-search {
  width: 100%;
  flex-basis: 100%;
}
.navbar-expand.navbar-search-w-icons .search-link[aria-expanded=true] .bx::before {
  content: "\ec8d";
}

.dropdown-menu {
  box-shadow: 0 100px 80px rgba(17, 15, 26, 0.05), 0 42px 34px rgba(17, 15, 26, 0.04), 0 22px 18px rgba(17, 15, 26, 0.03), 0 13px 11px rgba(17, 15, 26, 0.03), 0 6.65px 5.325px rgba(17, 15, 26, 0.02), 0 3px 2px rgba(17, 15, 26, 0.01);
}
.dropdown-menu .dropdown-divider {
  height: 1px;
}

.dropdown-item {
  font-family: var(--bs-font-sans-serif);
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  letter-spacing: 0rem;
  font-size: 1rem;
  font-weight: 400;
  --bs-dropdown-border-radius: 0.475rem;
  border-radius: var(--bs-dropdown-border-radius);
}

.text-white .dropdown-divider,
.bg-dark .dropdown-divider,
.dropdown-menu-dark .dropdown-divider {
  opacity: 0.2;
}

.dropdown-menu .dropdown-header {
  text-transform: capitalize;
}

.navbar-no-collapse-items .dropdown-menu.dropdown-menu-xs {
  min-width: 300px;
  width: 300px;
}

@media (min-width: 992px) {
  .navbar .dropdown-menu-end {
    right: 0;
    left: auto;
  }
  .dropdown-scroll-lg {
    overflow-y: auto;
    max-height: calc(100vh - 5rem - 1.25rem);
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .dropdown-scroll-lg::-webkit-scrollbar {
    display: none;
  }
  .navbar .dropdown-menu {
    top: 100%;
    display: block;
    visibility: hidden;
    transform: translateY(16px);
    opacity: 0;
  }
  .navbar .dropdown:hover > .dropdown-menu, .navbar .dropend:hover > .dropdown-menu, .navbar .dropstart:hover > .dropdown-menu {
    transition: opacity 0.15s, transform 0.25s cubic-bezier(0.25, 0.5, 0.75, 1);
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .dropdown-menu-fw {
    width: auto;
    max-width: none;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    right: 0;
    left: 0;
  }
  .navbar-boxed .navbar .dropdown-menu-fw {
    margin-left: 0;
    margin-right: 0;
  }
  .dropdown-menu-md {
    width: 720px;
  }
  .dropdown-menu-sm {
    width: 540px;
  }
  .navbar-no-collapse-items .dropdown-menu.dropdown-menu-xs {
    min-width: 360px;
    width: 360px;
  }
  .dropdown-menu-xs {
    width: 360px;
  }
}
@media (min-width: 1200px) {
  .navbar .dropdown-menu-lg {
    width: auto;
    max-width: none;
  }
  .navbar .dropdown-menu-lg.dropdown-menu-end[data-bs-popper] {
    right: 0;
    margin-right: 0.75rem;
  }
  .navbar .dropdown-menu-md {
    width: 720px;
  }
  .navbar .dropdown-menu-sm {
    width: 540px;
  }
  .navbar .dropdown-menu-xs {
    width: 340px;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm.navbar .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-sm.navbar .dropend .dropdown-menu,
.navbar-expand-sm.navbar .dropstart .dropdown-menu {
    opacity: 1;
    position: static;
    padding-top: 0.25rem;
    padding-right: 0;
    padding-left: 1rem;
    padding-bottom: 0;
  }
  .navbar-expand-sm.navbar .nav-item.dropdown {
    position: static;
  }
  .navbar-expand-sm.navbar .navbar-no-collapse-items .dropdown-menu {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md.navbar .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-md.navbar .dropend .dropdown-menu,
.navbar-expand-md.navbar .dropstart .dropdown-menu {
    opacity: 1;
    position: static;
    padding-top: 0.25rem;
    padding-right: 0;
    padding-left: 1rem;
    padding-bottom: 0;
  }
  .navbar-expand-md.navbar .nav-item.dropdown {
    position: static;
  }
  .navbar-expand-md.navbar .navbar-no-collapse-items .dropdown-menu {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg.navbar .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-lg.navbar .dropend .dropdown-menu,
.navbar-expand-lg.navbar .dropstart .dropdown-menu {
    opacity: 1;
    position: static;
    padding-top: 0.25rem;
    padding-right: 0;
    padding-left: 1rem;
    padding-bottom: 0;
  }
  .navbar-expand-lg.navbar .nav-item.dropdown {
    position: static;
  }
  .navbar-expand-lg.navbar .navbar-no-collapse-items .dropdown-menu {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl.navbar .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-xl.navbar .dropend .dropdown-menu,
.navbar-expand-xl.navbar .dropstart .dropdown-menu {
    opacity: 1;
    position: static;
    padding-top: 0.25rem;
    padding-right: 0;
    padding-left: 1rem;
    padding-bottom: 0;
  }
  .navbar-expand-xl.navbar .nav-item.dropdown {
    position: static;
  }
  .navbar-expand-xl.navbar .navbar-no-collapse-items .dropdown-menu {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl.navbar .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-xxl.navbar .dropend .dropdown-menu,
.navbar-expand-xxl.navbar .dropstart .dropdown-menu {
    opacity: 1;
    position: static;
    padding-top: 0.25rem;
    padding-right: 0;
    padding-left: 1rem;
    padding-bottom: 0;
  }
  .navbar-expand-xxl.navbar .nav-item.dropdown {
    position: static;
  }
  .navbar-expand-xxl.navbar .navbar-no-collapse-items .dropdown-menu {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}
.navbar-expand.navbar .dropdown-menu {
  box-shadow: none;
}
.navbar-expand.navbar .dropend .dropdown-menu,
.navbar-expand.navbar .dropstart .dropdown-menu {
  opacity: 1;
  position: static;
  padding-top: 0.25rem;
  padding-right: 0;
  padding-left: 1rem;
  padding-bottom: 0;
}
.navbar-expand.navbar .nav-item.dropdown {
  position: static;
}
.navbar-expand.navbar .navbar-no-collapse-items .dropdown-menu {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-left: 0;
}

.dropdown-menu.dropdown-menu-xxs {
  max-width: 160px;
  min-width: 160px;
}

.swiper-classic {
  height: 440px;
}
.swiper-classic .swiper-slide {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 992px) {
  .swiper-classic {
    height: 700px;
  }
}
.carousel-layers li {
  transition: opacity 0.3s, transform cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.35s;
  overflow: hidden;
  height: auto;
  display: block;
  opacity: 0;
}
.carousel-layers [data-carousel-layer=fade-up] {
  transform: translate3d(0, 50px, 0);
}
.carousel-layers [data-carousel-layer=fade-down] {
  transform: translate3d(0, -50px, 0);
}
.carousel-layers [data-carousel-layer=fade-start] {
  transform: translate3d(-50px, 0, 0);
}
.carousel-layers [data-carousel-layer=fade-end] {
  transform: translate3d(50px, 0, 0);
}
.carousel-layers [data-carousel-layer=zoom-in] {
  transform: translate3d(0, -50px, 0) scale(0.6);
}

.carousel-item.active .carousel-layers li,
.swiper-slide-active .carousel-layers li {
  transform: translateZ(0) scale(1);
  opacity: 1;
  transition-duration: 800ms;
  transition-delay: 300ms;
}
.carousel-item.active .carousel-layers li:nth-child(2),
.swiper-slide-active .carousel-layers li:nth-child(2) {
  transition-delay: 600ms;
}
.carousel-item.active .carousel-layers li:nth-child(3),
.swiper-slide-active .carousel-layers li:nth-child(3) {
  transition-delay: 800ms;
}
.carousel-item.active .carousel-layers li:nth-child(4),
.swiper-slide-active .carousel-layers li:nth-child(4) {
  transition-delay: 1s;
}

.swiper-parallax-bg .swiper-slide {
  height: 75vh;
  display: flex;
  align-items: center;
}
.swiper-parallax-bg .parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 135%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.swiper-progress .swiper-pagination-progressbar {
  bottom: 0;
  top: auto;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.125);
}
.swiper-progress .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: rgba(255, 255, 255, 0.5);
}

.swiper-vertical {
  height: 100%;
  width: 100%;
}
.swiper-vertical .swiper-slide {
  display: flex;
  align-items: center;
  height: 100%;
}

.swiper-pagination .swiper-pagination-bullet {
  opacity: 0.5;
  background-color: currentColor;
  border-radius: 50%;
  transition: opacity 0.3s, transform 0.3s ease-in-out;
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 1rem;
  transform: scale(0.5);
  transform-origin: center;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  transform: none;
}

.swiper-button-next,
.swiper-button-prev {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: 2.5rem;
  background-color: #c4c3c6;
  color: #110f1a;
  transition: all 0.25s;
}
.swiper-button-next:focus,
.swiper-button-prev:focus {
  outline: none !important;
}
.swiper-button-next::after, .swiper-button-next::before,
.swiper-button-prev::after,
.swiper-button-prev::before {
  content: "";
  font-family: inherit;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 2px;
  background: currentColor;
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate(-50%, -50%) scaleX(0.5);
  transform-origin: right;
  opacity: 0;
}
.swiper-button-next::before,
.swiper-button-prev::before {
  border: solid currentColor;
  border-width: 0 2px 2px 0;
  display: inline-block;
  width: 8px;
  height: 8px;
  position: relative;
  transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: center;
  transform: rotate(-45deg);
}

.swiper-button-prev::before {
  transform: rotate(135deg);
}
.swiper-button-prev::after {
  transform-origin: left;
}

.swiper-button-next:not(.swiper-button-disabled):hover::after {
  opacity: 1;
  transform: translate(-50%, -50%) scaleX(1);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}
.swiper-button-next:not(.swiper-button-disabled):hover::before {
  transform: translate(2px, 0) rotate(-45deg);
}

.swiper-button-prev:not(.swiper-button-disabled):hover::after {
  opacity: 1;
  transform: translate(-50%, -50%) scaleX(1);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}
.swiper-button-prev:not(.swiper-button-disabled):hover::before {
  transform: translate(-2px, 0) rotate(135deg);
}

.text-white .swiper-pagination .swiper-pagination-bullet,
.text-light .swiper-pagination .swiper-pagination-bullet {
  background-color: white;
}

.swiper-thumbs .swiper-slide {
  transition: all 0.25s;
  opacity: 0.35;
  cursor: pointer;
}
.swiper-thumbs .swiper-slide img {
  width: 100%;
  max-width: 70px;
  transition: all 0.25s;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: center;
  height: auto;
  display: block;
  margin: 0 auto;
}
.swiper-thumbs .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-thumbs .swiper-slide.swiper-slide-thumb-active img {
  transform: none;
}

.swiper-slide-thumb-active .swiper-pagination-progress-bar-inner {
  transition-property: width;
  transition-timing-function: linear;
  width: 100%;
}

.swiper-pagination-progress {
  cursor: pointer;
}

.swiper-pagination-progress-bar {
  background-color: rgba(17, 15, 26, 0.125);
  height: 4px;
  position: relative;
}

.swiper-pagination-progress-bar-inner {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  background-color: #110f1a;
  transition: none;
}

.text-white .swiper-pagination-progress-bar {
  background-color: rgba(255, 255, 255, 0.25);
}

.text-white .swiper-pagination-progress-bar-inner {
  background-color: #fff;
}

.swiper-divider-line {
  background-color: #cfcfd1;
  height: 2px;
}
.swiper-divider-line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  border-radius: 100%;
  background-color: #110f1a;
  border: 5px solid #f9f8fc;
  margin-left: -1px;
}

.spinner-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

body.loaded .spinner-loader {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.form-control.text-white::placeholder, input.text-white::placeholder, select.text-white::placeholder, textarea.text-white::placeholder {
  color: rgba(255, 255, 255, 0.65);
}

.input-icon-group {
  position: relative;
}
.input-icon-group .form-control {
  padding-left: 3rem;
}
.input-icon-group .form-control-lg {
  padding-left: 3.5rem;
}
.input-icon-group .form-control-sm {
  padding-left: 2.25rem;
}
.input-icon-group .input-icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
}
.input-icon-group .input-icon::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(234, 232, 240, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}
.input-icon-group .input-icon > i {
  font-size: 1rem;
  opacity: 0.3;
}
.input-icon-group.input-icon-group-sm .input-icon {
  width: 2rem;
}
.input-icon-group.input-icon-group-sm .input-icon > i {
  font-size: 0.875rem;
}
.input-icon-group.input-icon-group-lg .input-icon {
  width: 3rem;
}
.input-icon-group.input-icon-group-lg .input-icon > i {
  font-size: 1.125rem;
}

.form-switch > .form-check-input {
  height: 1.5rem;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  box-shadow: 0 0 0.75rem rgba(53, 165, 82, 0.0875);
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  box-shadow: 0 0 0.75rem rgba(249, 71, 112, 0.0875);
}

.fill-primary *, .fill-primary {
  fill: #110f1a !important;
}

.stroke-primary, .stroke-primary * {
  stroke: #110f1a !important;
}

.text-outline-primary {
  color: transparent !important;
  -webkit-text-stroke: 1px #110f1a;
}

.fill-secondary *, .fill-secondary {
  fill: #352f4a !important;
}

.stroke-secondary, .stroke-secondary * {
  stroke: #352f4a !important;
}

.text-outline-secondary {
  color: transparent !important;
  -webkit-text-stroke: 1px #352f4a;
}

.fill-success *, .fill-success {
  fill: #35a552 !important;
}

.stroke-success, .stroke-success * {
  stroke: #35a552 !important;
}

.text-outline-success {
  color: transparent !important;
  -webkit-text-stroke: 1px #35a552;
}

.fill-info *, .fill-info {
  fill: #00c2ff !important;
}

.stroke-info, .stroke-info * {
  stroke: #00c2ff !important;
}

.text-outline-info {
  color: transparent !important;
  -webkit-text-stroke: 1px #00c2ff;
}

.fill-warning *, .fill-warning {
  fill: #ffd751 !important;
}

.stroke-warning, .stroke-warning * {
  stroke: #ffd751 !important;
}

.text-outline-warning {
  color: transparent !important;
  -webkit-text-stroke: 1px #ffd751;
}

.fill-danger *, .fill-danger {
  fill: #f94770 !important;
}

.stroke-danger, .stroke-danger * {
  stroke: #f94770 !important;
}

.text-outline-danger {
  color: transparent !important;
  -webkit-text-stroke: 1px #f94770;
}

.fill-light *, .fill-light {
  fill: #f9f8fc !important;
}

.stroke-light, .stroke-light * {
  stroke: #f9f8fc !important;
}

.text-outline-light {
  color: transparent !important;
  -webkit-text-stroke: 1px #f9f8fc;
}

.fill-white *, .fill-white {
  fill: #fff !important;
}

.stroke-white, .stroke-white * {
  stroke: #fff !important;
}

.text-outline-white {
  color: transparent !important;
  -webkit-text-stroke: 1px #fff;
}

.fill-dark *, .fill-dark {
  fill: #110f1a !important;
}

.stroke-dark, .stroke-dark * {
  stroke: #110f1a !important;
}

.text-outline-dark {
  color: transparent !important;
  -webkit-text-stroke: 1px #110f1a;
}

.fill-gray-200 *, .fill-gray-200 {
  fill: #eae8f0 !important;
}

.stroke-gray-200, .stroke-gray-200 * {
  stroke: #eae8f0 !important;
}

.text-outline-gray-200 {
  color: transparent !important;
  -webkit-text-stroke: 1px #eae8f0;
}

.fill-gray-800 *, .fill-gray-800 {
  fill: #262136 !important;
}

.stroke-gray-800, .stroke-gray-800 * {
  stroke: #262136 !important;
}

.text-outline-gray-800 {
  color: transparent !important;
  -webkit-text-stroke: 1px #262136;
}

.fill-currentColor {
  fill: currentColor !important;
}
.fill-currentColor > * {
  fill: currentColor !important;
}

.stroke-currentColor {
  stroke: currentColor !important;
}
.stroke-currentColor > * {
  stroke: currentColor !important;
}

.link-underline {
  display: inline-block;
  position: relative;
  padding-bottom: 0.125rem;
  overflow: hidden;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  transition: color 0.25s cubic-bezier(0.28, 0.44, 0.49, 1);
}
.link-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  width: 100%;
  will-change: transform;
  background-color: currentColor;
  opacity: 0.35;
}
.link-underline:hover::after {
  opacity: 1;
  animation: scaleOut 0.25s cubic-bezier(0.28, 0.44, 0.49, 1) 0s forwards, scaleIn 0.25s cubic-bezier(0.28, 0.44, 0.49, 1) 0.25s forwards;
}

.link-decoration {
  text-decoration: underline;
}
.link-decoration:hover {
  text-decoration: none;
}

.link-hover-decoration {
  text-decoration: none;
}
.link-hover-decoration:hover {
  text-decoration: underline;
}

.link-hover-underline {
  position: relative;
  display: inline-block;
}
.link-hover-underline::after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  top: 100%;
  left: 0.05em;
  right: 0.05em;
  background: currentColor;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: -webkit-transform 0.6s cubic-bezier(1, 0, 0, 1);
  transition: transform 0.6s cubic-bezier(1, 0, 0, 1);
}
.link-hover-underline:hover::after {
  transform: scaleX(1);
  transition-duration: 0.9s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: 0 50%;
}

.link-decoration:not(:hover):not(.active) {
  text-decoration: underline;
}

.link-hover-move-arrow {
  position: relative;
  display: inline-block;
  transition: padding 0.35s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.link-hover-move-arrow svg {
  width: 10px;
  height: auto;
  margin-right: 2px;
}
.link-hover-move-arrow svg > path {
  fill: currentColor;
}
.link-hover-move-arrow:hover {
  padding-left: 0.5rem;
}

.link-both-underline {
  display: inline-block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}
.link-both-underline::after {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  transform: translate3d(-102%, 0, 0);
  content: "";
  display: block;
  height: 1px;
  background-color: currentColor;
}
.link-both-underline:hover::after {
  animation: underline-both 1s ease;
}

.link-hover-no-underline {
  display: inline-block;
  position: relative;
  padding-bottom: 0.02em;
}
.link-hover-no-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  background: currentColor;
  width: 100%;
  right: 0;
  left: 0;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.link-hover-no-underline:hover:after {
  width: 0;
  left: 50%;
  right: auto;
}

/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
  display: inline-block;
}

/* Psuedo-element chars */
.splitting .char {
  white-space: nowrap;
  position: relative;
}

.link-underline > *,
.link-hover-underline > *,
.btn > *,
.nav-link > * {
  pointer-events: none;
}

.link-multiline {
  display: inline;
  background-image: linear-gradient(#110f1a, #110f1a);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.3s, color 0.3s;
}
.link-multiline:hover {
  background-size: 100% 1px;
}

.text-light .link-multiline, .text-white .link-multiline {
  background-image: linear-gradient(#fff, #fff);
}

.link-2x.link-underline, .link-2x.link-hover-underline, .link-2x.link-hover-nounderline, .link-2x.link-both-underline {
  padding-bottom: 0.2rem;
}
.link-2x.link-underline::after, .link-2x.link-hover-underline::after, .link-2x.link-hover-nounderline::after, .link-2x.link-both-underline::after {
  height: 2px;
}

.link-arrow-bounce {
  animation-name: bounce;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.list-inline-divided .list-inline-item::after {
  content: "/";
  margin-left: 0.5rem;
  font-size: 0.739375rem;
  color: currentColor;
  opacity: 0.25;
  display: inline-block;
  vertical-align: middle;
  font-weight: light;
}
.list-inline-divided .list-inline-item:last-child::after {
  display: none;
}

.list-disc, .list-circle {
  padding-left: 1rem;
}

.list-disc {
  list-style-type: disc;
}

.list-circle {
  list-style-type: circle;
}

.text-white .list-group-item, .text-light .list-group-item {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.25);
}

.stripes {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

.stripes-container {
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.stripes-item {
  background-color: #f9f8fc;
  height: 100%;
  width: 1px;
}

.text-white .stripes-item, .text-light .stripes-item {
  background-color: rgba(255, 255, 255, 0.075);
}

.progress-page {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: none;
  border: 0;
  height: auto;
  margin: 0;
  padding: 0;
}
.progress-page progress {
  position: relative;
  display: block;
  height: 5px;
  width: 100%;
  border: 0;
  background-color: transparent;
}
.progress-page progress::-moz-progress-bar {
  background-color: #acabaf;
}
.progress-page progress::-webkit-progress-bar {
  background-color: transparent;
}
.progress-page progress::-webkit-progress-value {
  background-color: #acabaf;
}

.progress-bar.aos-init {
  width: 0;
}

.toTop {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #fff;
  color: #1a1726;
  box-shadow: 0 0.5rem 1rem rgba(17, 15, 26, 0.0875);
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: 1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-30px);
  transition: 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  z-index: 9999;
}
.toTop.show {
  visibility: visible;
  opacity: 1;
  transform: none;
}

.connect-line {
  display: block;
  bottom: 0;
  width: 1px;
  height: 4rem;
  background-color: currentColor;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  z-index: 10;
  animation: connectLine 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  -webkit-animation: connectLine 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.c-scrollbar_thumb {
  background-color: rgba(17, 15, 26, 0.5);
  border-radius: 0px;
  margin: 0px;
}

.si {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
  font-size: 1.25rem;
  color: #1a1726;
}
.si > i {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.15s, transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  line-height: 1;
}
.si > i:last-child {
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
}
.si:hover > i:first-child {
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
}
.si:hover > i:last-child {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.si.si-border {
  border: 1px solid #eae8f0;
}

.si-list-inline li .si + .si {
  margin-left: 0.25rem;
}

.si-circle {
  border-radius: 100%;
}

.si-hover-facebook:hover {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
  color: #fff !important;
}

.si-hover-twitter:hover {
  background-color: #1DA1F2 !important;
  border-color: #1DA1F2 !important;
  color: #fff !important;
}

.si-hover-google:hover {
  background-color: #4285F4 !important;
  border-color: #4285F4 !important;
  color: #fff !important;
}

.si-hover-skype:hover {
  background-color: #00aff0 !important;
  border-color: #00aff0 !important;
  color: #fff !important;
}

.si-hover-linkedin:hover {
  background-color: #0077b5 !important;
  border-color: #0077b5 !important;
  color: #fff !important;
}

.si-hover-dribbble:hover {
  background-color: #ea4c89 !important;
  border-color: #ea4c89 !important;
  color: #fff !important;
}

.si-hover-youtube:hover {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: #fff !important;
}

.si-hover-github:hover {
  background-color: #171515 !important;
  border-color: #171515 !important;
  color: #fff !important;
}

.si-hover-google-play:hover {
  background-color: #dd4b39 !important;
  border-color: #dd4b39 !important;
  color: #fff !important;
}

.si-hover-behance:hover {
  background-color: #053eff !important;
  border-color: #053eff !important;
  color: #fff !important;
}

.si-hover-whatsapp:hover {
  background-color: #25D366 !important;
  border-color: #25D366 !important;
  color: #fff !important;
}

.si-hover-twitch:hover {
  background-color: #6441a5 !important;
  border-color: #6441a5 !important;
  color: #fff !important;
}

.si-colored-facebook {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
  color: #fff !important;
}
.si-colored-facebook:hover {
  background-color: #293e6a !important;
  border-color: #23355b !important;
}

.si-colored-twitter {
  background-color: #1DA1F2 !important;
  border-color: #1DA1F2 !important;
  color: #fff !important;
}
.si-colored-twitter:hover {
  background-color: #1471a9 !important;
  border-color: #116191 !important;
}

.si-colored-google {
  background-color: #4285F4 !important;
  border-color: #4285F4 !important;
  color: #fff !important;
}
.si-colored-google:hover {
  background-color: #2e5dab !important;
  border-color: #285092 !important;
}

.si-colored-skype {
  background-color: #00aff0 !important;
  border-color: #00aff0 !important;
  color: #fff !important;
}
.si-colored-skype:hover {
  background-color: #007ba8 !important;
  border-color: #006990 !important;
}

.si-colored-linkedin {
  background-color: #0077b5 !important;
  border-color: #0077b5 !important;
  color: #fff !important;
}
.si-colored-linkedin:hover {
  background-color: #00537f !important;
  border-color: #00476d !important;
}

.si-colored-dribbble {
  background-color: #ea4c89 !important;
  border-color: #ea4c89 !important;
  color: #fff !important;
}
.si-colored-dribbble:hover {
  background-color: #a43560 !important;
  border-color: #8c2e52 !important;
}

.si-colored-youtube {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
  color: #fff !important;
}
.si-colored-youtube:hover {
  background-color: #b30000 !important;
  border-color: #990000 !important;
}

.si-colored-github {
  background-color: #171515 !important;
  border-color: #171515 !important;
  color: #fff !important;
}
.si-colored-github:hover {
  background-color: #100f0f !important;
  border-color: #0e0d0d !important;
}

.si-colored-google-play {
  background-color: #dd4b39 !important;
  border-color: #dd4b39 !important;
  color: #fff !important;
}
.si-colored-google-play:hover {
  background-color: #9b3528 !important;
  border-color: #852d22 !important;
}

.si-colored-behance {
  background-color: #053eff !important;
  border-color: #053eff !important;
  color: #fff !important;
}
.si-colored-behance:hover {
  background-color: #042bb3 !important;
  border-color: #032599 !important;
}

.si-colored-whatsapp {
  background-color: #25D366 !important;
  border-color: #25D366 !important;
  color: #fff !important;
}
.si-colored-whatsapp:hover {
  background-color: #1a9447 !important;
  border-color: #167f3d !important;
}

.si-colored-twitch {
  background-color: #6441a5 !important;
  border-color: #6441a5 !important;
  color: #fff !important;
}
.si-colored-twitch:hover {
  background-color: #462e74 !important;
  border-color: #3c2763 !important;
}

.si-colored-instagram, .si-hover-instagram:hover, .si-colored-instagram:hover {
  background-image: radial-gradient(circle at 30% 107%, #FCAF45 0%, #F77737 5%, #E1306C 45%, #833AB4 60%, #405DE6 90%) !important;
  color: #fff;
  border-color: transparent;
}

.form-pricing-switch.form-switch {
  margin-bottom: 0;
}
.form-pricing-switch.form-switch > .form-check-input {
  height: 1.75rem;
  width: 3.25rem;
  margin-top: 0;
}

.offcanvas.offcanvas-fullscreen.show ul > li {
  overflow: unset !important;
}

.fullscreen-item {
  transition: opacity 0.5s linear 0s, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  opacity: 0;
  transform: translateY(3.5rem);
}

.fullscreen-inner .js-hover-img {
  transition: none;
}
.fullscreen-inner .js-hover-img .js-hover-img-item {
  transition: opacity 0.2s, transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transform: translate3d(0, 30px, 0);
  opacity: 0;
}
.fullscreen-inner .js-hover-img .js-hover-img-item .js-hover-img-link {
  transition: none;
}
.fullscreen-inner .js-hover-img .js-hover-img-item .js-hover-img-link:not(:hover):not(.active) {
  mix-blend-mode: screen;
}

.fullscreen-inner .js-hover-img .js-hover-img-item {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s linear 0.8s, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(1) {
  transition-delay: 300ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(2) {
  transition-delay: 600ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(3) {
  transition-delay: 900ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(4) {
  transition-delay: 1200ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(5) {
  transition-delay: 1500ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(6) {
  transition-delay: 1800ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(7) {
  transition-delay: 2100ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(8) {
  transition-delay: 2400ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(9) {
  transition-delay: 2700ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(10) {
  transition-delay: 3000ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(11) {
  transition-delay: 3300ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(12) {
  transition-delay: 3600ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(13) {
  transition-delay: 3900ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(14) {
  transition-delay: 4200ms;
}
.fullscreen-inner .js-hover-img .js-hover-img-item:nth-child(15) {
  transition-delay: 4500ms;
}

.fullscreen-item {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s linear 0.8s, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
}
.fullscreen-item:nth-child(1) {
  transition-delay: 300ms;
}
.fullscreen-item:nth-child(2) {
  transition-delay: 600ms;
}
.fullscreen-item:nth-child(3) {
  transition-delay: 900ms;
}
.fullscreen-item:nth-child(4) {
  transition-delay: 1200ms;
}
.fullscreen-item:nth-child(5) {
  transition-delay: 1500ms;
}
.fullscreen-item:nth-child(6) {
  transition-delay: 1800ms;
}
.fullscreen-item:nth-child(7) {
  transition-delay: 2100ms;
}
.fullscreen-item:nth-child(8) {
  transition-delay: 2400ms;
}
.fullscreen-item:nth-child(9) {
  transition-delay: 2700ms;
}
.fullscreen-item:nth-child(10) {
  transition-delay: 3000ms;
}
.fullscreen-item:nth-child(11) {
  transition-delay: 3300ms;
}
.fullscreen-item:nth-child(12) {
  transition-delay: 3600ms;
}
.fullscreen-item:nth-child(13) {
  transition-delay: 3900ms;
}
.fullscreen-item:nth-child(14) {
  transition-delay: 4200ms;
}
.fullscreen-item:nth-child(15) {
  transition-delay: 4500ms;
}

.offcanvas {
  transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.offcanvas.offcanvas-start, .offcanvas.offcanvas-end, .offcanvas.offcanvas-bottom {
  border: 0px;
}
.offcanvas .offcanvas-header .btn-close {
  padding: 0px;
}
.offcanvas .nav-link {
  font-weight: 500;
  display: block;
}
.offcanvas ul > li {
  transform: translateY(30px);
  opacity: 0;
}
.offcanvas ul.no-animation > li {
  transform: none;
  opacity: 1;
  transition: none;
}
.offcanvas.show:not(.offcanvas-navbar) {
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.offcanvas.show:not(.offcanvas-navbar) ul > li {
  overflow: hidden;
  transition: opacity 0.45s, transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 1;
  transform: translateZ(0);
}
.offcanvas.show:not(.offcanvas-navbar) ul > li:nth-child(1) {
  transition-delay: 0.25s;
}
.offcanvas.show:not(.offcanvas-navbar) ul > li:nth-child(2) {
  transition-delay: 0.5s;
}
.offcanvas.show:not(.offcanvas-navbar) ul > li:nth-child(3) {
  transition-delay: 0.75s;
}
.offcanvas.show:not(.offcanvas-navbar) ul > li:nth-child(4) {
  transition-delay: 1s;
}
.offcanvas.show:not(.offcanvas-navbar) ul > li:nth-child(5) {
  transition-delay: 1.25s;
}

th {
  font-weight: 500;
}

.table > :not(:first-child) {
  border-top: 1px solid currentColor;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background-color: #110f1a;
  border-color: #110f1a;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background-color: #e7e7e8;
  border-color: #e7e7e8;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e7e7e8, 5px 0 0 #e7e7e8;
  box-shadow: -5px 0 0 #e7e7e8, 5px 0 0 #e7e7e8;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #c7c3d3;
}

.flatpickr-current-month {
  font-size: 1rem;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: #e7e7e8;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  border-radius: 0.475rem;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  fill: #1a1726;
  color: #1a1726;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #110f1a;
}

.flatpickr-day {
  color: #1a1726;
}

.flatpickr-day.today {
  border-color: #e6e5ec;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #352f4a;
  background: #352f4a;
  color: #fff;
}

.page-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.page-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.page-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.3s;
  width: 260px;
  height: 100%;
  overflow: hidden;
  z-index: 1030;
}

.page-header {
  z-index: 1040;
}

.navbar-brand .navbar-brand-icon {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #110f1a;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-sidebar .page-sidebar-title,
.page-sidebar .nav .nav-link,
.page-sidebar .page-sidebar-brand {
  padding: 0.25rem 1rem;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  transition: all 0.25s;
}
.page-sidebar .nav .nav-link[data-bs-toggle=collapse]:after {
  opacity: 0.5;
  margin-right: 1rem;
  font-size: 0.75rem;
}
.page-sidebar .page-sidebar-title {
  opacity: 0.5;
  font-size: 0.875rem;
  padding-left: 1.75;
  position: relative;
}
.page-sidebar .page-sidebar-title i, .page-sidebar .page-sidebar-title span {
  transition: all 0.2s;
}
.page-sidebar .page-sidebar-title i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
  opacity: 0;
  margin-left: 1.75rem;
}
.page-sidebar .nav-sidebar-icon {
  width: 2.25rem;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-style: normal;
  border-radius: 0.25rem;
  margin-right: 0.75rem;
}

.page-content {
  padding-left: 260px;
  transition: all 0.3s;
}

.page-content, .page-sidebar {
  padding-top: 63px;
}

.sidebar-dropdown {
  margin: 0;
  padding: 0;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
}
.sidebar-dropdown .sidebar-link {
  display: flex;
  align-items: center;
  padding: 0.4rem 1rem;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sidebar-dropdown .sidebar-link[data-bs-toggle=collapse]::after {
  opacity: 0.5;
  margin-right: 1rem;
  font-size: 0.75rem;
}

.sidebar-dropdown.sd-second-level, .sidebar-dropdown.sd-thrid-level {
  padding-left: 0.75rem;
}

.sidebar-light .nav .nav-link {
  color: #1a1726;
}
.sidebar-light .sidebar-dropdown .sidebar-link {
  color: #1a1726;
}

.sidebar-toggler {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  position: relative;
}
.sidebar-toggler::before, .sidebar-toggler::after {
  content: "";
  height: 2px;
  background-color: currentColor;
  position: absolute;
  left: 15%;
  width: 70%;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.25s;
}
.sidebar-toggler::before {
  margin-top: 6px;
}
.sidebar-toggler::after {
  margin-top: -6px;
}
.sidebar-toggler i {
  transition: all 0.25s;
  display: block;
  position: absolute;
  width: 70%;
  height: 100%;
  left: 15%;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  background-color: currentColor;
}
.sidebar-toggler.sidebar-toggled i {
  width: 50%;
  transition-delay: 0.15s;
}
.sidebar-toggler.sidebar-toggled::before {
  width: 25%;
  transition-delay: 0.25s;
}

@media (min-width: 992px) {
  body.page-collapsed .page-sidebar:not(:hover) {
    width: 72px;
  }
  body.page-collapsed .page-sidebar:not(:hover) .sidebar-dropdown.collapse.show {
    height: 0px;
    overflow: hidden;
    transition: height 0.3s ease 0s;
  }
  body.page-collapsed .page-sidebar:not(:hover) .nav-link-text, body.page-collapsed .page-sidebar:not(:hover) .navbar-brand-text {
    opacity: 0;
  }
  body.page-collapsed .page-sidebar:not(:hover) .sidebar-toggler {
    display: none;
  }
  body.page-collapsed .page-sidebar:not(:hover) .page-sidebar-title span {
    opacity: 0;
  }
  body.page-collapsed .page-sidebar:not(:hover) .page-sidebar-title i {
    opacity: 1;
    transform: translateX(0) translateY(-50%);
  }
  body.page-collapsed .page-content {
    padding-left: 72px;
  }
}
@media (max-width: 991.98px) {
  .page-sidebar {
    transform: translateX(-100%);
  }
  .page-content {
    padding-left: 0;
  }
  body.page-collapsed .page-sidebar {
    transform: translateX(0);
  }
}
.list-timeline-v {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.list-timeline-v li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.list-timeline-v li:not(:last-child) {
  margin-bottom: 1.5rem;
}
.list-timeline-v li .timeline-icon {
  margin-right: 1.5rem;
  z-index: 1;
  position: relative;
}
.list-timeline-v li .timeline-icon + div {
  -ms-flex: 1;
  flex: 1;
}

.timeline-icon {
  width: 3rem;
  height: 3rem;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.25rem;
  color: #110f1a;
}

@media (min-width: 768px) {
  .list-timeline-v li {
    width: 50%;
    margin-left: 50%;
    position: relative;
  }
  .list-timeline-v li .timeline-icon {
    margin-left: -1.5rem;
  }
  .list-timeline-v li:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: 0;
    margin-right: 50%;
  }
  .list-timeline-v li:nth-child(even) .timeline-icon {
    margin-right: -1.5rem;
    margin-left: 1.5rem;
  }
  .list-timeline-v li:not(:last-child) {
    margin-bottom: 3rem;
    padding-bottom: 0;
  }
  .list-timeline-v li:not(:last-child):after {
    content: "";
    display: block;
    width: 1px;
    height: calc(100% - 2rem);
    border-left: 1px dashed #eae8f0;
    position: absolute;
    top: 4rem;
  }
}
.step .step-item {
  margin-bottom: 1.25rem;
}
.step .step-row {
  position: relative;
  display: flex;
  padding-bottom: 1.25rem;
}
.step .step-icon {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 1.25rem;
}
.step .step-icon::after {
  border-left: 2px dashed #eae8f0;
  content: "";
  position: absolute;
  top: 3.75rem;
  left: calc(1.5rem - 1px);
  width: 1rem;
  height: calc(100% - 3.25rem);
}
.step .step-item:last-child .step-icon::after {
  height: 0;
}

.dropdown-toggle::before, .dropdown-toggle::after {
  font-family: boxicons !important;
  font-weight: 400 !important;
}

.dropdown-toggle::after {
  margin-left: 0.125rem;
  content: "\ed35";
  vertical-align: -3px;
}

.dropup .dropdown-toggle::after {
  margin-left: 0.125rem;
  content: "\ed42";
  vertical-align: -3px;
}

.dropend .dropdown-toggle::after {
  margin-left: 0.125rem;
  vertical-align: -3px;
  content: "\ed3b";
}

.dropstart .dropdown-toggle::before {
  margin-right: 0.175rem;
  vertical-align: -0.15em;
  content: "\ed38";
}
.dropstart .dropdown-toggle::after {
  display: none;
}

.dropdown-toggle-split.dropdown-toggle::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split.dropdown-toggle::before {
  margin-right: 0px;
}

.navbar .navbar-nav .dropdown-toggle:not(.active)::before, .navbar .navbar-nav .dropdown-toggle:not(.active)::after {
  opacity: 0.75;
}

@media (min-width: 576px) {
  .navbar-expand-sm .dropdown-menu .dropend .dropdown-toggle::after {
    content: "\ed3b";
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: initial;
    line-height: 1;
  }
  .navbar-expand-sm .dropdown-menu .dropstart .dropdown-toggle::before {
    content: "\ed38";
    position: absolute;
    left: -0.125rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm .dropdown-menu .dropend .dropdown-toggle::after {
    content: "\ed35";
  }
  .navbar-expand-sm .dropdown-menu .dropstart .dropdown-toggle::before {
    content: "\ed35";
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .dropdown-menu .dropend .dropdown-toggle::after {
    content: "\ed3b";
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: initial;
    line-height: 1;
  }
  .navbar-expand-md .dropdown-menu .dropstart .dropdown-toggle::before {
    content: "\ed38";
    position: absolute;
    left: -0.125rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md .dropdown-menu .dropend .dropdown-toggle::after {
    content: "\ed35";
  }
  .navbar-expand-md .dropdown-menu .dropstart .dropdown-toggle::before {
    content: "\ed35";
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .dropdown-menu .dropend .dropdown-toggle::after {
    content: "\ed3b";
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: initial;
    line-height: 1;
  }
  .navbar-expand-lg .dropdown-menu .dropstart .dropdown-toggle::before {
    content: "\ed38";
    position: absolute;
    left: -0.125rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .dropdown-menu .dropend .dropdown-toggle::after {
    content: "\ed35";
  }
  .navbar-expand-lg .dropdown-menu .dropstart .dropdown-toggle::before {
    content: "\ed35";
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .dropdown-menu .dropend .dropdown-toggle::after {
    content: "\ed3b";
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: initial;
    line-height: 1;
  }
  .navbar-expand-xl .dropdown-menu .dropstart .dropdown-toggle::before {
    content: "\ed38";
    position: absolute;
    left: -0.125rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .dropdown-menu .dropend .dropdown-toggle::after {
    content: "\ed35";
  }
  .navbar-expand-xl .dropdown-menu .dropstart .dropdown-toggle::before {
    content: "\ed35";
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .dropdown-menu .dropend .dropdown-toggle::after {
    content: "\ed3b";
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: initial;
    line-height: 1;
  }
  .navbar-expand-xxl .dropdown-menu .dropstart .dropdown-toggle::before {
    content: "\ed38";
    position: absolute;
    left: -0.125rem;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl .dropdown-menu .dropend .dropdown-toggle::after {
    content: "\ed35";
  }
  .navbar-expand-xxl .dropdown-menu .dropstart .dropdown-toggle::before {
    content: "\ed35";
  }
}
.navbar-expand .dropdown-menu .dropend .dropdown-toggle::after {
  content: "\ed3b";
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  vertical-align: initial;
  line-height: 1;
}
.navbar-expand .dropdown-menu .dropstart .dropdown-toggle::before {
  content: "\ed38";
  position: absolute;
  left: -0.125rem;
  top: 50%;
  transform: translateY(-50%);
  vertical-align: middle;
}
.navbar-expand .dropdown-menu .dropend .dropdown-toggle::after {
  content: "\ed35";
}
.navbar-expand .dropdown-menu .dropstart .dropdown-toggle::before {
  content: "\ed35";
}

.page-item:not(:first-child) {
  margin-left: 0.375rem;
}
.page-item .page-link {
  border-radius: 0.475rem;
}

.pagination-sm .page-item:not(:first-child) {
  margin-left: 0.25rem;
}
.pagination-sm .page-link {
  border-radius: 0.325rem;
}

.pagination-lg .page-item:not(:first-child) {
  margin-left: 0.5rem;
}
.pagination-lg .page-link {
  border-radius: 0.725rem;
}

.mask-squircle {
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
}

.mask-blob {
  mask-image: url('data:image/svg+xml;charset=utf-8,%3Csvg width="960" height="1140" viewBox="0 0 960 1140" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M408.453 0.35942C262.614 -4.60354 93.7303 41.1372 20.1168 180.826C-48.758 311.523 80.8716 460.184 86.4278 611.433C91.5013 749.544 -20.5442 894.766 50.5155 1008.33C124.267 1126.2 279.385 1116.17 408.453 1126.09C551.791 1137.11 709.886 1170.19 819.707 1067.45C934.679 959.904 967.526 777.933 958.621 611.433C950.224 454.41 873.887 317.789 773.663 206.47C673.336 95.0366 550.388 5.18954 408.453 0.35942Z" fill="%23000"/%3E%3C/svg%3E');
}

.mask-blob-2 {
  mask-image: url('data:image/svg+xml;charset=utf-8,%3Csvg width="960" height="1140" viewBox="0 0 960 1140" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M537.08 1103.67C392.701 1112.16 220.82 1193.69 120.365 1080.49C20.0292 967.422 127.095 783.434 113.977 626.879C98.0414 436.693 -73.5079 230.789 36.7192 82.2148C145.776 -64.7824 366.175 23.5494 537.08 56.5805C670.343 82.3365 804.269 129.485 886.552 246.463C960.973 352.266 934.079 493.7 938.227 626.879C942.631 768.245 998.846 929.688 911.085 1034C822.28 1139.55 668.373 1095.95 537.08 1103.67Z" fill="%23000"/%3E%3C/svg%3E');
}

.mask-blob-3 {
  mask-image: url('data:image/svg+xml;charset=utf-8,%3Csvg width="960" height="900" viewBox="0 0 960 900" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M956.368 429.683C943.276 311.394 895.043 194.621 814.425 111.211C733.807 27.8021 620.804 -23.0016 533.296 10.3622C481.227 30.2137 438.429 79.8636 391.837 133.915C360.124 170.704 326.654 209.533 287.307 242.391C251.158 272.512 209.977 297.414 170.363 321.369C104.267 361.337 42.5359 398.666 15.8243 453.19C4.92206 475.808 0 501.334 0 528.233C0 605.039 40.1287 693.037 95.7533 756.497C170.859 841.423 272.837 882.369 365.858 895.26C389.407 898.523 412.337 899.988 434.94 900C435.122 900 435.303 900 435.485 900C501.97 899.966 565.639 887.373 633.896 870.995C725.539 848.247 824.761 817.916 886.086 742.848C947.411 667.021 969.46 547.974 956.368 429.683Z" fill="%23000"/%3E%3C/svg%3E');
}

.mask-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
  visibility: visible;
  --bs-input-border-radius: 0.475rem;
  border-radius: var(--bs-input-border-radius);
  border-width: 0px;
  border-color: transparent;
}

.is-flipped .choices__list--dropdown, .is-flipped .choices__list[aria-expanded] {
  margin-bottom: 0.5rem;
}

.choices__list.dropdown-menu {
  --bs-input-border-radius: 0.475rem;
  border-radius: var(--bs-input-border-radius);
  padding: 0px;
  overflow: hidden;
  z-index: 1055;
  min-width: 100%;
  max-width: 100%;
  margin-top: 0.5rem;
}
.choices__list.dropdown-menu > .form-control {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
}
.choices__list.dropdown-menu > .choices__list {
  max-height: 280px;
  overflow-y: auto;
}
.choices__list.dropdown-menu > .choices__list .dropdown-item {
  border-radius: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.4rem 1rem;
}

.choices__list.choices__list--single {
  padding: 0px;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #f9f8fc;
  color: #110f1a;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted.active, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted.active {
  background-color: #eae8f0;
  color: #110f1a;
}

.choices.is-open:not(.is-flipped) .form-control-sm.form-control + .choices__list.dropdown-menu {
  border-bottom-left-radius: 0.325rem;
  border-bottom-right-radius: 0.325rem;
}
.choices.is-open:not(.is-flipped) .form-control-lg.form-control + .choices__list.dropdown-menu {
  border-bottom-left-radius: 0.725rem;
  border-bottom-right-radius: 0.725rem;
}
.choices.is-open:not(.is-flipped) .form-control + .choices__list.dropdown-menu {
  border-bottom-left-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}

.is-flipped .form-control-sm.form-control + .choices__list.dropdown-menu {
  border-top-left-radius: 0.325rem;
  border-top-right-radius: 0.325rem;
}
.is-flipped .form-control-lg.form-control + .choices__list.dropdown-menu {
  border-top-left-radius: 0.725rem;
  border-top-right-radius: 0.725rem;
}
.is-flipped .form-control + .choices__list.dropdown-menu {
  border-top-left-radius: 0.475rem;
  border-top-right-radius: 0.475rem;
}

.is-open .choices__list--dropdown, .is-open .choices__list[aria-expanded] {
  border-color: #e6e5ec;
}

.choices {
  overflow: visible;
}
.choices > .form-control {
  padding-right: 32px;
}
.choices.is-focused > .form-control {
  box-shadow: 0 0 0.75rem 0 rgba(17, 15, 26, 0.0875);
  border-color: #292731;
}

.choices__placeholder {
  color: #8a8793;
  opacity: 1;
}

.choices__list.dropdown-menu .choices__placeholder.choices__item--disabled {
  opacity: 0.5;
  pointer-events: none;
  background-color: transparent;
}

.choices[data-type*=select-one]::after {
  border: 0px 0px 0px 0px;
  font-family: boxicons !important;
  content: "\ed35";
  transition: all 0.25s;
  margin-top: 0px;
  right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  line-height: 1;
}
.choices[data-type*=select-one].is-open::after {
  transform: translateY(-50%) rotate(180deg);
  margin: 0px;
}

.choices__list.choices__list--multiple + .form-control {
  padding: 0px;
  border: 0px;
  box-shadow: none;
  display: inline;
  min-width: 3rem !important;
}

.choices__list--multiple .choices__item {
  background-color: #110f1a;
  border-color: #110f1a;
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
  border-left-color: #4d4b53;
}

.form-control-lg.form-control + .choices__list.dropdown-menu {
  --bs-input-border-radius-lg: 0.725rem;
  border-radius: var(--bs-input-border-radius-lg);
}
.form-control-lg.form-control + .choices__list.dropdown-menu .dropdown-item {
  padding: 0.75rem 1.675rem;
  font-size: 1.125rem;
}

.is-flipped .choices__list--dropdown, .is-flipped .choices__list[aria-expanded] {
  --bs-input-border-radius: 0.475rem;
  border-radius: var(--bs-input-border-radius);
}

.form-control-sm.form-control + .choices__list.dropdown-menu {
  --bs-input-border-radius-sm: 0.325rem;
  border-radius: var(--bs-input-border-radius-sm);
}
.form-control-sm.form-control + .choices__list.dropdown-menu .dropdown-item {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
}

.choices .choices__inline:not(.btn) {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.choices__inline:not(.btn) + .dropdown-menu .dropdown-item {
  font-size: 1.25rem;
}

.form-control.choices__inline:not(.btn) + .dropdown-menu .dropdown-item {
  font-size: 1rem;
}

.form-control-sm.choices__inline:not(.btn) + .dropdown-menu .dropdown-item {
  font-size: 0.875rem;
}

.form-control-lg.choices__inline:not(.btn) + .dropdown-menu .dropdown-item {
  font-size: 1.25rem;
}

.choices__inline + .choices__list.dropdown-menu {
  border-radius: 0.475rem !important;
}

.choices__inline + .choices__list.dropdown-menu > .choices__list .dropdown-item {
  padding: 0.75rem 1rem;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.js-hover-img {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  text-align: left;
}
.js-hover-img li {
  position: relative;
}
.js-hover-img li img {
  pointer-events: none;
  position: absolute;
  opacity: 0;
  bottom: 0;
  width: auto;
  height: 30vh;
  transition: opacity 0.4s;
}
.js-hover-img li .js-hover-img-link {
  position: relative;
  transition: all 0.6s;
  z-index: 2;
}
.js-hover-img li .js-hover-img-link a {
  display: block;
  text-decoration: none;
  color: currentColor;
  z-index: 3;
  line-height: 1.4;
  transition: all 0.35s;
}
.js-hover-img li .js-hover-img-link a > * {
  pointer-events: none;
  display: block;
}
.js-hover-img li:hover .img {
  opacity: 0.8;
}
.js-hover-img li:hover .js-hover-img-link {
  mix-blend-mode: luminosity;
}

.text-white .js-hover-img li:hover .js-hover-img-link, .text-light .js-hover-img li:hover .js-hover-img-link {
  mix-blend-mode: color-dodge;
}

#marker {
  display: inline-flex;
  font-family: boxicons !important;
  font-style: normal;
  font-weight: normal !important;
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
  background-color: #110f1a;
  box-sizing: 0 2rem 4rem rgba(17, 15, 26, 0.0875);
  color: #fff;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 1.25rem;
  cursor: pointer;
  border-radius: 50rem;
}
#marker::after {
  content: "\eb56";
}

.mapboxgl-popup-content {
  padding: 1.25rem;
  border-radius: 0.725rem;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
}

.ms-layer.ms-title {
  font-size: 5.5rem;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: -0.03em;
}
.ms-layer.ms-title-alt {
  font-size: 4rem;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: -0.03rem;
}
.ms-layer.ms-caption {
  font-weight: normal;
  font-size: 2.25rem;
}

.ms-slider-alt .ms-view {
  background-color: #110f1a;
  background: none;
}

.ms-btn .btn {
  font-size: 1rem;
  padding: 0.475rem 1.5rem;
}

@media (min-width: 768px) {
  .ms-btn .btn {
    padding: 1rem 1.75rem;
    font-size: 1.125rem;
  }
}
@media (max-width: 575.98px) {
  .ms-btn .btn {
    margin-top: 1.5rem;
  }
}
.ms-timerbar {
  height: 6px !important;
}

.ms-time-bar {
  height: 6px !important;
  background-color: rgba(17, 15, 26, 0.1);
  border-top: 0 !important;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    z-index: 9999999;
    mix-blend-mode: difference;
  }
  .cursor__inner {
    display: block;
    margin-top: -0.25rem;
    margin-left: -0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #fff;
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s ease-out;
  }
  .cursor.cHover .cursor__inner {
    width: 0.5rem;
    height: 0.5rem;
    margin-top: 0.25rem;
    margin-left: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .cursor {
    display: none;
  }
}
.ql-toolbar.ql-snow, .ql-container.ql-snow {
  border-color: #e6e5ec;
}

.ql-container {
  font-size: var(--bs-font-sans-serif);
  font-family: var(--bs-font-sans-serif);
}

.ql-editor {
  padding: 1.25rem 1rem;
  background-color: #fff;
  border-bottom-left-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
  font-family: var(--bs-font-sans-serif);
}

.ql-container {
  border-bottom-left-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}

.ql-toolbar.ql-snow {
  font-family: var(--bs-font-sans-serif);
  padding: 0.9375rem 1rem;
  background-color: #fff;
  border-top-left-radius: 0.475rem;
  border-top-right-radius: 0.475rem;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #e6e5ec;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #1a1726;
}

.ql-snow .ql-editor a {
  text-decoration: none;
  font-weight: 600;
}
.ql-snow .ql-editor img {
  border-radius: 0.725rem;
  box-shadow: 0 1rem 3rem rgba(17, 15, 26, 0.075);
}

.ql-snow a {
  color: #110f1a;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: transparent;
  box-shadow: 0 1rem 3rem rgba(17, 15, 26, 0.075);
  padding: 0.5rem;
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
  background-color: #f9f8fc;
  border-radius: 0.475rem;
}

.ql-snow .ql-editor code {
  padding: 4px 8px;
}

.ql-snow .ql-editor blockquote {
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
  background: transparent;
  border: 1px solid #e6e5ec;
  border-left-width: 5px;
  border-left-color: #110f1a;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.typed-cursor {
  font-weight: 300;
  font-size: 75%;
  display: inline-block;
  line-height: inherit;
  vertical-align: middle;
}
.typed-cursor.typed-cursor--blink {
  animation: blink 0.6s both infinite;
}

.dz-preview {
  display: flex;
  flex-wrap: wrap;
}
.dz-preview .badge {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone-image-preview img {
  max-width: 100%;
}

.listing-img-preview {
  margin-right: 0.35rem;
}

.bs-stepper .step-trigger:hover {
  background-color: transparent;
}

.bs-stepper-circle {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  line-height: 1;
  padding: 0;
  margin-right: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.bs-stepper .step-trigger {
  font-weight: inherit;
  color: #1a1726;
}
.bs-stepper .step-trigger .bs-stepper-circle {
  background-color: #e7e7e8;
  color: #110f1a;
}
.bs-stepper .step-trigger:focus {
  color: #110f1a;
}

.bs-stepper .active .bs-stepper-circle {
  background-color: #110f1a;
  color: #e7e7e8;
}
.bs-stepper .active .step-trigger > span:not(.bs-stepper-circle) {
  color: #110f1a;
}

.bs-stepper-vertical .bs-stepper-content {
  padding-top: 0px;
  padding-bottom: 0;
}
.bs-stepper-vertical .step-trigger {
  width: 100%;
  justify-content: flex-start;
  padding: 1rem 0;
  display: flex;
  position: relative;
}
.bs-stepper-vertical .step-trigger::before {
  content: "";
  left: 1.5rem;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #eae8f0;
  position: absolute;
  margin-left: -1px;
}
.bs-stepper-vertical .step-trigger .bs-stepper-circle {
  position: relative;
  margin-left: 0px;
}

.bs-stepper .step:first-child .step-trigger::before {
  bottom: 0;
  top: auto;
  height: calc(100% - 1.25rem);
}
.bs-stepper .step:last-child .step-trigger::before {
  height: calc(100% - 1.5rem);
}

.bs-stepper .step-trigger.disabled, .bs-stepper .step-trigger:disabled {
  opacity: 1;
}

@media (max-width: 520px) {
  .bs-stepper-header {
    text-align: left;
    margin: 0;
    padding: 0 1rem;
  }
  .bs-stepper .step-trigger {
    flex-direction: row;
    padding: 1rem 0;
  }
}
.plyr {
  border-radius: 0.725rem;
  position: relative;
  z-index: 2;
}

.plyr__control--overlaid, .plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background-color: #110f1a;
}

.plyr--full-ui input[type=range] {
  color: #110f1a;
}

.plyr__control--overlaid {
  padding: 1.5rem;
}

.plyr__control--overlaid::before,
.plyr__control--overlaid::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background: #110f1a;
  opacity: 0.8;
  animation: pulse 2s infinite ease-in-out;
  z-index: -1;
}

.dark-mode-checkbox {
  cursor: pointer;
}
.dark-mode-checkbox input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
}
.dark-mode-checkbox .light_mode {
  display: none;
}
.dark-mode-checkbox .slide {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.475rem;
  color: currentColor;
  box-shadow: 0 0.5rem 1rem rgba(17, 15, 26, 0.0875);
}
.dark-mode-checkbox .slide::after {
  content: "\e51c";
  font-family: "Material Icons";
}

body.dark-layout .dark-mode-checkbox .light_mode {
  display: block;
}
body.dark-layout .dark-mode-checkbox .dark_mode {
  display: none;
}
body.dark-layout .dark-mode-checkbox .slide::after {
  content: "\e0f0";
}

.choices {
  position: relative;
}
.choices.is-focused {
  outline: 0;
}
.choices.is-flipped .dropdown-menu {
  top: auto;
  bottom: 100%;
}
.choices .dropdown-menu {
  min-width: 100%;
}
.choices .choices__list--single {
  display: flex;
  align-items: center;
  width: 100%;
}
.choices .choices__list--single::after {
  transition: all 0.25s;
  font-size: 1.325rem;
  line-height: 1;
  margin-left: auto;
  content: "\e5cf";
  font-family: "Material Icons";
}
.choices .choices__inline .choices__list--single::after {
  margin-left: 0.5rem;
}
.choices .choices__inline:not(.btn) {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.choices.is-open .choices__list--single::after {
  transform: rotate(180deg);
}
.choices .choices__fw {
  width: 100%;
}
.choices .choices__fw .choices__list--single::after {
  margin-left: auto;
}

.choices__inline:not(.btn) + .dropdown-menu .dropdown-item {
  font-size: 1.25rem;
}

.choices__list.choices__list--multiple {
  display: inline;
}
.choices__list.choices__list--multiple .choices__item.choices__item--selectable {
  padding: 4px 12px;
  padding-right: 2px;
  border-radius: 0.475rem;
  margin-top: 0.125rem;
  margin-right: 0.25rem;
  background-color: #e6e5ec;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.choices__list.choices__list--multiple .choices__button {
  pointer-events: auto;
  color: #1a1726;
}
.choices__list.choices__list--multiple .choices__item {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
}
.choices__list.choices__list--multiple .choices__item .choices__button {
  text-indent: -999px;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  background-color: transparent;
  margin-left: 0.125rem;
}
.choices__list.choices__list--multiple .choices__item .choices__button::after {
  content: "\e5cd";
  font-family: "Material Icons";
  vertical-align: middle;
  width: 100%;
  text-indent: 0;
}

.choices__list.choices__list--multiple + .form-control {
  min-width: 3rem !important;
  display: inline-block;
  box-shadow: none;
  border: 0px;
  width: 100% !important;
}
.choices__list.choices__list--multiple + .form-control:focus {
  background-color: transparent;
}

.choices.is-open > .form-control {
  box-shadow: 0 0 0.75rem 0 rgba(17, 15, 26, 0.0875);
  border-color: var(--bs-input-focus-border-color);
  background-color: var(--bs-input-focus-bg);
}

.dropzone {
  --bs-input-bg:#fff;
  --bs-input-border-color:#e6e5ec;
  border: 1px dashed var(--bs-input-border-color);
  background-color: var(--bs-input-bg);
  min-height: 220px;
  position: relative;
  overflow: hidden;
  max-width: 320px;
  height: auto;
  padding: 1rem;
  border-radius: 0.725rem;
}
.dropzone .dropzone-select {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
}
.dropzone .dropzone-preview {
  width: 100%;
  height: 100%;
}
.dropzone .dropzone-preview img {
  pointer-events: none;
}
.dropzone .dropzone-preview img[src="#"] {
  opacity: 0;
}
.dropzone .dropzone-delete {
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.dropzone-preview-list.dz-error {
  display: none;
}

.dz-max-files-reached .dropzone-select {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.dropzone-file-area {
  min-height: 200px;
}

.form-control.is-error {
  border-color: #f94770;
  box-shadow: 0 0 0.75rem 0 rgba(249, 71, 112, 0.0875);
}

label.is-error {
  display: block;
  padding: 0.25rem 0.5rem;
  background-color: #fee3ea;
  color: #f94770;
  border-radius: 0.475rem;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}
label.is-error:last-child {
  margin-bottom: 1.5rem;
}

.form-check-input.is-error {
  border-color: #f94770;
}

textarea {
  resize: none;
}

.form-control {
  --bs-input-color:#1a1726;
  --bs-input-bg:#fff;
  --bs-input-border-color:#e6e5ec;
  --bs-input-focus-color:#1a1726;
  --bs-input-focus-bg:#fff;
  --bs-input-focus-border-color:#292731;
  background-color: var(--bs-input-bg);
  color: var(--bs-input-color);
  border-color: var(--bs-input-border-color);
}
.form-control:focus {
  background-color: var(--bs-input-focus-bg);
  color: var(--bs-input-focus-color);
  border-color: var(--bs-input-focus-border-color);
}

.form-check-input {
  --bs-form-check-input-bg:#fff;
  --bs-form-check-input-border:1px solid #e6e5ec;
  background-color: var(--bs-form-check-input-bg);
  border: var(--bs-form-check-input-border);
}