//
//Fullscreen header scss
//

.offcanvas.offcanvas-fullscreen{
&.show{
  ul>li{
    overflow: unset!important;
  }
}
}
.fullscreen-item {
  transition: opacity .5s linear 0s, transform .5s cubic-bezier(.215, .61, .355, 1) 0s;
  opacity: 0;
  transform: translateY(3.5rem);
}

//Fullscreen gsap image link
.fullscreen-inner {
  .js-hover-img {
    transition: none;

    .js-hover-img-item {
      transition: opacity .2s, transform .4s cubic-bezier(.215, .61, .355, 1) .1s;
      transform: translate3d(0, 30px, 0);
      opacity: 0;
      .js-hover-img-link {
        transition: none;

        &:not(:hover):not(.active) {
          mix-blend-mode: screen;
        }
      }
    }
  }
}

  .fullscreen-inner {
    .js-hover-img {
      .js-hover-img-item {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s linear .8s, transform .5s cubic-bezier(.215, .61, .355, 1) .8s;

        @for $i from 1 through 15 {
          &:nth-child(#{$i}) {
            transition-delay: $i * 300ms;
          }
        }
      }
    }
  }

  .fullscreen-item {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s linear .8s, transform .5s cubic-bezier(.215, .61, .355, 1) .8s;

    @for $i from 1 through 15 {
      &:nth-child(#{$i}) {
        transition-delay: $i * 300ms;
      }
    }
  }
